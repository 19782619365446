@charset "UTF-8";
/*  normalize ------------------------------------------------------------ */
body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"],
input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 0 solid silver;
  margin: 0 !important; }

legend {
  border: 0;
  /* 1 */
  margin: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: 700; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

input, textarea, select {
  -webkit-border-radius: 0; }

* {
  -webkit-tap-highlight-color: transparent; }

/*  normalize ------------------------------------------------------------ */
body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"],
input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 0 solid silver;
  margin: 0 !important; }

legend {
  border: 0;
  /* 1 */
  margin: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: 700; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

input, textarea, select {
  -webkit-border-radius: 0; }

* {
  -webkit-tap-highlight-color: transparent; }

/*  global reset ------------------------------------------------------------ */
html, body, .gs-result a {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 100%;
  color: #222222;
  background-color: #ffffff;
  overflow-x: hidden;
  padding: 0;
  margin: 0px; }

textarea {
  font-family: 'Montserrat', sans-serif; }

a {
  text-decoration: none;
  color: #003e80; }

a:hover {
  color: #166bc5; }

@font-face {
  font-family: "SSPika";
  src: url("../fonts/ss-pika.eot");
  src: url("../fonts/ss-pika.eot?#iefix") format("embedded-opentype"), url("../fonts/ss-pika.woff") format("woff"), url("../fonts/ss-pika.ttf") format("truetype"), url("../fonts/ss-pika.svg#SSPika") format("svg");
  font-weight: normal;
  font-style: normal; }

html:hover [class^="ss-"] {
  -ms-zoom: 1; }

.ss-icon, .ss-icon.ss-pika,
[class^="ss-"]:before, [class*=" ss-"]:before,
[class^="ss-"].ss-pika:before, [class*=" ss-"].ss-pika:before,
[class^="ss-"].right:after, [class*=" ss-"].right:after,
[class^="ss-"].ss-pika.right:after, [class*=" ss-"].ss-pika.right:after {
  font-family: "SSPika";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; }

[class^="ss-"].right:before,
[class*=" ss-"].right:before {
  display: none;
  content: ''; }

.ss-cursor:before, .ss-cursor.right:after {
  content: '?'; }

.ss-crosshair:before, .ss-crosshair.right:after {
  content: '?'; }

.ss-search:before, .ss-search.right:after {
  content: '??'; }

.ss-zoomin:before, .ss-zoomin.right:after {
  content: '?'; }

.ss-zoomout:before, .ss-zoomout.right:after {
  content: '?'; }

.ss-screenshot:before, .ss-screenshot.right:after {
  content: '?'; }

.ss-binoculars:before, .ss-binoculars.right:after {
  content: '?'; }

.ss-view:before, .ss-view.right:after {
  content: '??'; }

.ss-attach:before, .ss-attach.right:after {
  content: '??'; }

.ss-link:before, .ss-link.right:after {
  content: '??'; }

.ss-move:before, .ss-move.right:after {
  content: '?'; }

.ss-write:before, .ss-write.right:after {
  content: '?'; }

.ss-writingdisabled:before, .ss-writingdisabled.right:after {
  content: '?'; }

.ss-erase:before, .ss-erase.right:after {
  content: '?'; }

.ss-compose:before, .ss-compose.right:after {
  content: '??'; }

.ss-draw:before, .ss-draw.right:after {
  content: '?'; }

.ss-lock:before, .ss-lock.right:after {
  content: '??'; }

.ss-unlock:before, .ss-unlock.right:after {
  content: '??'; }

.ss-key:before, .ss-key.right:after {
  content: '??'; }

.ss-backspace:before, .ss-backspace.right:after {
  content: '?'; }

.ss-ban:before, .ss-ban.right:after {
  content: '??'; }

.ss-nosmoking:before, .ss-nosmoking.right:after {
  content: '??'; }

.ss-trash:before, .ss-trash.right:after {
  content: '?'; }

.ss-target:before, .ss-target.right:after {
  content: '?'; }

.ss-new:before, .ss-new.right:after {
  content: '??'; }

.ss-tag:before, .ss-tag.right:after {
  content: '?'; }

.ss-pricetag:before, .ss-pricetag.right:after {
  content: '?'; }

.ss-flowertag:before, .ss-flowertag.right:after {
  content: '?'; }

.ss-bookmark:before, .ss-bookmark.right:after {
  content: '??'; }

.ss-flag:before, .ss-flag.right:after {
  content: '?'; }

.ss-like:before, .ss-like.right:after {
  content: '??'; }

.ss-dislike:before, .ss-dislike.right:after {
  content: '??'; }

.ss-heart:before, .ss-heart.right:after {
  content: '?'; }

.ss-halfheart:before, .ss-halfheart.right:after {
  content: '?'; }

.ss-emptyheart:before, .ss-emptyheart.right:after {
  content: '?'; }

.ss-star:before, .ss-star.right:after {
  content: '?'; }

.ss-halfstar:before, .ss-halfstar.right:after {
  content: '?'; }

.ss-medal:before, .ss-medal.right:after {
  content: '?'; }

.ss-ribbon:before, .ss-ribbon.right:after {
  content: '??'; }

.ss-bullseye:before, .ss-bullseye.right:after {
  content: '??'; }

.ss-sample:before, .ss-sample.right:after {
  content: '?'; }

.ss-crop:before, .ss-crop.right:after {
  content: '?'; }

.ss-layers:before, .ss-layers.right:after {
  content: '?'; }

.ss-gridlines:before, .ss-gridlines.right:after {
  content: '?'; }

.ss-pen:before, .ss-pen.right:after {
  content: '?'; }

.ss-feather:before, .ss-feather.right:after {
  content: '?'; }

.ss-rubbereraser:before, .ss-rubbereraser.right:after {
  content: '?'; }

.ss-paintroller:before, .ss-paintroller.right:after {
  content: '?'; }

.ss-rubberstamp:before, .ss-rubberstamp.right:after {
  content: '?'; }

.ss-checkclipboard:before, .ss-checkclipboard.right:after {
  content: '?'; }

.ss-linechartclipboard:before, .ss-linechartclipboard.right:after {
  content: '?'; }

.ss-clockwise:before, .ss-clockwise.right:after {
  content: '?'; }

.ss-phone:before, .ss-phone.right:after {
  content: '??'; }

.ss-phonedisabled:before, .ss-phonedisabled.right:after {
  content: '?'; }

.ss-headset:before, .ss-headset.right:after {
  content: '?'; }

.ss-megaphone:before, .ss-megaphone.right:after {
  content: '??'; }

.ss-rss:before, .ss-rss.right:after {
  content: '?'; }

.ss-facetime:before, .ss-facetime.right:after {
  content: '?'; }

.ss-reply:before, .ss-reply.right:after {
  content: '?'; }

.ss-send:before, .ss-send.right:after {
  content: '?'; }

.ss-mail:before, .ss-mail.right:after {
  content: '?'; }

.ss-inbox:before, .ss-inbox.right:after {
  content: '??'; }

.ss-outbox:before, .ss-outbox.right:after {
  content: '??'; }

.ss-wifimail:before, .ss-wifimail.right:after {
  content: '?'; }

.ss-chat:before, .ss-chat.right:after {
  content: '??'; }

.ss-user:before, .ss-user.right:after {
  content: '??'; }

.ss-femaleuser:before, .ss-femaleuser.right:after {
  content: '??'; }

.ss-users:before, .ss-users.right:after {
  content: '??'; }

.ss-usergroup:before, .ss-usergroup.right:after {
  content: '?'; }

.ss-adduser:before, .ss-adduser.right:after {
  content: '?'; }

.ss-removeuser:before, .ss-removeuser.right:after {
  content: '?'; }

.ss-deleteuser:before, .ss-deleteuser.right:after {
  content: '?'; }

.ss-userprofile:before, .ss-userprofile.right:after {
  content: '?'; }

.ss-businessuser:before, .ss-businessuser.right:after {
  content: '?'; }

.ss-cart:before, .ss-cart.right:after {
  content: '?'; }

.ss-addcart:before, .ss-addcart.right:after {
  content: '?'; }

.ss-removecart:before, .ss-removecart.right:after {
  content: '?'; }

.ss-deletecart:before, .ss-deletecart.right:after {
  content: '?'; }

.ss-downloadcart:before, .ss-downloadcart.right:after {
  content: '?'; }

.ss-emptycart:before, .ss-emptycart.right:after {
  content: '?'; }

.ss-basket:before, .ss-basket.right:after {
  content: '?'; }

.ss-gift:before, .ss-gift.right:after {
  content: '??'; }

.ss-apple:before, .ss-apple.right:after {
  content: '??'; }

.ss-cashregister:before, .ss-cashregister.right:after {
  content: '?'; }

.ss-store:before, .ss-store.right:after {
  content: '??'; }

.ss-searchbarcode:before, .ss-searchbarcode.right:after {
  content: '?'; }

.ss-notepad:before, .ss-notepad.right:after {
  content: '?'; }

.ss-creditcard:before, .ss-creditcard.right:after {
  content: '??'; }

.ss-safe:before, .ss-safe.right:after {
  content: '?'; }

.ss-digitalsafe:before, .ss-digitalsafe.right:after {
  content: '?'; }

.ss-atm:before, .ss-atm.right:after {
  content: '??'; }

.ss-dispensecash:before, .ss-dispensecash.right:after {
  content: '?'; }

.ss-banknote:before, .ss-banknote.right:after {
  content: '??'; }

.ss-coins:before, .ss-coins.right:after {
  content: '?'; }

.ss-bankcheck:before, .ss-bankcheck.right:after {
  content: '?'; }

.ss-piggybank:before, .ss-piggybank.right:after {
  content: '?'; }

.ss-moneybag:before, .ss-moneybag.right:after {
  content: '??'; }

.ss-tip:before, .ss-tip.right:after {
  content: '?'; }

.ss-calculator:before, .ss-calculator.right:after {
  content: '?'; }

.ss-percent:before, .ss-percent.right:after {
  content: '%'; }

.ss-bank:before, .ss-bank.right:after {
  content: '??'; }

.ss-scales:before, .ss-scales.right:after {
  content: '?'; }

.ss-piechart:before, .ss-piechart.right:after {
  content: '?'; }

.ss-piechartthirds:before, .ss-piechartthirds.right:after {
  content: '?'; }

.ss-barchart:before, .ss-barchart.right:after {
  content: '??'; }

.ss-upwardsbarchart:before, .ss-upwardsbarchart.right:after {
  content: '?'; }

.ss-downwardsbarchart:before, .ss-downwardsbarchart.right:after {
  content: '?'; }

.ss-horizontalbarchart:before, .ss-horizontalbarchart.right:after {
  content: '?'; }

.ss-analytics:before, .ss-analytics.right:after {
  content: '?'; }

.ss-upwardslinechart:before, .ss-upwardslinechart.right:after {
  content: '??'; }

.ss-downwardslinechart:before, .ss-downwardslinechart.right:after {
  content: '??'; }

.ss-linecharts:before, .ss-linecharts.right:after {
  content: '?'; }

.ss-scatterchart:before, .ss-scatterchart.right:after {
  content: '?'; }

.ss-stock:before, .ss-stock.right:after {
  content: '?'; }

.ss-form:before, .ss-form.right:after {
  content: '?'; }

.ss-box:before, .ss-box.right:after {
  content: '??'; }

.ss-crate:before, .ss-crate.right:after {
  content: '?'; }

.ss-deliveryvan:before, .ss-deliveryvan.right:after {
  content: '?'; }

.ss-deliverytruck:before, .ss-deliverytruck.right:after {
  content: '?'; }

.ss-forklift:before, .ss-forklift.right:after {
  content: '?'; }

.ss-cargoship:before, .ss-cargoship.right:after {
  content: '?'; }

.ss-hook:before, .ss-hook.right:after {
  content: '?'; }

.ss-weight:before, .ss-weight.right:after {
  content: '?'; }

.ss-containers:before, .ss-containers.right:after {
  content: '?'; }

.ss-fragile:before, .ss-fragile.right:after {
  content: '?'; }

.ss-thissideup:before, .ss-thissideup.right:after {
  content: '?'; }

.ss-home:before, .ss-home.right:after {
  content: '¦'; }

.ss-building:before, .ss-building.right:after {
  content: '??'; }

.ss-lodging:before, .ss-lodging.right:after {
  content: '??'; }

.ss-earth:before, .ss-earth.right:after {
  content: '??'; }

.ss-globe:before, .ss-globe.right:after {
  content: '??'; }

.ss-garage:before, .ss-garage.right:after {
  content: '?'; }

.ss-warehouse:before, .ss-warehouse.right:after {
  content: '?'; }

.ss-floorplan:before, .ss-floorplan.right:after {
  content: '?'; }

.ss-address:before, .ss-address.right:after {
  content: '?'; }

.ss-searchhouse:before, .ss-searchhouse.right:after {
  content: '?'; }

.ss-housesale:before, .ss-housesale.right:after {
  content: '?'; }

.ss-navigate:before, .ss-navigate.right:after {
  content: '?'; }

.ss-compass:before, .ss-compass.right:after {
  content: '?'; }

.ss-signpost:before, .ss-signpost.right:after {
  content: '?'; }

.ss-map:before, .ss-map.right:after {
  content: '?'; }

.ss-gps:before, .ss-gps.right:after {
  content: '?'; }

.ss-compassnavigate:before, .ss-compassnavigate.right:after {
  content: '?'; }

.ss-compassarrow:before, .ss-compassarrow.right:after {
  content: '?'; }

.ss-location:before, .ss-location.right:after {
  content: '?'; }

.ss-pin:before, .ss-pin.right:after {
  content: '??'; }

.ss-pushpin:before, .ss-pushpin.right:after {
  content: '??'; }

.ss-maplocation:before, .ss-maplocation.right:after {
  content: '?'; }

.ss-globelocation:before, .ss-globelocation.right:after {
  content: '?'; }

.ss-puzzle:before, .ss-puzzle.right:after {
  content: '?'; }

.ss-floppydisk:before, .ss-floppydisk.right:after {
  content: '??'; }

.ss-firewall:before, .ss-firewall.right:after {
  content: '?'; }

.ss-database:before, .ss-database.right:after {
  content: '?'; }

.ss-hdd:before, .ss-hdd.right:after {
  content: '?'; }

.ss-music:before, .ss-music.right:after {
  content: '?'; }

.ss-eighthnote:before, .ss-eighthnote.right:after {
  content: '?'; }

.ss-mic:before, .ss-mic.right:after {
  content: '??'; }

.ss-volume:before, .ss-volume.right:after {
  content: '??'; }

.ss-lowvolume:before, .ss-lowvolume.right:after {
  content: '??'; }

.ss-highvolume:before, .ss-highvolume.right:after {
  content: '??'; }

.ss-radio:before, .ss-radio.right:after {
  content: '??'; }

.ss-stereo:before, .ss-stereo.right:after {
  content: '?'; }

.ss-airplay:before, .ss-airplay.right:after {
  content: '?'; }

.ss-vinylrecord:before, .ss-vinylrecord.right:after {
  content: '?'; }

.ss-disc:before, .ss-disc.right:after {
  content: '??'; }

.ss-camera:before, .ss-camera.right:after {
  content: '??'; }

.ss-picture:before, .ss-picture.right:after {
  content: '??'; }

.ss-video:before, .ss-video.right:after {
  content: '??'; }

.ss-playvideo:before, .ss-playvideo.right:after {
  content: '?'; }

.ss-play:before, .ss-play.right:after {
  content: '?'; }

.ss-pause:before, .ss-pause.right:after {
  content: '?'; }

.ss-stop:before, .ss-stop.right:after {
  content: '¦'; }

.ss-record:before, .ss-record.right:after {
  content: '?'; }

.ss-rewind:before, .ss-rewind.right:after {
  content: '?'; }

.ss-fastforward:before, .ss-fastforward.right:after {
  content: '?'; }

.ss-skipback:before, .ss-skipback.right:after {
  content: '?'; }

.ss-skipforward:before, .ss-skipforward.right:after {
  content: '?'; }

.ss-eject:before, .ss-eject.right:after {
  content: '?'; }

.ss-repeat:before, .ss-repeat.right:after {
  content: '??'; }

.ss-replay:before, .ss-replay.right:after {
  content: '?'; }

.ss-shuffle:before, .ss-shuffle.right:after {
  content: '??'; }

.ss-index:before, .ss-index.right:after {
  content: '?'; }

.ss-page:before, .ss-page.right:after {
  content: '?'; }

.ss-book:before, .ss-book.right:after {
  content: '??'; }

.ss-books:before, .ss-books.right:after {
  content: '??'; }

.ss-openbook:before, .ss-openbook.right:after {
  content: '??'; }

.ss-heartbook:before, .ss-heartbook.right:after {
  content: '?'; }

.ss-downloadbook:before, .ss-downloadbook.right:after {
  content: '?'; }

.ss-musicopenbook:before, .ss-musicopenbook.right:after {
  content: '?'; }

.ss-searchbook:before, .ss-searchbook.right:after {
  content: '?'; }

.ss-notebook:before, .ss-notebook.right:after {
  content: '??'; }

.ss-newspaper:before, .ss-newspaper.right:after {
  content: '??'; }

.ss-notice:before, .ss-notice.right:after {
  content: '?'; }

.ss-rows:before, .ss-rows.right:after {
  content: '?'; }

.ss-columns:before, .ss-columns.right:after {
  content: '?'; }

.ss-thumbnails:before, .ss-thumbnails.right:after {
  content: '?'; }

.ss-pagecurl:before, .ss-pagecurl.right:after {
  content: '?'; }

.ss-desktop:before, .ss-desktop.right:after {
  content: '??'; }

.ss-laptop:before, .ss-laptop.right:after {
  content: '?'; }

.ss-tablet:before, .ss-tablet.right:after {
  content: '?'; }

.ss-smartphone:before, .ss-smartphone.right:after {
  content: '?'; }

.ss-cell:before, .ss-cell.right:after {
  content: '??'; }

.ss-walkytalky:before, .ss-walkytalky.right:after {
  content: '?'; }

.ss-ereader:before, .ss-ereader.right:after {
  content: '?'; }

.ss-ebook:before, .ss-ebook.right:after {
  content: '?'; }

.ss-wifibook:before, .ss-wifibook.right:after {
  content: '?'; }

.ss-wifiopenbook:before, .ss-wifiopenbook.right:after {
  content: '?'; }

.ss-battery:before, .ss-battery.right:after {
  content: '??'; }

.ss-highbattery:before, .ss-highbattery.right:after {
  content: '?'; }

.ss-mediumbattery:before, .ss-mediumbattery.right:after {
  content: '?'; }

.ss-lowbattery:before, .ss-lowbattery.right:after {
  content: '?'; }

.ss-emptybattery:before, .ss-emptybattery.right:after {
  content: '?'; }

.ss-chargingbattery:before, .ss-chargingbattery.right:after {
  content: '?'; }

.ss-heartmobile:before, .ss-heartmobile.right:after {
  content: '?'; }

.ss-phonemobile:before, .ss-phonemobile.right:after {
  content: '?'; }

.ss-lockmobile:before, .ss-lockmobile.right:after {
  content: '?'; }

.ss-textmobile:before, .ss-textmobile.right:after {
  content: '?'; }

.ss-dollarmobile:before, .ss-dollarmobile.right:after {
  content: '?'; }

.ss-euromobile:before, .ss-euromobile.right:after {
  content: '?'; }

.ss-rssmobile:before, .ss-rssmobile.right:after {
  content: '?'; }

.ss-navigationmobile:before, .ss-navigationmobile.right:after {
  content: '?'; }

.ss-batterymobile:before, .ss-batterymobile.right:after {
  content: '?'; }

.ss-powermobile:before, .ss-powermobile.right:after {
  content: '?'; }

.ss-browseonline:before, .ss-browseonline.right:after {
  content: '?'; }

.ss-shoponline:before, .ss-shoponline.right:after {
  content: '?'; }

.ss-bankonline:before, .ss-bankonline.right:after {
  content: '?'; }

.ss-realtyonline:before, .ss-realtyonline.right:after {
  content: '?'; }

.ss-readonline:before, .ss-readonline.right:after {
  content: '?'; }

.ss-weatheronline:before, .ss-weatheronline.right:after {
  content: '?'; }

.ss-lightbulb:before, .ss-lightbulb.right:after {
  content: '??'; }

.ss-lightbulbon:before, .ss-lightbulbon.right:after {
  content: '?'; }

.ss-cfl:before, .ss-cfl.right:after {
  content: '?'; }

.ss-hairdryer:before, .ss-hairdryer.right:after {
  content: '?'; }

.ss-picnictable:before, .ss-picnictable.right:after {
  content: '?'; }

.ss-flame:before, .ss-flame.right:after {
  content: '?'; }

.ss-campfire:before, .ss-campfire.right:after {
  content: '??'; }

.ss-bonfire:before, .ss-bonfire.right:after {
  content: '?'; }

.ss-balloon:before, .ss-balloon.right:after {
  content: '??'; }

.ss-christmastree:before, .ss-christmastree.right:after {
  content: '??'; }

.ss-sweep:before, .ss-sweep.right:after {
  content: '?'; }

.ss-chess:before, .ss-chess.right:after {
  content: '?'; }

.ss-onedie:before, .ss-onedie.right:after {
  content: '?'; }

.ss-twodie:before, .ss-twodie.right:after {
  content: '?'; }

.ss-threedie:before, .ss-threedie.right:after {
  content: '?'; }

.ss-fourdie:before, .ss-fourdie.right:after {
  content: '?'; }

.ss-fivedie:before, .ss-fivedie.right:after {
  content: '?'; }

.ss-sixdie:before, .ss-sixdie.right:after {
  content: '?'; }

.ss-downloadcloud:before, .ss-downloadcloud.right:after {
  content: '?'; }

.ss-download:before, .ss-download.right:after {
  content: '?'; }

.ss-downloadbox:before, .ss-downloadbox.right:after {
  content: '?'; }

.ss-downloadcrate:before, .ss-downloadcrate.right:after {
  content: '?'; }

.ss-uploadcloud:before, .ss-uploadcloud.right:after {
  content: '?'; }

.ss-upload:before, .ss-upload.right:after {
  content: '?'; }

.ss-uploadbox:before, .ss-uploadbox.right:after {
  content: '?'; }

.ss-uploadcrate:before, .ss-uploadcrate.right:after {
  content: '?'; }

.ss-fork:before, .ss-fork.right:after {
  content: '?'; }

.ss-merge:before, .ss-merge.right:after {
  content: '?'; }

.ss-refresh:before, .ss-refresh.right:after {
  content: '?'; }

.ss-sync:before, .ss-sync.right:after {
  content: '?'; }

.ss-loading:before, .ss-loading.right:after {
  content: '?'; }

.ss-wifi:before, .ss-wifi.right:after {
  content: '?'; }

.ss-connection:before, .ss-connection.right:after {
  content: '?'; }

.ss-reload:before, .ss-reload.right:after {
  content: '?'; }

.ss-file:before, .ss-file.right:after {
  content: '??'; }

.ss-addfile:before, .ss-addfile.right:after {
  content: '?'; }

.ss-removefile:before, .ss-removefile.right:after {
  content: '?'; }

.ss-deletefile:before, .ss-deletefile.right:after {
  content: '?'; }

.ss-downloadfile:before, .ss-downloadfile.right:after {
  content: '?'; }

.ss-uploadfile:before, .ss-uploadfile.right:after {
  content: '?'; }

.ss-importfile:before, .ss-importfile.right:after {
  content: '?'; }

.ss-exportfile:before, .ss-exportfile.right:after {
  content: '?'; }

.ss-settingsfile:before, .ss-settingsfile.right:after {
  content: '?'; }

.ss-lockfile:before, .ss-lockfile.right:after {
  content: '?'; }

.ss-userfile:before, .ss-userfile.right:after {
  content: '?'; }

.ss-picturefile:before, .ss-picturefile.right:after {
  content: '?'; }

.ss-textfile:before, .ss-textfile.right:after {
  content: '?'; }

.ss-exe:before, .ss-exe.right:after {
  content: '?'; }

.ss-zip:before, .ss-zip.right:after {
  content: '?'; }

.ss-doc:before, .ss-doc.right:after {
  content: '?'; }

.ss-ppt:before, .ss-ppt.right:after {
  content: '?'; }

.ss-pdf:before, .ss-pdf.right:after {
  content: '?'; }

.ss-jpg:before, .ss-jpg.right:after {
  content: '?'; }

.ss-png:before, .ss-png.right:after {
  content: '?'; }

.ss-folder:before, .ss-folder.right:after {
  content: '??'; }

.ss-openfolder:before, .ss-openfolder.right:after {
  content: '??'; }

.ss-downloadfolder:before, .ss-downloadfolder.right:after {
  content: '?'; }

.ss-uploadfolder:before, .ss-uploadfolder.right:after {
  content: '?'; }

.ss-cloudfolder:before, .ss-cloudfolder.right:after {
  content: '?'; }

.ss-lockfolder:before, .ss-lockfolder.right:after {
  content: '?'; }

.ss-securefolder:before, .ss-securefolder.right:after {
  content: '?'; }

.ss-picturefolder:before, .ss-picturefolder.right:after {
  content: '?'; }

.ss-moviefolder:before, .ss-moviefolder.right:after {
  content: '?'; }

.ss-quote:before, .ss-quote.right:after {
  content: '“'; }

.ss-text:before, .ss-text.right:after {
  content: '?'; }

.ss-font:before, .ss-font.right:after {
  content: '?'; }

.ss-highlight:before, .ss-highlight.right:after {
  content: '?'; }

.ss-print:before, .ss-print.right:after {
  content: '?'; }

.ss-fax:before, .ss-fax.right:after {
  content: '??'; }

.ss-list:before, .ss-list.right:after {
  content: '?'; }

.ss-layout:before, .ss-layout.right:after {
  content: '?'; }

.ss-action:before, .ss-action.right:after {
  content: '?'; }

.ss-redirect:before, .ss-redirect.right:after {
  content: '?'; }

.ss-expand:before, .ss-expand.right:after {
  content: '?'; }

.ss-contract:before, .ss-contract.right:after {
  content: '?'; }

.ss-help:before, .ss-help.right:after {
  content: '?'; }

.ss-info:before, .ss-info.right:after {
  content: '?'; }

.ss-alert:before, .ss-alert.right:after {
  content: '?'; }

.ss-caution:before, .ss-caution.right:after {
  content: '?'; }

.ss-logout:before, .ss-logout.right:after {
  content: '?'; }

.ss-login:before, .ss-login.right:after {
  content: '?'; }

.ss-scaleup:before, .ss-scaleup.right:after {
  content: '?'; }

.ss-scaledown:before, .ss-scaledown.right:after {
  content: '?'; }

.ss-plus:before, .ss-plus.right:after {
  content: '+'; }

.ss-hyphen:before, .ss-hyphen.right:after {
  content: '-'; }

.ss-check:before, .ss-check.right:after {
  content: '?'; }

.ss-delete:before, .ss-delete.right:after {
  content: '?'; }

.ss-bearface:before, .ss-bearface.right:after {
  content: '??'; }

.ss-bird:before, .ss-bird.right:after {
  content: '??'; }

.ss-fishes:before, .ss-fishes.right:after {
  content: '?'; }

.ss-tropicalfish:before, .ss-tropicalfish.right:after {
  content: '?'; }

.ss-tree:before, .ss-tree.right:after {
  content: '??'; }

.ss-evergreen:before, .ss-evergreen.right:after {
  content: '??'; }

.ss-palmtree:before, .ss-palmtree.right:after {
  content: '??'; }

.ss-leaf:before, .ss-leaf.right:after {
  content: '??'; }

.ss-seedling:before, .ss-seedling.right:after {
  content: '??'; }

.ss-grass:before, .ss-grass.right:after {
  content: '?'; }

.ss-settings:before, .ss-settings.right:after {
  content: '?'; }

.ss-dashboard:before, .ss-dashboard.right:after {
  content: '?'; }

.ss-dial:before, .ss-dial.right:after {
  content: '?'; }

.ss-notifications:before, .ss-notifications.right:after {
  content: '??'; }

.ss-notificationsdisabled:before, .ss-notificationsdisabled.right:after {
  content: '??'; }

.ss-flash:before, .ss-flash.right:after {
  content: '?'; }

.ss-wrench:before, .ss-wrench.right:after {
  content: '??'; }

.ss-tapemeasure:before, .ss-tapemeasure.right:after {
  content: '?'; }

.ss-clock:before, .ss-clock.right:after {
  content: '?'; }

.ss-watch:before, .ss-watch.right:after {
  content: '?'; }

.ss-stopwatch:before, .ss-stopwatch.right:after {
  content: '?'; }

.ss-alarmclock:before, .ss-alarmclock.right:after {
  content: '?'; }

.ss-calendar:before, .ss-calendar.right:after {
  content: '??'; }

.ss-addcalendar:before, .ss-addcalendar.right:after {
  content: '?'; }

.ss-removecalendar:before, .ss-removecalendar.right:after {
  content: '?'; }

.ss-checkcalendar:before, .ss-checkcalendar.right:after {
  content: '?'; }

.ss-deletecalendar:before, .ss-deletecalendar.right:after {
  content: '?'; }

.ss-sausage:before, .ss-sausage.right:after {
  content: '?'; }

.ss-burger:before, .ss-burger.right:after {
  content: '??'; }

.ss-pizza:before, .ss-pizza.right:after {
  content: '??'; }

.ss-fish:before, .ss-fish.right:after {
  content: '??'; }

.ss-shrimp:before, .ss-shrimp.right:after {
  content: '??'; }

.ss-turkey:before, .ss-turkey.right:after {
  content: '?'; }

.ss-steak:before, .ss-steak.right:after {
  content: '?'; }

.ss-sidedish:before, .ss-sidedish.right:after {
  content: '??'; }

.ss-noodles:before, .ss-noodles.right:after {
  content: '??'; }

.ss-spaghetti:before, .ss-spaghetti.right:after {
  content: '??'; }

.ss-corn:before, .ss-corn.right:after {
  content: '?'; }

.ss-carrot:before, .ss-carrot.right:after {
  content: '?'; }

.ss-icecream:before, .ss-icecream.right:after {
  content: '??'; }

.ss-mug:before, .ss-mug.right:after {
  content: '?'; }

.ss-beer:before, .ss-beer.right:after {
  content: '??'; }

.ss-bottle:before, .ss-bottle.right:after {
  content: '?'; }

.ss-wineglass:before, .ss-wineglass.right:after {
  content: '??'; }

.ss-cocktail:before, .ss-cocktail.right:after {
  content: '??'; }

.ss-tea:before, .ss-tea.right:after {
  content: '??'; }

.ss-teapot:before, .ss-teapot.right:after {
  content: '?'; }

.ss-waterbottle:before, .ss-waterbottle.right:after {
  content: '?'; }

.ss-wineglasssparkle:before, .ss-wineglasssparkle.right:after {
  content: '?'; }

.ss-salt:before, .ss-salt.right:after {
  content: '?'; }

.ss-pepper:before, .ss-pepper.right:after {
  content: '?'; }

.ss-oliveoil:before, .ss-oliveoil.right:after {
  content: '?'; }

.ss-hotsauce:before, .ss-hotsauce.right:after {
  content: '?'; }

.ss-coal:before, .ss-coal.right:after {
  content: '?'; }

.ss-oven:before, .ss-oven.right:after {
  content: '?'; }

.ss-stove:before, .ss-stove.right:after {
  content: '?'; }

.ss-cook:before, .ss-cook.right:after {
  content: '??'; }

.ss-bbq:before, .ss-bbq.right:after {
  content: '?'; }

.ss-utensils:before, .ss-utensils.right:after {
  content: '??'; }

.ss-spoonfork:before, .ss-spoonfork.right:after {
  content: '?'; }

.ss-knife:before, .ss-knife.right:after {
  content: '??'; }

.ss-cookingutensils:before, .ss-cookingutensils.right:after {
  content: '?'; }

.ss-measuringcup:before, .ss-measuringcup.right:after {
  content: '?'; }

.ss-colander:before, .ss-colander.right:after {
  content: '?'; }

.ss-scale:before, .ss-scale.right:after {
  content: '?'; }

.ss-eggtimer:before, .ss-eggtimer.right:after {
  content: '?'; }

.ss-platter:before, .ss-platter.right:after {
  content: '?'; }

.ss-apron:before, .ss-apron.right:after {
  content: '?'; }

.ss-bbqapron:before, .ss-bbqapron.right:after {
  content: '?'; }

.ss-chef:before, .ss-chef.right:after {
  content: '?'; }

.ss-handbag:before, .ss-handbag.right:after {
  content: '??'; }

.ss-briefcase:before, .ss-briefcase.right:after {
  content: '??'; }

.ss-hanger:before, .ss-hanger.right:after {
  content: '?'; }

.ss-weathervane:before, .ss-weathervane.right:after {
  content: '?'; }

.ss-thermometer:before, .ss-thermometer.right:after {
  content: '?'; }

.ss-weather:before, .ss-weather.right:after {
  content: '?'; }

.ss-cloud:before, .ss-cloud.right:after {
  content: '?'; }

.ss-droplet:before, .ss-droplet.right:after {
  content: '??'; }

.ss-sun:before, .ss-sun.right:after {
  content: '?'; }

.ss-partlycloudy:before, .ss-partlycloudy.right:after {
  content: '?'; }

.ss-rain:before, .ss-rain.right:after {
  content: '?'; }

.ss-thunderstorm:before, .ss-thunderstorm.right:after {
  content: '?'; }

.ss-umbrella:before, .ss-umbrella.right:after {
  content: '?'; }

.ss-rainbow:before, .ss-rainbow.right:after {
  content: '??'; }

.ss-fog:before, .ss-fog.right:after {
  content: '?'; }

.ss-wind:before, .ss-wind.right:after {
  content: '?'; }

.ss-tornado:before, .ss-tornado.right:after {
  content: '?'; }

.ss-snowflake:before, .ss-snowflake.right:after {
  content: '?'; }

.ss-fan:before, .ss-fan.right:after {
  content: '?'; }

.ss-solarpanel:before, .ss-solarpanel.right:after {
  content: '?'; }

.ss-plug:before, .ss-plug.right:after {
  content: '??'; }

.ss-outlet:before, .ss-outlet.right:after {
  content: '?'; }

.ss-car:before, .ss-car.right:after {
  content: '??'; }

.ss-taxi:before, .ss-taxi.right:after {
  content: '??'; }

.ss-locomotive:before, .ss-locomotive.right:after {
  content: '??'; }

.ss-train:before, .ss-train.right:after {
  content: '??'; }

.ss-traintunnel:before, .ss-traintunnel.right:after {
  content: '??'; }

.ss-bus:before, .ss-bus.right:after {
  content: '??'; }

.ss-truck:before, .ss-truck.right:after {
  content: '??'; }

.ss-caravan:before, .ss-caravan.right:after {
  content: '??'; }

.ss-tractor:before, .ss-tractor.right:after {
  content: '??'; }

.ss-tunnel:before, .ss-tunnel.right:after {
  content: '?'; }

.ss-plane:before, .ss-plane.right:after {
  content: '?'; }

.ss-arrival:before, .ss-arrival.right:after {
  content: '?'; }

.ss-departure:before, .ss-departure.right:after {
  content: '?'; }

.ss-helicopter:before, .ss-helicopter.right:after {
  content: '??'; }

.ss-bike:before, .ss-bike.right:after {
  content: '??'; }

.ss-motorcycle:before, .ss-motorcycle.right:after {
  content: '?'; }

.ss-boat:before, .ss-boat.right:after {
  content: '??'; }

.ss-sailboat:before, .ss-sailboat.right:after {
  content: '?'; }

.ss-schooner:before, .ss-schooner.right:after {
  content: '?'; }

.ss-skylift:before, .ss-skylift.right:after {
  content: '??'; }

.ss-rocket:before, .ss-rocket.right:after {
  content: '??'; }

.ss-steeringwheel:before, .ss-steeringwheel.right:after {
  content: '?'; }

.ss-trafficcamera:before, .ss-trafficcamera.right:after {
  content: '?'; }

.ss-fuel:before, .ss-fuel.right:after {
  content: '?'; }

.ss-jerrycan:before, .ss-jerrycan.right:after {
  content: '?'; }

.ss-passport:before, .ss-passport.right:after {
  content: '?'; }

.ss-trafficlight:before, .ss-trafficlight.right:after {
  content: '??'; }

.ss-highway:before, .ss-highway.right:after {
  content: '?'; }

.ss-road:before, .ss-road.right:after {
  content: '?'; }

.ss-intersection:before, .ss-intersection.right:after {
  content: '?'; }

.ss-wheelchair:before, .ss-wheelchair.right:after {
  content: '?'; }

.ss-elevator:before, .ss-elevator.right:after {
  content: '?'; }

.ss-golf:before, .ss-golf.right:after {
  content: '?'; }

.ss-hockey:before, .ss-hockey.right:after {
  content: '?'; }

.ss-iceskate:before, .ss-iceskate.right:after {
  content: '?'; }

.ss-billiards:before, .ss-billiards.right:after {
  content: '??'; }

.ss-baseballglove:before, .ss-baseballglove.right:after {
  content: '?'; }

.ss-tennis:before, .ss-tennis.right:after {
  content: '??'; }

.ss-tabletennis:before, .ss-tabletennis.right:after {
  content: '?'; }

.ss-badminton:before, .ss-badminton.right:after {
  content: '?'; }

.ss-boxing:before, .ss-boxing.right:after {
  content: '?'; }

.ss-bowling:before, .ss-bowling.right:after {
  content: '?'; }

.ss-football:before, .ss-football.right:after {
  content: '??'; }

.ss-soccer:before, .ss-soccer.right:after {
  content: '?'; }

.ss-hiker:before, .ss-hiker.right:after {
  content: '?'; }

.ss-pool:before, .ss-pool.right:after {
  content: '?'; }

.ss-shower:before, .ss-shower.right:after {
  content: '??'; }

.ss-exercise:before, .ss-exercise.right:after {
  content: '?'; }

.ss-exercisebike:before, .ss-exercisebike.right:after {
  content: '?'; }

.ss-dumbbell:before, .ss-dumbbell.right:after {
  content: '?'; }

.ss-jumprope:before, .ss-jumprope.right:after {
  content: '?'; }

.ss-yoga:before, .ss-yoga.right:after {
  content: '?'; }

.ss-suitcase:before, .ss-suitcase.right:after {
  content: '?'; }

.ss-luggage:before, .ss-luggage.right:after {
  content: '?'; }

.ss-donotdisturb:before, .ss-donotdisturb.right:after {
  content: '?'; }

.ss-sunscreen:before, .ss-sunscreen.right:after {
  content: '?'; }

.ss-callbell:before, .ss-callbell.right:after {
  content: '?'; }

.ss-hospital:before, .ss-hospital.right:after {
  content: '?'; }

.ss-medicalcross:before, .ss-medicalcross.right:after {
  content: '?'; }

.ss-ambulance:before, .ss-ambulance.right:after {
  content: '??'; }

.ss-bandage:before, .ss-bandage.right:after {
  content: '?'; }

.ss-medicalthermometer:before, .ss-medicalthermometer.right:after {
  content: '?'; }

.ss-stethoscope:before, .ss-stethoscope.right:after {
  content: '?'; }

.ss-syringe:before, .ss-syringe.right:after {
  content: '??'; }

.ss-pill:before, .ss-pill.right:after {
  content: '??'; }

.ss-pillbottle:before, .ss-pillbottle.right:after {
  content: '?'; }

.ss-supplements:before, .ss-supplements.right:after {
  content: '?'; }

.ss-bathroomscale:before, .ss-bathroomscale.right:after {
  content: '?'; }

.ss-dna:before, .ss-dna.right:after {
  content: '?'; }

.ss-anatomicalheart:before, .ss-anatomicalheart.right:after {
  content: '?'; }

.ss-checkheart:before, .ss-checkheart.right:after {
  content: '?'; }

.ss-eyesurgery:before, .ss-eyesurgery.right:after {
  content: '?'; }

.ss-brokenbone:before, .ss-brokenbone.right:after {
  content: '?'; }

.ss-up:before, .ss-up.right:after {
  content: '?'; }

.ss-upright:before, .ss-upright.right:after {
  content: '?'; }

.ss-right:before, .ss-right.right:after {
  content: '?'; }

.ss-downright:before, .ss-downright.right:after {
  content: '?'; }

.ss-down:before, .ss-down.right:after {
  content: '?'; }

.ss-downleft:before, .ss-downleft.right:after {
  content: '?'; }

.ss-left:before, .ss-left.right:after {
  content: '?'; }

.ss-upleft:before, .ss-upleft.right:after {
  content: '?'; }

.ss-navigateup:before, .ss-navigateup.right:after {
  content: '?'; }

.ss-navigateright:before, .ss-navigateright.right:after {
  content: '?'; }

.ss-navigatedown:before, .ss-navigatedown.right:after {
  content: '?'; }

.ss-navigateleft:before, .ss-navigateleft.right:after {
  content: '?'; }

.ss-directup:before, .ss-directup.right:after {
  content: '?'; }

.ss-directright:before, .ss-directright.right:after {
  content: '?'; }

.ss-dropdown:before, .ss-dropdown.right:after {
  content: '?'; }

.ss-directleft:before, .ss-directleft.right:after {
  content: '?'; }

.ss-leftright:before, .ss-leftright.right:after {
  content: '?'; }

.ss-rightward:before, .ss-rightward.right:after {
  content: '?'; }

.ss-leftward:before, .ss-leftward.right:after {
  content: '?'; }

.ss-previouspage:before, .ss-previouspage.right:after {
  content: '?'; }

.ss-nextpage:before, .ss-nextpage.right:after {
  content: '?'; }

.ss-retweet:before, .ss-retweet.right:after {
  content: '?'; }

.ss-share:before, .ss-share.right:after {
  content: '?'; }

/* betco header ------------------------------------------------------------ */
/* header ------------------------------------------------------------ */
/*
009cde  light blue
004c97  dark blue
    */
.print-header {
  display: none; }

.header {
  /*background-color: #0054A0;*/
  background-color: #003e80;
  height: 100px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  margin: 0 auto; }

.header-sas {
  /*background-image: url(../Images/stripes-blue-dark.png);*/
  height: 110px;
  width: 100%; }

.header-container {
  position: relative;
  margin: 0 auto;
  height: 100px; }

.header-margin {
  height: 100px; }

/* brandbox ------------------------------------------------------------ */
.logobox {
  width: 176px;
  height: 100px;
  background-image: url(../Images/logo.svg);
  background-position: center center;
  background-repeat: no-repeat;
  float: left; }

.brandbox {
  margin-left: 0px;
  margin-right: 0px;
  float: left; }

a.brandbox-link {
  width: 106px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../Images/logo.svg); }

.brandbox-link {
  float: left;
  font-size: 18px;
  line-height: 20px;
  height: 100px;
  margin-left: 20px; }

.brandbox-text {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/* navbox ------------------------------------------------------------ */
.navbox {
  float: left;
  display: block; }

.navbox .k-menu .k-item > .k-link {
  padding: 0px 0px 0px 0px; }

.navbox .k-icon {
  position: relative;
  z-index: 100001; }

.navbox .k-i-arrow-s,
.navbox .k-menu-expand-arrow {
  background-image: none;
  background-image: url(../Images/nav-arrow-down-white.png);
  background-position: center;
  width: 8px;
  height: 6px;
  top: 1px;
  margin-right: 5px !important;
  margin-left: 6px !important; }

.navbox li.k-state-hover .k-i-arrow-s,
.navbox li.k-state-hover .k-menu-expand-arrow {
  background-image: url(../Images/nav-arrow-down-blue.png) !important;
  background-position: center !important; }

.k-state-border-down:hover > a > .k-menu-expand-arrow {
  background-image: url(../Images/nav-arrow-down-blue.png) !important; }

.k-i-arrow-60-down:before {
  content: " "; }

.navbox .k-item a {
  min-height: 49px;
  padding: 41px 10px 10px 10px !important;
  text-transform: capitalize;
  color: #fff !important;
  font-size: .8em; }

.navbox .k-item a:hover {
  color: #003e80 !important;
  background-color: #fff; }

.navbox .k-group .k-item a {
  padding-top: 0px !important;
  color: #222222 !important; }

.navbox .k-menu-horizontal.k-widget .k-item {
  border: 0px; }

.navbox .sfNavWrp .sfSel {
  font-weight: normal; }

.navbox .k-menu .k-group {
  border-style: solid;
  border-width: 0px !important;
  background-color: #fff;
  margin-top: 1px;
  margin-left: 0px !important;
  padding-top: 10px;
  padding-bottom: 10px; }

.navbox .k-state-border-down {
  background-color: #fff; }

.navbox .k-state-border-down a {
  color: #003e80 !important; }

.navbox .k-state-border-down .k-i-arrow-s {
  background-image: url(../Images/nav-arrow-down-blue.png) !important;
  background-position: center !important; }

.navbox .k-group .k-item a {
  padding: 4px 14px 4px 14px !important;
  color: #222222 !important;
  min-height: 0px !important; }

.navbox .k-group .k-item a:hover {
  color: #fff !important;
  background-color: #003e80; }

.navbox .k-group {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box; }

.navbox :root .k-group {
  margin-top: 2px \0/IE9; }

.navbox .sfNavSelect {
  height: 34px;
  width: 220px;
  border: 0px;
  margin-top: 32px;
  margin-left: 40px;
  color: #000;
  background-image: url(../Images/ddl-caret-white.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-left: 6px;
  overflow: hidden; }

/* searchbox ------------------------------------------------------------ */
.k-textbox, .k-autocomplete, .k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap {
  border-width: 0px;
  border-style: solid;
  padding: 0 0 0 0; }

.searchbox {
  float: right;
  margin-top: 27px; }

.searchbox fieldset span {
  margin-left: -12px; }

/*#search_TFB8053E7008_ctl00_ctl00_searchButton {
    background: url(../Images/search-icon-red.png) no-repeat;
    cursor: pointer;
    width: 43px;
    border: none;
    float: left;
    height: 34px;
}

#search_TFB8053E7008_ctl00_ctl00_searchTextBox {
    width: 90% !important;
    height: 34px;
    padding: 0px 8px 0px 0px;
    border: 0px;
    float: right;
}*/
/*#search_TFB8053E7025_ctl00_ctl00_searchButton {
    background: url(../Images/search-icon-red.png) no-repeat;
    cursor: pointer;
    width: 43px;
    border: none;
    float: left;
    height: 34px;
}

#search_TFB8053E7025_ctl00_ctl00_searchButton {
    width: 90% !important;
    height: 34px;
    padding: 0px 8px 0px 0px;
    border: 0px;
    float: left;
}*/
.rsearch {
  display: none;
  border: solid 0px #fff;
  height: 30px;
  width: 30px;
  float: right;
  margin-top: 33px; }

.rsearch-text {
  cursor: pointer;
  width: 40px;
  border: none;
  float: right;
  height: 34px; }

.rsearch-text-o {
  background: url(../Images/search-icon-open.png) no-repeat;
  background-position: center center;
  background-position-y: 1px;
  margin-right: 10px;
  color: #ff00ff; }

.rsearch-text-c {
  background: url(../Images/search-icon-closed.png) no-repeat;
  background-position: center center;
  background-position-y: 1px;
  margin-right: 10px; }

.k-popup.k-list-container, .k-popup.k-calendar-container {
  display: none !important; }

/*#search_TFB8053E7008_ctl00_ctl00_searchButton {
    color: #003e80;
}*/
#search_TFB8053E7025_ctl00_ctl00_searchButton {
  color: #003e80; }

/* mast image swap  ------------------------------------------------------------ */
.masthead-large,
.masthead-medium,
.masthead-small {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  display: none;
  width: 100%; }
  .masthead-large .mastheadimage,
  .masthead-medium .mastheadimage,
  .masthead-small .mastheadimage {
    padding: 2rem 0;
    overflow-wrap: break-word;
    color: #fff;
    text-shadow: 0 0 3px #000, 0 5px 10px rgba(0, 0, 0, 0.6); }
    @media (min-width: 768px) {
      .masthead-large .mastheadimage,
      .masthead-medium .mastheadimage,
      .masthead-small .mastheadimage {
        padding: 4rem 0; } }
    @media (min-width: 1024px) {
      .masthead-large .mastheadimage,
      .masthead-medium .mastheadimage,
      .masthead-small .mastheadimage {
        padding: 6rem 0; } }
    .masthead-large .mastheadimage h1,
    .masthead-large .mastheadimage .h1,
    .masthead-medium .mastheadimage h1,
    .masthead-medium .mastheadimage .h1,
    .masthead-small .mastheadimage h1,
    .masthead-small .mastheadimage .h1 {
      font-size: 3.5rem;
      line-height: 1em; }
    .masthead-large .mastheadimage h2,
    .masthead-large .mastheadimage .h2,
    .masthead-medium .mastheadimage h2,
    .masthead-medium .mastheadimage .h2,
    .masthead-small .mastheadimage h2,
    .masthead-small .mastheadimage .h2 {
      margin-top: 0.5rem;
      font-size: 2rem;
      line-height: 1em;
      font-weight: normal; }

/* solutions icons ------------------------------------------------------------ */
.m7f4245d4-f865-6eec-859e-ff00005eb5be > li {
  display: block;
  margin-left: 30px; }

.m7f4245d4-f865-6eec-859e-ff00005eb5be a {
  background-image: url(../Images/sp-bulb-red.png);
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8px;
  text-indent: 16px; }

.m7f4245d4-f865-6eec-859e-ff00005eb5be a:hover {
  background-image: url(../Images/sp-bulb-white.png);
  background-repeat: no-repeat; }

/* footer ------------------------------------------------------------ */
.footer {
  clear: both;
  background-color: #003e80;
  position: relative;
  width: 100%;
  margin: 0 auto;
  border: solid 1px #003e80;
  margin-top: 20px; }

.footer-sas {
  /*background-image: url(../Images/stripes-blue-dark.png);*/
  position: relative;
  width: 100%;
  margin-top: -10px;
  overflow: auto; }

.footer-container {
  position: relative;
  color: #fff;
  margin-bottom: 90px;
  margin-top: 60px; }

.footer-container a {
  color: #fff;
  text-decoration: none; }

.footer h1 {
  position: relative;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  min-width: 10px;
  float: left;
  z-index: 100;
  margin-top: 30px;
  margin-left: -1px;
  line-height: .85em; }

.footer .h1 {
  position: relative;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  min-width: 10px;
  float: left;
  z-index: 100;
  margin-top: 30px;
  margin-left: -1px;
  line-height: .85em; }

.footer h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px; }

.footer h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.footer .h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px; }

.footer .h1 strong {
  color: rgba(255, 255, 255, 0.3); }

/* contact form ------------------------------------------------------------ */
.contactform label {
  display: block;
  position: relative;
  border: solid 0px #bbb;
  color: #bbb;
  z-index: 200;
  left: 15px;
  font-size: .8em;
  margin-top: -8px;
  top: 25px; }

.contactform input[type=text] {
  width: 80%;
  border: 0px;
  height: 30px;
  font-size: .8em;
  padding-left: 15px; }

.contactform input[type=text]:hover, .contactform textarea:hover {
  -moz-box-shadow: 0px 0px 4px #ffffff;
  -webkit-box-shadow: 0px 0px 4px #ffffff;
  box-shadow: 0px 0px 4px #ffffff; }

.contactform textarea {
  width: 79.6%;
  border: 0px;
  height: 60px;
  font-size: 12px;
  padding-left: 15px;
  padding-top: 10px; }

.contactform input[type=submit] {
  width: 170px;
  border: 0px;
  height: 32px;
  font-size: .8em;
  background-color: transparent;
  margin-top: 9px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  border-left: solid 1px #fff;
  color: #fff; }

.pagecontent .sfsearchTxt {
  width: 200px;
  border: 1px;
  height: 30px;
  font-size: .8em;
  background-color: transparent;
  margin-top: 9px;
  border-top: solid 1px #ccc;
  border-right: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  border-left: solid 1px #ccc;
  color: #000;
  padding-left: 10px; }

.contactform input[type="submit"]:hover {
  background-color: #fff;
  color: #000; }

.rclass {
  color: #fff !important;
  z-index: -200 !important; }

.contactform .half {
  width: 44.5%;
  float: left; }

/* products open/close menu ------------------------------------------------------------ */
.productselector-close {
  display: block;
  background-image: url(../Images/close-toggle.png);
  background-repeat: no-repeat;
  width: 10%;
  height: 14px;
  float: right;
  cursor: pointer;
  margin-top: 8px;
  display: none; }

.productselector-open {
  display: block;
  position: relative;
  width: 50px;
  text-align: right;
  top: -60px;
  background-image: url(../Images/menu-toggle.png);
  background-position-x: right;
  background-repeat: no-repeat;
  height: 18px;
  cursor: pointer;
  float: right;
  display: none;
  padding-right: 20px; }

/* product selection menu ------------------------------------------------------------ */
.productcontrol {
  display: block;
  position: relative;
  margin-top: 40px;
  min-height: 1px;
  width: 100%; }

.productselector {
  width: 250px;
  color: #fff;
  float: right; }

.productselector .deco1 {
  height: 20px;
  width: 100%;
  background-color: #003e80;
  /*background-image: url(../Images/stripes-blue-udark.png);*/ }

.deco2 {
  display: block;
  height: 10px;
  width: 100%;
  /*background-image: url(../Images/stripes-blue-udark.png);*/ }

.productselector h5 {
  font-size: 22px;
  padding: 25px 0px 20px 0px;
  margin: 0px;
  font-weight: bold;
  color: #fff;
  width: 95%;
  float: left; }

.productselector h6 {
  font-size: 19px;
  padding: 20px 0px 10px 20px;
  margin: 0px;
  font-weight: bold;
  color: #fff; }

.productselector ul {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.productselector li {
  margin-top: 4px; }

#product-lines-navigation {
  background-color: #003e80;
  padding-bottom: 20px; }

.product-types li {
  padding-left: 20px; }

.product-types li a {
  color: #fff;
  font-size: 14px;
  line-height: 24px; }

.product-sub-types li {
  margin-left: 5px;
  background-repeat: no-repeat;
  padding-left: 28px; }

a.navigation-item {
  background-image: url(../Images/checkbox-off.png);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  padding-left: 24px; }

a.sel {
  background-image: url(../Images/checkbox-on.png);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  padding-left: 24px; }

.product-filter {
  /*background-image: url(../Images/stripes-blue-udark.png);*/
  padding: 0px 0px 20px 20px;
  background-color: #003e80; }

.navigation-options li {
  background-color: #fff;
  display: inline-block;
  text-align: center;
  width: 44%;
  margin: 0px 6px 7px 0px; }

.navigation-options li a {
  font-size: 14px;
  height: 24px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  width: 100%;
  padding-top: 6px; }

.navigation-options a.selected {
  background-color: #b3c1d2; }

#show-icons {
  background-image: url(../Images/ico-ico.png);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px; }

#show-list {
  background-image: url(../Images/ico-list.png);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px; }

#Ascending {
  background-image: url(../Images/ico-pin.png);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px; }

#Descending {
  background-image: url(../Images/ico-a.png);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px; }

#products-data {
  display: block;
  position: relative;
  float: left; }

/* product selection grid ------------------------------------------------------------ */
.gallerylist ul {
  float: left;
  width: 100%;
  list-style: none; }

#galleryresults li {
  float: left;
  padding: 0;
  margin: 0 2% 22px 9px;
  border-radius: 8px;
  cursor: pointer;
  height: 350px; }

.gallery-image-container {
  width: 245px;
  height: 340px;
  display: inline-block;
  background-color: white;
  vertical-align: middle; }

.ajax-spinner {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  background-image: url("../Images/ajax-loader-large-pie.gif");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.img-list-transitional {
  width: 30% !important;
  display: inline;
  float: left; }

.div-list {
  text-align: left !important; }

.gallery-image-container-list {
  width: 30% !important;
  height: auto !important; }

.gallery-item-name-container {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.1;
  font-family: 'Montserrat', sans-serif; }

.tabular-data-paging-controls {
  float: right;
  padding: 0;
  margin: 5px 0px 40px 0px; }

.tabular-data-paging-controls .inactive {
  background-color: #000 !important;
  color: #fff !important; }

.pager-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  text-align: center;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
  float: left;
  display: inline-table;
  margin: 1px;
  padding: 0px 5px; }

.gallery-item-name-container {
  font-size: 17px;
  font-weight: bold; }

.gallery-item-description-container {
  font-size: 14px; }

/* product details ------------------------------------------------------------ */
.video-details {
  width: 58%;
  max-width: 100%; }

.sizes-line {
  width: 42%; }

.sizes-line, .video-details {
  position: relative;
  min-height: 1px;
  padding-top: 20px;
  padding-top: 20px;
  float: left;
  /*width: 58%;*/ }

.sub-container {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  width: 100%; }

.product-video {
  background-size: contain;
  -webkit-background-size: contain;
  background-position: center center;
  padding: 0;
  position: relative;
  margin-bottom: 20px;
  max-width: 100%; }

.details-container {
  padding: 0;
  width: 33.33%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-bottom: 20px; }

.similar-products {
  display: block;
  opacity: 1;
  position: relative;
  width: 100%; }

.sub-container:before, .sub-container:after {
  display: none;
  clear: none;
  content: none; }

.main-container {
  display: block;
  content: " ";
  clear: both;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 0; }

.main-container h5 {
  color: #fff !important;
  /*background-image: url(../Images/stripes-blue-udark.png);*/
  padding: 10px;
  background-color: #152d61; }

.main-container .h5 {
  color: #fff !important;
  /*background-image: url(../Images/stripes-blue-udark.png);*/
  padding: 10px;
  background-color: #152d61; }

.main-container h1 {
  margin-top: 34px; }

.main-container .h1 {
  margin-top: 34px; }

.sizes h5 {
  background: none;
  color: #000 !important;
  padding-bottom: 0px; }

.arrowshim {
  margin-top: -20px; }

.breadcrumbs {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
  font-size: .90em; }

.breadcrumbs a {
  color: #e31937;
  text-decoration: none; }

.image-container {
  width: 300px;
  height: 300px;
  margin: 0 auto; }

.sizes p,
.additional-videos p {
  color: #e31937;
  border: 1px solid #ebebeb;
  padding: 5px 20px 5px 20px;
  display: inline-block;
  font-weight: bold;
  margin: 4px 2px; }

.sizes .item-number {
  display: block;
  font-size: 12px;
  color: #666;
  font-weight: normal;
  margin-top: -3px; }

.product-description {
  padding-right: 40px; }

.product-family {
  margin-top: 60px; }

.sizes .btn {
  margin-top: 20px; }

.btn {
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease; }

a.btn-primary {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #bd0010;
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  background-color: #d30927; }

a:hover.btn-primary {
  background-color: #bd0010; }

.tech-specs {
  padding: 0;
  position: relative; }

.tech-specs h5,
.direction-of-use h5,
.documents h5,
.similar h5 {
  color: #fff;
  background-color: #003f7f;
  margin: 0;
  padding: 10px 20px; }

.direction-of-use .details-content, .tech-specs .details-content, .documents .details-content {
  height: 300px;
  overflow: scroll; }

.details-content {
  padding: 25px 20px 0px 20px; }

.details-dir, .details-desc {
  padding: 9px 20px 0px 20px; }

.details-dir {
  overflow: scroll;
  height: 316px; }

.details-features {
  /*height: 150px;*/
  overflow: none;
  padding: 25px 20px 0px 20px; }

.tech-specs .row:nth-child(odd) {
  background-color: #ebebeb !important; }

.tech-specs .row {
  margin: 0;
  padding: 0; }

.tech-spec-key, .tech-spec-value {
  padding: 0;
  margin: 0;
  width: 50%;
  float: left; }

.product-features .tech-spec-key {
  background-image: url(../Images/sp-check-blue.png);
  background-position: 0px 6px, center;
  background-repeat: no-repeat;
  padding: 3px 0px 3px 23px;
  margin: 0; }

.product-features .row:nth-child(odd) {
  background-color: #fff !important; }

.product-features .tech-spec-key {
  background-image: url(../Images/sp-check-blue.png);
  background-position: 0px 6px, center;
  background-repeat: no-repeat;
  padding: 3px 0px 3px 23px;
  margin: 0; }

ul.links {
  margin-left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0; }

.similar h5 {
  color: #fff !important;
  /*background-image: url(../Images/stripes-blue-light.png);*/
  background-color: #166bc5; }

.printleft, .printright {
  display: none; }

.product-competitors {
  padding: 10px 10px 10px 20px;
  margin: 30px 0px 0px 0px;
  min-height: 170px; }

.product-competitors {
  width: 220px;
  display: block;
  background-color: #166bc5;
  /*background-image: url(../Images/stripes-blue-light.png);*/
  min-height: 170px;
  padding-bottom: 20px; }

.product-competitors-sas {
  /*background-image: url(../Images/stripes-blue-light.png);*/
  height: 10px; }

.product-competitors h5 {
  color: #fff;
  font-size: 20px;
  padding: 0px;
  margin: 10px 0px 10px 0px;
  width: 100%; }

.product-competitors .view-competition-btn {
  color: #666;
  font-size: 14px;
  width: 200px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px 30px 10px 2px;
  border: none;
  margin-bottom: 10px;
  background: #a7b8cb; }

.product-competitors select {
  color: #666;
  font-size: 14px;
  width: 200px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px 30px 10px 2px;
  border: none;
  margin-bottom: 10px; }

.product-competitors -space {
  /*background-image: url(../Images/stripes-blue-light.png);*/
  height: 10px;
  width: 220px; }

/* margin and padding setup ------------------------------------------------------------ */
.sfContentBlock {
  padding: 0px 10px 0px 10px; }

.sfdownloadFileWrp {
  padding-left: 10px; }

.nolist li {
  background: none !important; }

/* left hand page arrows ------------------------------------------------------------ */
.pagearrow {
  left: -1980px;
  background-repeat: repeat-x;
  height: 48px;
  width: 1980px;
  float: left;
  position: absolute;
  margin-top: 32px; }

.arrowswhite {
  /*background-image: url(../Images/title-arrow-white-48.png);*/ }

.arrowsgray {
  /*background-image: url(../Images/title-arrow-gray-48.png);*/ }

/* colorbars ------------------------------------------------------------ */
.white-lightblue h1,
.white-darkblue h1,
.lightgray h1,
.darkgray h1,
.lightblue h1,
.darkblue h1,
.darkred h1,
.white-default h1 {
  position: relative;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  min-width: 10px;
  z-index: 100;
  margin-top: 31px;
  margin-left: -1px;
  line-height: .85em; }

.white-lightblue .h1,
.white-darkblue .h1,
.lightgray .h1,
.darkgray .h1,
.lightblue .h1,
.darkblue .h1,
.darkred .h1,
.white-default .h1 {
  position: relative;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
  min-width: 10px;
  z-index: 100;
  margin-top: 31px;
  margin-left: -1px;
  line-height: .85em; }

.footer h1 {
  width: 100%; }

.footer .h1 {
  width: 100%; }

/* lightgray -------------------------- */
.lightgray {
  background-color: #e1e1e1;
  min-height: 100px;
  /*padding: 0px 0px 10px 0px;*/
  color: #fff;
  background-color: #fff;
  /*background-image: url(../Images/stripes-gray-light.png);*/
  min-height: 100px; }

.lightgray > div {
  background-color: #e1e1e1;
  min-height: 100px;
  /*background-image: url(../Images/stripes-gray-light.png);*/
  padding: 60px 0px 60px 0px;
  color: #000; }

.white-lightgray > div {
  background-color: #fff;
  min-height: 100px;
  padding: 60px 0px 60px 0px;
  color: #000; }

.Contentplaceholder2 .lightgray h1 {
  color: #fff;
  margin-bottom: 40px;
  text-transform: uppercase; }

.Contentplaceholder2 .lightgray .h1 {
  color: #fff;
  margin-bottom: 40px;
  text-transform: uppercase; }

.lightgray h1 strong {
  color: #fff; }

.lightgray h1 {
  color: #fff;
  margin-bottom: 40px; }

.lightgray .h1 strong {
  color: #fff; }

.lightgray .h1 {
  color: #fff;
  margin-bottom: 40px; }

.lightgray ul {
  padding: 0px 0px 0px 5px;
  margin: 0px; }

.lightgray li {
  background: url(../Images/sp-check-red.png) no-repeat left 6px;
  padding: 3px 0px 3px 30px;
  list-style: none;
  margin: 0; }

/* white-default -------------------------- */
.white-default {
  background-color: #fff;
  min-height: 100px;
  padding: 40px 0px 60px 0px; }

.white-default > div {
  background-color: #e1e1e1;
  min-height: 100px;
  background-color: #fff; }

.white-default h1 {
  color: #d1d1d1;
  margin-bottom: 20px; }

.white-default h1 strong {
  color: #166bc5; }

.white-default .h1 {
  color: #d1d1d1;
  margin-bottom: 20px; }

.white-default .h1 strong {
  color: #166bc5; }

.white-default h2,
.white-default h3,
.white-default h4,
.white-default h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-default .h2,
.white-default .h3,
.white-default .h4,
.white-default .h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-default h2 {
  color: #267bd5; }

.white-default h3 {
  color: #000; }

.white-default h4 {
  color: #000; }

.white-default h5 {
  color: #d1d1d1; }

.white-default .h2 {
  color: #267bd5; }

.white-default .h3 {
  color: #000; }

.white-default .h4 {
  color: #000; }

.white-default .h5 {
  color: #d1d1d1; }

.white-default .sfdownloadFileWrp a, .white-default .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #267bd5;
  color: #166bc5;
  text-decoration: none;
  margin-top: 10px; }

.white-default .sfdownloadFileWrp a:hover, .white-default .download a:hover {
  background-color: #166bc5;
  color: #fff; }

.white-default .quote {
  border-left: solid 3px #ccc;
  padding-left: 20px;
  line-height: 1.42857143; }

/* white-lightblue -------------------------- 009cde  light blue
004c97  dark blue*/
.white-lightblue {
  background-color: #fff;
  min-height: 100px; }

.white-lightblue > div {
  background-color: #e1e1e1;
  min-height: 100px;
  background-color: #fff;
  padding: 40px 0px 60px 0px; }

.white-lightblue h1 {
  color: #d1d1d1;
  margin-bottom: 40px;
  width: 100%;
  line-height: .85em; }

.white-lightblue .h1 strong {
  color: #166bc5; }

.white-lightblue .h1 {
  color: #d1d1d1;
  margin-bottom: 40px;
  width: 100%;
  line-height: .85em; }

.white-lightblue h1 strong {
  color: #166bc5; }

.white-lightblue h2,
.white-lightblue h3,
.white-lightblue h4,
.white-lightblue h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-lightblue .h2,
.white-lightblue .h3,
.white-lightblue .h4,
.white-lightblue .h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-lightblue h2 {
  color: #267bd5; }

.white-lightblue h3 {
  color: #000; }

.white-lightblue h4 {
  color: #000; }

.white-lightblue h5 {
  color: #d1d1d1; }

.white-lightblue .h2 {
  color: #267bd5; }

.white-lightblue .h3 {
  color: #000; }

.white-lightblue .h4 {
  color: #000; }

.white-lightblue .h5 {
  color: #d1d1d1; }

.white-lightblue .sfdownloadFileWrp a,
.white-lightblue .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #267bd5;
  color: #166bc5;
  text-decoration: none;
  margin-top: 10px; }

.white-lightblue .sfdownloadFileWrp a:hover,
.white-lightblue .download a:hover {
  background-color: #166bc5;
  color: #fff; }

.white-lightblue li {
  background: url(../Images/sp-check-blue.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

.white-lightblue .quote {
  border-left: solid 3px #ccc;
  padding-left: 20px;
  line-height: 1.42857143; }

/* white-darkblue -------------------------- */
.white-darkblue {
  background-color: #fff;
  min-height: 100px; }

.white-darkblue > div {
  background-color: #e1e1e1;
  background-color: #fff;
  padding: 40px 0px 40px 0px; }

.white-darkblue h1 {
  color: #ccc;
  margin-bottom: 40px;
  width: 100%;
  font-weight: bold;
  line-height: .85em; }

.white-darkblue h1 strong {
  color: #003e80; }

.white-darkblue .h1 {
  color: #ccc;
  margin-bottom: 40px;
  width: 100%;
  font-weight: bold;
  line-height: .85em; }

.white-darkblue .h1 strong {
  color: #003e80; }

.white-darkblue h2,
.white-darkblue h3,
.white-darkblue h4,
.white-darkblue h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-darkblue .h2,
.white-darkblue .h3,
.white-darkblue .h4,
.white-darkblue .h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-darkblue h2 {
  color: #003e80; }

.white-darkblue h3 {
  color: #000; }

.white-darkblue h4 {
  color: #000; }

.white-darkblue h5 {
  color: #d1d1d1;
  font-weight: normal; }

.white-darkblue .h2 {
  color: #003e80; }

.white-darkblue .h3 {
  color: #000; }

.white-darkblue .h4 {
  color: #000; }

.white-darkblue .h5 {
  color: #d1d1d1;
  font-weight: normal; }

.white-darkblue .sfdownloadFileWrp a,
.white-darkblue .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #003e80;
  color: #003e80;
  text-decoration: none;
  margin-top: 10px;
  font-size: 14px; }

.white-darkblue .sfdownloadFileWrp a:hover,
.white-darkblue .download a:hover {
  background-color: #003e80;
  color: #fff; }

.white-darkblue li {
  background: url(../Images/sp-check-blue.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

.white-darkblue .quote {
  border-left: solid 3px #ccc;
  padding-left: 20px;
  line-height: 1.42857143; }

/* darkgray -------------------------- */
.darkgray {
  background-color: #fff;
  /*background-image: url(../Images/stripes-gray-dark.png);*/
  min-height: 100px;
  /*padding-bottom: 10px;*/ }

.darkgray > div {
  background-color: #989898;
  /*background-image: url(../Images/stripes-gray-dark.png);*/
  color: #fff;
  padding: 40px 0px 40px 0px; }

.darkgray h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px;
  width: 100%; }

.darkgray h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.darkgray .h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px;
  width: 100%; }

.darkgray .h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.darkgray .sfdownloadFileWrp a,
.darkgray .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  margin-top: 10px; }

.darkgray .sfdownloadFileWrp a:hover, .darkgray .download a:hover {
  background-color: #fff;
  color: #989898; }

.darkgray li {
  background: url(../Images/sp-check-white.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

/* lightblue -------------------------- */
.lightblue {
  background-color: #fff;
  /*background-image: url(../Images/stripes-blue-light.png);*/
  min-height: 100px;
  /*padding-bottom: 10px;*/ }

.lightblue > div {
  background-color: #166bc5;
  /*background-image: url(../Images/stripes-blue-light.png);*/
  color: #fff;
  padding: 40px 0px 40px 0px; }

.lightblue h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px;
  width: 100%; }

.lightblue .h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px;
  width: 100%; }

.lightblue h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.lightblue .h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.lightblue h2,
.lightblue h3,
.lightblue h4,
.lightblue h5 {
  margin-top: 10px;
  margin-bottom: 10px; }

.lightblue .h2,
.lightblue .h3,
.lightblue .h4,
.lightblue .h5 {
  margin-top: 10px;
  margin-bottom: 10px; }

.lightblue h2 {
  color: #fff; }

.lightblue h3 {
  color: #fff; }

.lightblue h4 {
  color: #fff; }

.lightblue h5 {
  color: #fff; }

.lightblue .h2 {
  color: #fff; }

.lightblue .h3 {
  color: #fff; }

.lightblue .h4 {
  color: #fff; }

.lightblue .h5 {
  color: #fff; }

.lightblue .sfdownloadFileWrp a,
.lightblue .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  font-size: 14px; }

.lightblue .sfdownloadFileWrp a:hover,
.lightblue .download a:hover {
  background-color: #fff;
  color: #166bc5; }

.lightblue li {
  background: url(../Images/sp-check-white.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

/* darkblue -------------------------- */
/*  009cde  light blue
004c97  dark blue*/
.darkblue {
  background-color: #fff;
  /*background-image: url(../Images/stripes-blue-dark.png);*/
  min-height: 100px;
  padding-bottom: 10px; }

.darkblue > div {
  background-color: #003e80;
  /*background-image: url(../Images/stripes-blue-dark.png);*/
  color: #fff;
  padding: 40px 0px 40px 0px; }

.darkblue h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px;
  width: 100%; }

.darkblue h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.darkblue .h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px;
  width: 100%; }

.darkblue .h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.darkblue h2,
.darkblue h3,
.darkblue h4,
.darkblue h5 {
  margin-top: 10px;
  margin-bottom: 10px; }

.darkblue .h2,
.darkblue .h3,
.darkblue .h4,
.darkblue .h5 {
  margin-top: 10px;
  margin-bottom: 10px; }

.darkblue h2 {
  color: #fff; }

.darkblue h3 {
  color: #fff; }

.darkblue h4 {
  color: #fff; }

.darkblue h5 {
  color: #fff; }

.darkblue .h2 {
  color: #fff; }

.darkblue .h3 {
  color: #fff; }

.darkblue .h4 {
  color: #fff; }

.darkblue .h5 {
  color: #fff; }

.darkblue li {
  background: url(../Images/sp-check-white.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

/* darkred -------------------------- */
.darkred {
  background-color: #fff;
  /*background-image: url(../Images/stripes-red-dark.png);*/
  min-height: 100px;
  padding-bottom: 10px; }

.darkred > div {
  background-color: #d80e2c;
  /*background-image: url(../Images/stripes-red-dark.png);*/
  color: #fff;
  padding: 30px 0px 40px 0px; }

.darkred h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px; }

.darkred h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.darkred .h1 {
  color: rgba(255, 255, 255, 0.3);
  margin-bottom: 40px; }

.darkred .h1 strong {
  color: rgba(255, 255, 255, 0.3); }

.darkred h2 {
  color: #fff; }

.darkred h3 {
  color: #fff; }

.darkred h4 {
  color: #fff; }

.darkred h5 {
  color: #fff; }

.darkred .h2 {
  color: #fff; }

.darkred .h3 {
  color: #fff; }

.darkred .h4 {
  color: #fff; }

.darkred .h5 {
  color: #fff; }

.darkred p {
  color: #fff; }

.darkred li {
  color: #fff; }

.darkred .sfdownloadFileWrp a,
.darkred .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  background-color: #d30927;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  margin-top: 10px; }

.darkred .sfdownloadFileWrp a:hover,
.darkred .download a:hover {
  background-color: #fff;
  color: #d30927; }

.darkred li {
  background: url(../Images/sp-check-white.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

/* products -------------------------- */
.white-products .pagearrow {
  left: -1980px;
  background-repeat: repeat-x;
  height: 48px;
  width: 1980px;
  float: left;
  position: absolute;
  margin-top: 0px; }

.white-products {
  background-color: #fff;
  min-height: 100px; }

.white-products > div {
  background-color: #e1e1e1;
  min-height: 100px;
  background-color: #fff;
  padding: 40px 0px 60px 0px; }

.white-products h1 {
  color: #d1d1d1;
  width: 100%;
  line-height: .85em; }

.white-products h1 strong {
  color: #166bc5; }

.white-products .h1 {
  color: #d1d1d1;
  width: 100%;
  line-height: .85em; }

.white-products .h1 strong {
  color: #166bc5; }

.white-products h2,
.white-products h3,
.white-products h4,
.white-products h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-products .h2,
.white-products .h3,
.white-products .h4,
.white-products .h5 {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px; }

.white-products h2 {
  color: #267bd5; }

.white-products h3 {
  color: #000; }

.white-products h4 {
  color: #000; }

.white-products h5 {
  color: #fff; }

.white-products .h2 {
  color: #267bd5; }

.white-products .h3 {
  color: #000; }

.white-products .h4 {
  color: #000; }

.white-products .h5 {
  color: #fff; }

.white-products .sfdownloadFileWrp a,
.white-products .download a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #267bd5;
  color: #166bc5;
  text-decoration: none;
  margin-top: 10px; }

.white-products .sfdownloadFileWrp a:hover,
.white-products .download a:hover {
  background-color: #166bc5;
  color: #fff; }

/* horzizontal spacers ------------------------------------------------------------ */
.spacer10 {
  height: 10px; }

.spacer20 {
  height: 20px; }

.spacer30 {
  height: 30px; }

.spacer40 {
  height: 40px; }

.spacer50 {
  height: 50px; }

/* big white text ------------------------------------------------------------ */
.bigtagwhite {
  font-size: 3.8em;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: .8;
  letter-spacing: -.05em; }

/* vimeo ------------------------------------------------------------ */
.vimeo {
  width: 200px;
  height: 200px;
  position: relative;
  border: solid 0px #000;
  float: left;
  margin: 6px;
  text-align: center;
  display: table; }

.vimeo a {
  display: table-footer-group;
  font-weight: bold; }

.vimeo img {
  display: table-header-group;
  border: solid 1px #666; }

.side-nav {
  float: right; }

/* preset image widths for full space ------------------------------------------------------------ */
.Contentplaceholder1 img,
.Contentplaceholder2 img,
.Contentplaceholder3 img,
.Contentplaceholder4 img,
.Contentplaceholder5 img,
.Contentplaceholder6 img,
.Contentplaceholder7 img,
.Contentplaceholder8 img,
.Contentplaceholder9 img,
.Contentplaceholder10 img {
  display: block;
  margin: 0 auto;
  /* width: 100%;*/
  height: auto; }

/* accordion style ordered lists - FAQ ------------------------------------------------------------ */
.sflistTitle {
  display: none; }

.alist .sflistExpandAllLnkWrp {
  display: none; }

.alist ol {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.alist h3 {
  /*background-image: url(../Images/stripes-gray-light.png);*/
  background-color: #e1e1e1;
  font-size: 14px; }

.alist h3 a {
  width: 100%;
  text-decoration: none;
  color: #000;
  line-height: 30px;
  display: block;
  padding-left: 30px;
  background-image: url(../Images/list-arrow-right-black.png);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px; }

.alist .sflistListItemExpanded {
  background-image: url(../Images/list-arrow-down-black.png); }

.alist .olist {
  border-left: solid 4px #e0e0e0;
  margin-left: 20px; }

.alist .olist ol {
  display: block;
  padding: 0 0 0 26px;
  list-style: none;
  position: relative;
  counter-reset: numList; }

.alist .olist ol li {
  margin-bottom: 20px;
  margin-right: 44px;
  position: relative;
  padding-left: 10px; }

.alist .olist ol li:before {
  counter-increment: numList;
  content: counter(numList);
  margin-left: -20px;
  position: absolute;
  left: -26px;
  font: bold 12px;
  text-align: center;
  color: #fff;
  line-height: 28px;
  width: 28px;
  height: 28px;
  background: #166bc5;
  -moz-border-radius: 999px;
  border-radius: 999px;
  border: solid 4px #e0e0e0; }

/* accordion style ordered lists - betco u ------------------------------------------------------------ */
.sflistTitle {
  display: none; }

.bulist .sflistExpandAllLnkWrp {
  display: none; }

.bulist {
  display: block;
  position: relative;
  clear: both;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px; }

.bulist ol {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.bulist h3 a:before {
  position: relative;
  content: url("../Images/list-arrow-down-black.png");
  top: 1px;
  right: 7px; }

.bulist h3 a {
  position: relative;
  padding-left: 30px;
  text-decoration: none;
  color: #000;
  line-height: 30px;
  display: block;
  /*background-image: url(../Images/stripes-gray-light.png);*/
  background-color: #e1e1e1;
  font-size: 14px; }

.bulist h3 .sflistListItemExpanded {
  /*background-image: url(../Images/stripes-blue-dark.png);*/
  background-color: #003e80;
  color: #fff; }

.bulist .sflistListItemExpanded:before {
  content: url("../Images/list-arrow-right-white.png");
  top: 1px;
  right: 7px; }

.bulist .olist {
  position: relative;
  border-left: solid 3px #ebebeb;
  margin: -10px 0px 0px 0px; }

.bulist .olist ol {
  display: block;
  padding: 0 0 0 26px;
  list-style: none;
  position: relative;
  counter-reset: numList; }

.bulist .olist ol li {
  margin-bottom: 20px;
  margin-right: 44px;
  position: relative;
  padding-left: 10px; }

.BetcoUVideo {
  padding: 20px; }

.ico-BetcoUVideo {
  position: relative;
  background-image: url(../Images/icon-videos.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin-left: -17px;
  margin-top: 20px;
  float: left; }

.BetcoUModule {
  padding: 20px;
  /*background-image: url(../Images/stripes-gray-light.png);*/ }

.ico-BetcoUModule {
  position: relative;
  z-index: 100;
  background-image: url(../Images/icon-training.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin-left: -17px;
  margin-top: 20px;
  float: left; }

.BetcoUCredits {
  padding: 20px; }

.ico-BetcoUCredits {
  position: relative;
  background-image: url(../Images/icon-credits.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin-left: -17px;
  margin-top: 20px;
  float: left; }

.BetcoUResources {
  padding: 20px;
  /*background-image: url(../Images/stripes-gray-light.png);*/ }

.ico-BetcoUResources {
  position: relative;
  z-index: 100;
  background-image: url(../Images/icon-resources.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin-left: -17px;
  margin-top: 20px;
  float: left; }

/* accordion style ordered lists - blue ------------------------------------------------------------ */
.sflistTitle {
  display: none; }

.bluelist .sflistExpandAllLnkWrp {
  display: none; }

.bluelist {
  display: block;
  position: relative;
  clear: both;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px; }

.bluelist ol {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.bluelist h3 a:before {
  position: relative;
  content: url("../Images/list-arrow-down-black.png");
  top: 1px;
  right: 7px; }

.bluelist h3 a {
  position: relative;
  padding-left: 30px;
  text-decoration: none;
  color: #000;
  line-height: 30px;
  display: block;
  /*background-image: url(../Images/stripes-gray-light.png);*/
  background-color: #e1e1e1;
  font-size: 14px; }

.bluelist h3 .sflistListItemExpanded {
  /*background-image: url(../Images/stripes-blue-light.png);*/
  background-color: #267bd5;
  color: #fff; }

.bluelist .sflistListItemExpanded:before {
  content: url("../Images/list-arrow-right-white.png");
  top: 1px;
  right: 7px; }

.bluelist .olist {
  position: relative;
  border-left: solid 3px #ebebeb;
  margin: -10px 0px 0px 0px; }

.bluelist .olist ol {
  display: block;
  padding: 10px 0 0 5px;
  list-style: none;
  position: relative;
  counter-reset: numList; }

.bluelist .olist ol li {
  margin-bottom: 20px;
  margin-right: 44px;
  position: relative;
  padding-left: 10px; }

/* betco u links ------------------------------------------------------------ */
a.betcoudownload {
  line-height: 22px;
  background-image: url(../Images/icon-dlcloud.png);
  background-repeat: no-repeat;
  padding-left: 20px;
  text-decoration: none;
  font-size: .9em;
  color: #000;
  margin-left: 8px; }

a:hover.betcoudownload {
  color: #166bc5; }

/* betco u course nav ------------------------------------------------------------ */
.coursenav {
  display: block;
  position: relative;
  clear: both;
  padding-left: 10px;
  padding-right: 10px; }

.coursenav ul {
  border-bottom: solid 1px #dddddd;
  width: 100%; }

.coursenav a {
  display: block;
  color: #666666;
  text-decoration: none;
  padding: 10px;
  font-size: .9em;
  line-height: 19px; }

.coursenav a:hover {
  background-color: #eeeeee; }

.coursenav a.sfSel {
  color: #fff;
  text-decoration: none;
  background-color: #e31937;
  font-weight: normal; }

/* area map locator ------------------------------------------------------------ */
img[usemap], map area {
  outline: none; }

.areamap ul {
  list-style-type: none; }

/* checkbox style unordered lists ------------------------------------------------------------ */
.checkbox-red li {
  background: url(../Images/sp-check-red.png) no-repeat left center;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

.checkbox-blue li {
  background: url(../Images/sp-check-blue.png) no-repeat left 6px;
  padding: 3px 0px 3px 23px;
  list-style: none;
  margin: 0; }

.cssanimations.no-touch .scroll {
  opacity: 0; }

.cssanimations.no-touch .shown {
  animation: appear .3s linear;
  -webkit-animation: appear .3s linear;
  /* Safari and Chrome */
  -moz-animation: appear .3s linear;
  /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
  /*Chrome 16+, Safari 4+*/
  -moz-animation-fill-mode: forwards;
  /*FF 5+*/
  -o-animation-fill-mode: forwards;
  /*Not implemented yet*/
  -ms-animation-fill-mode: forwards;
  /*IE 10+*/
  animation-fill-mode: forwards;
  /*when the spec is finished*/ }

/* treenav ------------------------------------------------------------ */
.treebar .k-treeview .k-in {
  padding: 0px; }

/* sidenav ------------------------------------------------------------ */
.treebaralt .side-nav ul {
  list-style-type: none;
  margin: 0;
  margin-left: 30px;
  padding: 0;
  font-size: 14px; }

.treebaralt .side-nav {
  background-color: #f5f5f5;
  padding: 4px 20px;
  /*background-image: url(../Images/stripes-gray-ulight.png);*/
  position: relative;
  width: 213px; }

.treebaralt .side-nav > .overhang {
  bottom: -10px;
  left: 0; }

.treebaralt .side-nav li:before {
  content: none; }

.treebaralt .side-nav > ul {
  margin-left: 0; }

.treebaralt .side-nav ul {
  margin-top: 5px; }

.treebaralt .side-nav > ul > li > ul {
  display: none;
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 25px;
  background-color: #f5f5f5; }

.treebaralt .side-nav > ul > li > ul > li {
  border-left: 3px solid #e1e1e1;
  padding-left: 15px; }

.treebaralt .side-nav > ul > li > ul > li.active {
  border-left: 3px solid #267bd5; }

.treebaralt .side-nav > ul > li.active > ul {
  display: block; }

.treebaralt .side-nav li {
  color: #222222;
  padding: 4px 0px 4px 0px; }

.treebaralt .side-nav li a {
  color: #666666;
  text-decoration: none; }

.treebaralt .side-nav li a:hover, .treebaralt .side-nav li a:active {
  color: #1267c1; }

.treebaralt .side-nav > ul > li.active > ul > li.active a {
  color: #267bd5; }

.treebaralt .hidden-xs {
  display: none !important; }

.treebaralt .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.treebaralt .side-nav-arrow {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  float: right; }

.treebaralt .caret {
  display: none; }

.treebaralt .s1 .caret {
  display: inline-block; }

.treebaralt .s2 .caret {
  display: none; }

.treebaralt .side-nav .sfSel {
  font-weight: normal; }

/* video player sidenav ------------------------------------------------------------ */
.treebarnav .side-nav ul {
  list-style-type: none;
  margin: 0;
  margin-left: 30px;
  padding: 0;
  font-size: 14px; }

.treebarnav .side-nav {
  background-color: #f5f5f5;
  padding: 4px 20px;
  /*background-image: url(../Images/stripes-gray-ulight.png);*/
  position: relative;
  width: 80%; }

.treebarnav .side-nav > .overhang {
  bottom: -10px;
  left: 0; }

.treebarnav .side-nav li:before {
  content: none; }

.treebarnav .side-nav > ul {
  margin-left: 0; }

.treebarnav .side-nav ul {
  margin-top: 5px; }

.treebarnav .side-nav > ul > li > ul {
  display: none;
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 25px;
  background-color: #f5f5f5; }

.treebarnav .side-nav > ul > li > ul > li {
  border-left: 3px solid #e1e1e1;
  padding-left: 15px; }

.treebarnav .side-nav > ul > li > ul > li.active {
  border-left: 3px solid #267bd5; }

.treebarnav .side-nav > ul > li.active > ul {
  display: block; }

.treebarnav .side-nav li {
  color: #222222;
  padding: 4px 0px 4px 0px; }

.treebarnav .side-nav li a {
  color: #666666;
  text-decoration: none; }

.treebarnav .side-nav li a:hover, .treebarnav .side-nav li a:active {
  color: #1267c1; }

.treebarnav .side-nav > ul > li.active > ul > li.active a {
  color: #267bd5; }

.treebarnav .hidden-xs {
  display: none !important; }

.treebarnav .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.treebarnav .side-nav-arrow {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  float: right; }

.treebarnav .caret {
  display: none; }

.treebarnav .s1 .caret {
  display: inline-block; }

.treebarnav .s2 .caret {
  display: none; }

.treebarnav .side-nav .sfSel {
  font-weight: normal; }

.treebarnav .side-nav a.sfSel {
  color: #267bd5;
  font-weight: bold; }

.treebarnav .side-nav a.sfSel {
  color: #267bd5;
  font-weight: bold; }

.treebarnava .s1 {
  padding-left: 50%;
  text-align: left;
  margin-top: 120px; }

.treebarnav .s2 {
  top: 33%;
  margin-left: 50%;
  text-align: left;
  margin-top: 160px; }

.treebarnav .side-nav .s2 {
  top: 33%;
  margin-left: 0%;
  text-align: left;
  margin-top: 0px; }

.treebarnav .s3 {
  top: 6%;
  margin-left: 45%;
  text-align: left;
  padding-right: 2%;
  width: 30%; }

.treebarnav .s4 {
  top: 33%;
  margin-left: 50%;
  text-align: left;
  margin-top: 160px; }

.treebarnav .s5 {
  top: 60%;
  left: -80px; }

#competitor_company, #competitor_product {
  background-color: #fff; }

/* videos ------------------------------------------------------------ */
.video-wrapper {
  position: relative;
  width: 350px;
  float: left;
  height: 300px; }

/* link button ------------------------------------------------------------ */
.linkbutton a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #267bd5;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 16px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #267bd5;
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; }

.linkbutton a:hover {
  background-color: #1267c1;
  color: #fff; }

/* search ------------------------------------------------------------ */
.sf_pagerNumeric a {
  border: 1px solid #267bd5;
  padding: 6px;
  margin: 4px;
  width: 20px;
  height: 16px;
  font-size: .8em;
  background-color: #267bd5;
  display: inline-block;
  text-align: center;
  color: #fff; }

.sf_pagerNumeric a:hover {
  background-color: #fff;
  color: #1267c1; }

/* slashed backgrounds ------------------------------------------------------------ */
.slashes {
  padding: 10px;
  margin: -10px;
  text-decoration: none; }

.slashes a {
  text-decoration: none; }

.dark-blue {
  /*background-image: url(../Images/stripes-blue-dark.png);*/
  color: #fff;
  background-color: #003e80; }

.dark-blue a {
  color: #fff; }

.light-blue {
  /*background-image: url(../Images/stripes-blue-light.png);*/
  color: #fff;
  background-color: #267bd5; }

.dark-gray {
  /*background-image: url(../Images/stripes-gray-dark.png);*/
  color: #fff;
  background-color: #989898; }

.light-gray {
  /*background-image: url(../Images/stripes-gray-light.png);*/
  color: #000;
  background-color: #e1e1e1; }

.light-gray-white {
  /*background-image: url(../Images/stripes-gray-extralight.png);*/
  color: #000;
  background-color: #f5f5f5;
  padding: 20px; }

.light-gray-white h3 {
  font-size: 1.2em; }

.light-gray-white p {
  font-size: .8em; }

.dark-red {
  /*background-image: url(../Images/stripes-red-dark.png);*/
  color: #fff;
  background-color: #d50b29; }

/* blog ------------------------------------------------------------ */
#headlinesp {
  height: 500px;
  width: 60%;
  float: left; }

#headlines {
  height: 500px;
  width: 39%;
  float: right; }

.headlines-tile {
  position: relative;
  display: block;
  width: 100%;
  height: 230px;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  position: relative;
  margin-bottom: 20px; }

.headlinesp-tile {
  position: relative;
  display: block;
  width: 100%;
  height: 460px;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  position: relative;
  margin-bottom: 20px; }

.headlines-tile a {
  position: relative;
  top: 80%;
  left: 5%;
  text-decoration: none;
  color: #fff;
  background-color: #003e80;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  line-height: 22px; }

.headlinesp-tile a {
  position: relative;
  top: 90%;
  left: 3%;
  text-decoration: none;
  color: #fff;
  background-color: #003e80;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  line-height: 22px; }

@media (max-width: 558px) and (min-width: 1px) {
  #headlines {
    width: 100%; }
  #headlinesp {
    width: 100%; } }

/* login form ------------------------------------------------------------ */
.loginform {
  padding: 20px 40px 40px 40px;
  border: solid 3px #166bc5; }

.loginform-nb {
  padding: 20px 40px 40px 40px;
  border: solid 0px #166bc5; }

.loginform ol {
  padding: 0;
  list-style-type: none;
  margin-bottom: 10px; }

.loginform li {
  margin-bottom: 10px;
  background: none; }

.loginform input {
  display: block; }

.loginform .sfSubmitBtn, #sds-search {
  color: #fff;
  background-color: #003e80;
  border-color: #003e80;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .9em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none; }

.loginform input[type=submit] {
  width: 170px;
  border: 0px;
  height: 32px;
  font-size: 12px;
  background-color: #0255af;
  margin-top: 9px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  border-left: solid 1px #fff;
  color: #fff; }

.loginform input[type=submit]:hover {
  background-color: #166bc5; }

.loginform .sfLostPassword {
  color: #fff;
  background-color: #003e80;
  border-color: #003e80;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .90em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none; }

.loginform .sfCheckBoxWrapper {
  display: none; }

.loginform fieldset {
  padding: 0;
  margin: 0;
  border: 0px; }

.loginform label {
  display: block;
  position: relative;
  border: solid 0px #bbb;
  color: #222222;
  padding-bottom: 4px;
  font-size: .9em; }

.loginform input[type=text], .loginform input[type=password] {
  width: 97%;
  border: 0px;
  height: 30px;
  padding-left: 15px;
  font-size: .8em;
  line-height: 1.42857143;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.loginform input[type=text]:hover, .loginform input[type=password]:hover {
  -moz-box-shadow: 0px 0px 4px #166bc5;
  -webkit-box-shadow: 0px 0px 4px #166bc5;
  box-shadow: 0px 0px 4px #166bc5; }

#sfLoginWidgetWrp input[type=text] {
  margin-bottom: 10px; }

#sfLoginWidgetWrp .sfError, .loginform .sfError {
  color: #d30927;
  font-size: .9em; }

.loginform .sfError {
  margin-top: 10px; }

.loginform textarea {
  width: 97%;
  border: 0px;
  height: 60px;
  padding-left: 15px;
  font-size: .8em;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.loginform li, .registerform li {
  padding: 0px; }

.sflogin .loginform {
  padding: 20px 40px 40px 40px;
  border: solid 3px #e31937; }

.sflogin .loginform-nb {
  padding: 20px 40px 40px 40px;
  border: solid 0px #e31937; }

.sflogin .loginform ol {
  padding: 0;
  list-style-type: none;
  margin-bottom: 10px; }

.sflogin .loginform li {
  margin-bottom: 10px;
  background: none; }

.sflogin .loginform input {
  display: block; }

.sflogin .loginform .sfSubmitBtn {
  color: #fff;
  background-color: #003e80;
  border-color: #003e80;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .9em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none; }

.sflogin .loginform input[type=submit] {
  width: 170px;
  border: 0px;
  height: 32px;
  font-size: 12px;
  background-color: #0255af;
  margin-top: 9px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  border-left: solid 1px #fff;
  color: #fff; }

.sflogin .loginform input[type=submit]:hover {
  background-color: #166bc5; }

.sflogin .loginform .sfLostPassword {
  color: #fff;
  background-color: #cf0523;
  border-color: #cf0523;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .90em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none; }

.sflogin .loginform .sfCheckBoxWrapper {
  display: none; }

.sflogin .loginform fieldset {
  padding: 0;
  margin: 0; }

.sflogin .loginform label {
  display: block;
  position: relative;
  border: solid 0px #bbb;
  color: #222222;
  z-index: 200;
  left: 15px;
  font-size: .9em;
  margin-top: -8px;
  top: 25px; }

.sflogin .loginform input[type=text], .sflogin .loginform input[type=password] {
  width: 97%;
  border: 0px;
  height: 30px;
  padding-left: 15px;
  font-size: .8em;
  line-height: 1.42857143;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.sflogin .loginform input[type=text]:hover, .sflogin .loginform input[type=password]:hover {
  -moz-box-shadow: 0px 0px 4px #166bc5;
  -webkit-box-shadow: 0px 0px 4px #166bc5;
  box-shadow: 0px 0px 4px #166bc5; }

.sflogin #sfLoginWidgetWrp input[type=text] {
  margin-bottom: 10px; }

.sflogin #sfLoginWidgetWrp .sfError, .sflogin .loginform .sfError {
  color: #d30927;
  font-size: .9em; }

.sflogin .loginform .sfError {
  margin-top: 10px; }

.sflogin .loginform textarea {
  width: 97%;
  border: 0px;
  height: 60px;
  padding-left: 15px;
  font-size: .8em;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

/* register form ------------------------------------------------------------ */
.registerform {
  padding: 20px 40px 40px 40px;
  border: solid 3px #267bd5; }

.registerform ol {
  padding: 0;
  list-style-type: none;
  margin-bottom: 10px; }

.registerform li {
  margin-bottom: 10px; }

.registerform input {
  display: block; }

.registerform .sfregisterSaveLnk {
  color: #fff;
  background-color: #1267c1;
  border-color: #1267c1;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .9em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  margin-left: 0px; }

.registerform .sfLostPassword {
  color: #fff;
  background-color: #cf0523;
  border-color: #cf0523;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .90em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none; }

.registerform .sfCheckBoxWrapper {
  display: none; }

.registerform fieldset {
  padding: 0;
  margin: 0; }

.registerform label {
  display: block;
  position: relative;
  border: solid 0px #bbb;
  color: #222222;
  padding-bottom: 4px;
  font-size: .9em; }

.registerform input[type=text], .registerform input[type=password] {
  width: 97%;
  border: 0px;
  height: 30px;
  padding-left: 15px;
  font-size: .8em;
  line-height: 1.42857143;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.175);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.registerform input[type=text]:hover, .registerform input[type=password]:hover {
  -moz-box-shadow: 0px 0px 4px #166bc5;
  -webkit-box-shadow: 0px 0px 4px #166bc5;
  box-shadow: 0px 0px 4px #166bc5; }

.logout {
  margin: 20px 0px 20px 0px;
  padding: 36px 40px 40px 40px;
  border: solid 3px #003e80; }

.logout a {
  color: #fff;
  background-color: #003e80;
  border-color: #1267c1;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .9em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none; }

/* cycle nav ------------------------------------------------------------ */
.cycle {
  position: relative; }

.cycle .one, .cycle .two, .cycle .three, .cycle h5 {
  position: absolute; }

.cycle-background {
  background-image: url(http://madhousedev.com/betco/images/solutions/cycle.png);
  background-size: contain;
  -webkit-background-size: contain;
  background-position: center center;
  height: 0;
  padding-bottom: 100%;
  -webkit-animation: rotating 20s linear infinite;
  -ms-behavior: url(http://madhousedev.com/betco/backgroundsize.min.htc); }

.svg.placeholder .cycle-background {
  background-image: url(http://madhousedev.com/betco/images/solutions/cycle.svg);
  background-size: contain;
  -webkit-background-size: contain;
  background-position: center center;
  height: 0;
  padding-bottom: 100%;
  -webkit-animation: rotating 20s linear infinite;
  -ms-behavior: url(http://madhousedev.com/betco/backgroundsize.min.htc); }

.slide .block > div {
  padding: 20px; }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

.cycle h5 {
  top: 50%;
  right: 0; }

.cycle h3 {
  float: left; }

.cycle p {
  width: 50px;
  line-height: 1;
  float: left;
  margin-top: 8px;
  margin-left: 5px;
  font-size: 16px; }

.cycle .one {
  top: 20%;
  left: 15%; }

.cycle .two {
  top: 30%;
  right: 15%; }

.cycle .three {
  bottom: 15%;
  left: 25%; }

/* resource blocks ------------------------------------------------------------ */
.resource-1, .resource-2, .resource-3, .resource-4 {
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 20px 30px;
  font-size: 14px !important; }

.resource-1 img, .resource-2 img, .resource-3 img, .resource-4 img {
  width: 23% !important; }

.resource-1 {
  background-color: rgba(255, 255, 255, 0.1); }

.resource-2 {
  background-color: rgba(255, 255, 255, 0.2); }

.resource-3 {
  background-color: rgba(255, 255, 255, 0.3); }

.resource-4 {
  background-color: rgba(255, 255, 255, 0.3); }

.r1 {
  background-image: url(../Images/basicu.png);
  background-repeat: no-repeat;
  background-position: bottom right; }

.r2 {
  background-image: url(../Images/distlocator.png);
  background-repeat: no-repeat;
  background-position: bottom right; }

.r3 {
  background-image: url(../Images/blog.png);
  background-repeat: no-repeat;
  background-position: bottom right; }

/* resource blocks ------------------------------------------------------------ */
.bns {
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 5px 5px;
  font-size: 14px !important; }

.bns img {
  width: 23% !important; }

.bns h5 {
  color: #000; }

.bns a {
  color: #000; }

.bns {
  background-color: rgba(255, 255, 255, 0); }

/* custom alignments ------------------------------------------------------------ */
.alignment-vertical-middle {
  position: relative;
  margin-top: 8%; }

/* carousel - horz list widget ------------------------------------------------------------ */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y; }

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%; }

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel .owl-item {
  float: left; }

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer; }

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.grabbing {
  cursor: url(grabbing.png) 8 8, move; }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px; }

.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease; }

.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease; }

.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease; }

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease; }

.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both; }

.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both; }

.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both; }

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both; }

@-webkit-keyframes empty {
  0% {
    opacity: 1; } }

@-moz-keyframes empty {
  0% {
    opacity: 1; } }

@keyframes empty {
  0% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px); }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%); } }

@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px); }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%); } }

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px); }
  75% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%); } }

@-webkit-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0); } }

@-moz-keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px); }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0); } }

@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    transform: translateZ(-500px); }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0); } }

@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8); } }

@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(0.8); } }

@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(0.8); } }

@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%); } }

@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%); } }

@keyframes goDown {
  from {
    transform: translateY(-100%); } }

@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5); } }

@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5); } }

@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5); } }

@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5); } }

@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5); } }

@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5); } }

#owl-touchpoints .item {
  padding: 0px 0px;
  margin: 0px;
  width: 95%; }

#owl-videos .item {
  padding: 0px 0px;
  margin: 0px;
  width: 95%; }

#owl-horzswipe .item {
  padding: 0px 0px;
  margin: 0px;
  width: 95%; }

#owl-areamap .item {
  padding: 0px 0px;
  margin: 0px;
  padding-right: 40px;
  padding-left: 40px; }

.owl-horzswipenav {
  width: 100px;
  text-align: center;
  margin: 0 auto; }

.owl-horzswipenav .prev, .owl-resources .prev {
  background-image: url(../Images/swipe-left.png);
  display: none;
  height: 18px;
  width: 18px;
  position: relative;
  float: left;
  cursor: pointer; }

.owl-horzswipenav .next, .owl-resources .next {
  background-image: url(../Images/swipe-right.png);
  display: none;
  height: 18px;
  width: 18px;
  position: relative;
  float: right;
  cursor: pointer; }

.owl-lnav {
  display: block;
  height: 26px;
  width: 26px;
  position: relative;
  left: -44px;
  top: 200px; }

.owl-lnav a {
  display: block;
  background-image: url(../Images/car-arrow-trans-left.png);
  height: 26px;
  width: 26px;
  cursor: pointer; }

.owl-lnav a:hover {
  background-image: url(../Images/car-arrow-white-left.png); }

.owl-rnav {
  display: block;
  height: 26px;
  width: 26px;
  position: relative;
  left: 100%;
  top: 226px; }

.owl-rnav a {
  display: block;
  background-image: url(../Images/car-arrow-trans-right.png);
  height: 26px;
  width: 26px;
  cursor: pointer; }

.owl-rnav a:hover {
  background-image: url(../Images/car-arrow-white-right.png); }

.owl-videos .owl-lnav a {
  display: block;
  background-image: url(../Images/car-arrow-red-right.png);
  height: 26px;
  width: 26px;
  cursor: pointer; }

.owl-videos .owl-lnav a:hover {
  background-image: url(../Images/car-arrow-red-right.png); }

.owl-videos .owl-rnav a {
  display: block;
  background-image: url(../Images/car-arrow-red-left.png);
  height: 26px;
  width: 26px;
  cursor: pointer; }

.owl-videos .owl-rnav a:hover {
  background-image: url(../Images/car-arrow-red-left.png); }

/* resources carousel ------------------------------------------------------------ */
.owl-resources {
  width: 50px;
  margin: 0 auto; }

.resources-block h1 {
  color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 1.4em;
  padding-left: 24px; }

.resources-block .h1 {
  color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 1.4em;
  padding-left: 24px; }

.resources-banner {
  display: block;
  width: 100%;
  height: 32px;
  background-color: #166bc5;
  padding-top: 12px; }

.resources-banneroh {
  display: block;
  width: 100%;
  height: 10px;
  /*background-image: url(../Images/stripes-blue-light.png);*/
  background-repeat: repeat; }

.resources-block a {
  color: #000;
  font-weight: bold; }

.resources-block .pdf, .resources-block .presentation, .resources-block .video, .resources-block .weblink {
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center; }

.resources-block .pdf {
  /*background-image: url(../Images/Icon_PDF_50x50.png);*/ }

.resources-block .video {
  /*background-image: url(../Images/Icon_Video_50x50.png);*/ }

.resources-block .presentation {
  /* background-image: url(../Images/Icon_Presentation_50x50.png);*/ }

.resources-block .weblink {
  /* background-image: url(../Images/Icon_WebLink_50x50.png);*/ }

.resources-block .pdf a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_PDF_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

.resources-block .video a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_Video_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

.resources-block .presentation a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_Presentation_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

.resources-block .weblink a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_WebLink_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

#vp1 .rytpe a {
  color: #000;
  font-weight: bold; }

#vp1 .pdf, #vp1 .presentation, #vp1 .video, #vp1 .weblink,
.resources-block .pdf, .resources-block .presentation, .resources-block .video, .resources-block .weblink {
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center; }

#vp1 .pdf a,
.resources-block .pdf a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_PDF_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

#vp1 .video a,
.resources-block .video a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_Video_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

#vp1 .presentation a,
.resources-block .presentation a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_Presentation_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

#vp1 .weblink a,
.resources-block .weblink a {
  display: block;
  height: 40px;
  width: 200px;
  background-image: url(../Images/Icon_WebLink_50x50.png);
  background-repeat: no-repeat;
  background-position-x: center;
  padding-top: 60px;
  margin-top: 20px; }

/* customNavigation for owl ------------------------------------------------------------ */
.customNavigation {
  text-align: center; }

.customNavigation a {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* rss feed reader ------------------------------------------------------------ */
.feedget-container {
  text-align: center;
  line-height: 14px; }

.feedget-error {
  font-size: 12px; }

.feedget-header-container {
  margin: 0;
  background-color: #333333;
  color: #ffffff;
  height: 42px;
  text-align: left;
  overflow: hidden; }

.feedget-header-title {
  font-size: 14px;
  font-weight: 700;
  display: block;
  padding-top: 5px;
  padding-left: 5px;
  width: 73%;
  line-height: 17px; }

.feedget-header-description {
  font-size: 12px;
  display: block;
  padding-top: 1px;
  padding-left: 5px;
  line-height: 17px; }

.vertical .feedget-header-description {
  width: 73%; }

.horizontal .feedget-header-description {
  width: 90%; }

.feedget-header-container div.left.arrow {
  position: absolute;
  top: 9px;
  right: 35px;
  width: 25px;
  height: 25px; }

.feedget-header-container div.right.arrow {
  position: absolute;
  top: 9px;
  right: 5px;
  width: 25px;
  height: 25px; }

.feedget-header-container div.up.arrow {
  position: absolute;
  top: 9px;
  right: 35px;
  width: 25px;
  height: 25px; }

.feedget-header-container div.down.arrow {
  position: absolute;
  top: 9px;
  right: 5px;
  width: 25px;
  height: 25px; }

.feedget-loading {
  margin-top: 10px; }

.feedget-elements-container {
  padding: 5px; }

.feedget-element {
  padding: 5px;
  border: thin solid #e0e0e0;
  background-color: #ffffff;
  overflow: hidden;
  text-align: center; }

.feedget-element.over {
  background-color: #dddddd; }

.feedget-element-title a {
  font-size: 13px;
  font-weight: 700;
  color: #CE0000 !important;
  display: block;
  text-decoration: none; }

.feedget-element-title a:hover {
  text-decoration: underline; }

.feedget-element-date {
  font-size: 9px;
  color: #666666;
  display: block;
  margin-bottom: 5px; }

.feedget-element-text {
  font-size: 10px;
  display: block; }

.horizontal .feedget-element {
  float: left;
  display: block;
  width: 180px;
  margin-right: 5px; }

.horizontal .feedget-element:last-child {
  margin-right: 0 !important; }

.horizontal .feedget-element span {
  text-align: left; }

.vertical .feedget-element {
  margin-bottom: 5px; }

.vertical .feedget-element:last-child {
  margin-bottom: 0 !important; }

.vertical .feedget-element span {
  text-align: left; }

.feedget-element-social {
  margin: 10px 0 0 0;
  text-align: center;
  width: 100%; }

.feedget-element-facebook {
  display: inline; }

.feedget-element-twitter {
  display: inline; }

.feedget-element-facebook iframe {
  width: 100px !important; }

.feedget-element-twitter iframe {
  width: 100px !important; }

.feedget-element-image {
  max-width: 80% !important;
  border: none; }

.feedget-body-container::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

.feedget-body-container::-webkit-scrollbar-track {
  margin: 10px 3px 10px 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(125, 125, 125, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

.feedget-body-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(125, 125, 125, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.feedget-body-container::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(125, 125, 125, 0.4); }

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center; }

.owl-theme .owl-controls .owl-buttons div {
  color: #FFF;
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  opacity: 0.5; }

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity=100);
  /*IE7 fix*/
  opacity: 1;
  text-decoration: none; }

/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline;
  /*IE7 life-saver */ }

.owl-theme .owl-controls .owl-page span {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 11px;
  height: 11px;
  display: block;
  background: #ccc;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin: 0 5px; }

.owl-theme .owl-controls .owl-page.active span {
  background: #003e80;
  cursor: default; }

.owl-theme .owl-controls.clickable .owl-page:hover span {
  background: #b2b2b2;
  background: rgba(0, 0, 0, 0.3); }

.owl-theme .owl-controls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #FFF;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px; }

/* preloading images */
.owl-item.loading {
  min-height: 150px;
  background: url(AjaxLoader.gif) no-repeat center center; }

.flexslider .flex-container a:active,
.flexslider .flexslider a:active,
.flexslider .flex-container a:focus,
.flexslider .flexslider a:focus {
  outline: none; }

.flexslider .slides,
.flexslider .flex-control-nav,
.flexslider .flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

.flexslider {
  margin: 0;
  padding: 0; }

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

.flexslider .slides img {
  width: 100%;
  display: block; }

.flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

.no-js .slides > li:first-child {
  display: block; }

.flexslider {
  /*     margin: 0 0 60px; */
  background: #fff;
  border: 0px solid #fff;
  position: relative;
  zoom: 1; }

.flexslider .flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.flexslider .loading .flex-viewport {
  max-height: 300px; }

.flexslider .flexslider .slides {
  zoom: 1; }

.flexslider .carousel li {
  margin-right: 5px; }

/* Direction Nav */
.flexslider .flex-direction-nav {
  *height: 0;
  display: none; }

.flexslider .flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease; }

.flexslider .flex-direction-nav .flex-prev {
  left: -50px; }

.flexslider .flex-direction-nav .flex-next {
  right: -50px;
  text-align: right; }

.flexslider:hover .flex-prev {
  opacity: 0.7;
  left: 10px; }

.flexslider:hover .flex-next {
  opacity: 0.7;
  right: 10px; }

.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
  opacity: 1; }

.flexslider .flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flexslider .flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: '\f001'; }

.flexslider .flex-direction-nav a.flex-next:before {
  content: '\f002'; }

/* Pause/Play */
.flexslider .flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }

.flexslider .flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004'; }

.flexslider .flex-pauseplay a:hover {
  opacity: 1; }

.flexslider .flex-pauseplay a.flex-play:before {
  content: '\f003'; }

/* Control Nav */
.flexslider .flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center; }

.flexslider .flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.flexslider .flex-control-paging li a {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 11px;
  height: 11px;
  display: block;
  background: #ccc;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin: 0 -2px; }

.flexslider .flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7); }

.flexslider .flex-control-paging li a.flex-active {
  background: #e31937;
  cursor: default; }

.flexslider .flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flexslider .flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flexslider .flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer; }

.flexslider .flex-control-thumbs img:hover {
  opacity: 1; }

.flexslider .flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

@media screen and (max-width: 860px) {
  .flexslider .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flexslider .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

.home-slide {
  display: block;
  height: 500px;
  -webkit-background-size: cover;
  background-position: center center;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%; }

.home-title-1 h1 {
  line-height: .8em;
  text-shadow: 1px 1px #000;
  font-size: 3em;
  color: #fff;
  padding: 0px;
  margin: 0px; }

.home-title-2 h1 {
  line-height: 1em;
  font-size: 3em;
  color: #000;
  padding: 0px;
  margin: 0px; }

.home-title-1 .h1 {
  line-height: .8em;
  text-shadow: 1px 1px #000;
  font-size: 3em;
  color: #fff;
  padding: 0px;
  margin: 0px; }

.home-title-2 .h1 {
  line-height: 1em;
  font-size: 3em;
  color: #000;
  padding: 0px;
  margin: 0px; }

.home-title-2 p {
  padding: 0px;
  margin: 0px;
  color: #000;
  padding-left: 4px;
  margin-top: 10px; }

.home-title-1 {
  display: block;
  position: relative;
  width: 50%;
  top: 20%;
  float: left;
  text-align: right; }

.home-title-2 {
  display: block;
  position: relative;
  width: 45%;
  top: 50%;
  float: right;
  text-align: left;
  padding: 0; }

.slides .s1 {
  padding-left: 50%;
  text-align: left;
  margin-top: 120px; }

.slides .s2 {
  top: 33%;
  margin-left: 50%;
  text-align: left;
  margin-top: 160px; }

.slides .s3 {
  top: 6%;
  margin-left: 45%;
  text-align: left;
  padding-right: 2%;
  width: 30%; }

.slides .s4 {
  top: 33%;
  margin-left: 50%;
  text-align: left;
  margin-top: 170px; }

.slides .s5 {
  top: 40%;
  left: -20px; }

.slides .s6 {
  top: 20%; }

.flexslider2 .flex-container a:active,
.flexslider2 .flexslider a:active,
.flexslider2 .flex-container a:focus,
.flexslider2 .flexslider a:focus {
  outline: none; }

.flexslider2 .slides,
.flexslider2 .flex-control-nav,
.flexslider2 .flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

.flexslider2 {
  margin: 0;
  padding: 0;
  margin-left: 40px; }

.flexslider2 .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

.flexslider2 .slides img {
  display: block; }

.flexslider2 .flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.flexslider2 .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

.no-js .slides > li:first-child {
  display: block; }

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none; }

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

@font-face {
  font-family: "SSPika";
  src: url("../fonts/ss-pika.eot");
  src: url("../fonts/ss-pika.eot?#iefix") format("embedded-opentype"), url("../fonts/ss-pika.woff") format("woff"), url("../fonts/ss-pika.ttf") format("truetype"), url("../fonts/ss-pika.svg#SSPika") format("svg");
  font-weight: normal;
  font-style: normal; }

.flexslider2 .flexslider {
  margin: 0;
  padding: 0; }

.flexslider2 .flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden; }

.flexslider2 .flex-pauseplay span {
  text-transform: capitalize; }

/* Clearfix for the .slides element */
.flexslider2 .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

.no-js .slides > li:first-child {
  display: block; }

/* FlexSlider Default Theme
*********************************/
.flexslider2 .flexslider {
  margin: 0;
  background: transparent;
  border: none;
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  zoom: 1; }

.flexslider2 .flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.flexslider2 .loading .flex-viewport {
  max-height: 300px; }

.flexslider2 .flexslider .slides {
  zoom: 1; }

.flexslider2 .carousel li {
  margin-right: 5px; }

/* Direction Nav */
.flexslider2 .flex-direction-nav {
  *height: 0; }

.flexslider2 .flex-direction-nav a {
  display: block;
  width: 40px;
  height: 40px;
  margin: -40px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #e31937;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  margin-left: -40px; }

.flexslider2 .flex-direction-nav .flex-prev {
  left: 0px; }

.flexslider2 .flex-direction-nav .flex-next {
  right: 0px;
  text-align: right; }

.flexslider2 .flexslider:hover .flex-prev {
  opacity: 0.7;
  left: 10px; }

.flexslider2 .flexslider:hover .flex-next {
  opacity: 0.7;
  right: 10px; }

.flexslider2 .flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
  opacity: 1; }

.flexslider2 .flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default; }

.flexslider2 .flex-direction-nav a:before {
  font-family: "SSPika";
  font-size: 40px;
  display: inline-block;
  content: '\25C5'; }

.flexslider2 .flex-direction-nav a.flex-next:before {
  content: '\25BB'; }

/* Pause/Play */
.flexslider2 .flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000; }

.flexslider2 .flex-pauseplay a:before {
  font-family: "SSPika";
  font-size: 20px;
  display: inline-block;
  content: '\25C5'; }

.flexslider2 .flex-pauseplay a:hover {
  opacity: 1; }

.flexslider2 .flex-pauseplay a.flex-play:before {
  content: '\25C5'; }

/* Control Nav */
.flexslider2 .flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
  display: none; }

.flexslider2 .flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.flexslider2 .flex-control-paging li a {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 11px;
  height: 11px;
  display: block;
  background: #ccc;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin: 0 -2px; }

.flexslider2 .flex-control-paging li a:hover {
  background: #b2b2b2;
  background: rgba(0, 0, 0, 0.3); }

.flexslider2 .flex-control-paging li a.flex-active {
  background: #e31937;
  cursor: default; }

.flexslider2 .flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flexslider2 .flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flexslider2 .flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer; }

.flexslider2 .flex-control-thumbs img:hover {
  opacity: 1; }

.flexslider2 .flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

@media screen and (max-width: 860px) {
  .flexslider2 .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px; }
  .flexslider2 .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px; } }

.flexslider2 li.date img {
  float: left;
  border: 3px solid #ccc;
  margin-right: 10px;
  z-index: 5;
  position: relative;
  max-height: 195px; }

.flexslider2 li.date h6 {
  color: #e31937;
  padding-top: 110px;
  font-size: 18px;
  font-weight: bold; }

.flexslider2 p {
  margin: 10px 0 10px;
  font-size: .9em; }

.flexslider2 .line {
  width: 100%;
  background: #ccc;
  height: 5px;
  z-index: 0;
  position: absolute;
  top: 95px; }

.flexslider2 .year {
  color: #e31937;
  text-align: center;
  background-image: url(../Images/year.png);
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-background-size: contain;
  background-position: center center;
  height: 195px;
  margin-left: 20px;
  float: left;
  width: 14%; }

.flexslider2 .year h1 {
  margin-top: 65px;
  font-size: 58px;
  position: relative;
  min-height: 1px; }

.flexslider2 .year .h1 {
  margin-top: 65px;
  font-size: 58px;
  position: relative;
  min-height: 1px; }

.flexslider2 .date {
  float: left;
  width: 49%; }

.flexslider2 .dates {
  float: left;
  width: 75%; }

.flexslider2 {
  margin-top: 70px;
  margin-bottom: 60px; }

.flexslider2 .flex-direction-nav a:before {
  font-family: "SSPika";
  font-size: 40px;
  display: inline-block;
  content: '\25C5'; }

.flexslider2 .flex-direction-nav a.flex-next:before {
  content: '\25BB'; }

.flexslider2 .slides {
  padding-left: 20px; }

.cse .gsc-control-cse,
.gsc-control-cse {
  padding: 1em;
  width: auto;
  background: none;
  border: 0px; }

.cse .gsc-control-wrapper-cse,
.gsc-control-wrapper-cse {
  width: 100%;
  background: none;
  border: 0px; }

.cse .gsc-branding,
.gsc-branding {
  display: none; }

.cse .gsc-control-cse div,
.gsc-control-cse div {
  position: normal; }

/* Selector for entire element. */
.cse .gsc-control-cse,
.gsc-control-cse {
  background: none;
  border: 0px; }

.cse .gsc-control-cse:after,
.gsc-control-cse:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.cse .gsc-resultsHeader,
.gsc-resultsHeader {
  border: block; }

table.gsc-search-box td.gsc-input {
  padding-right: 12px; }

input.gsc-input {
  border-color: #D9D9D9;
  font-size: 13px;
  padding: 3px 6px;
  width: 150px;
  float: right; }

/* Search button */
.cse input.gsc-search-button,
input.gsc-search-button {
  font-family: inherit;
  font-size: 11px;
  font-weight: bold;
  color: #000;
  padding: 0 8px;
  height: 20px;
  min-width: 54px;
  background-color: #CECECE;
  border: 1px solid #666;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px; }

/* Inactive tab */
.cse .gsc-tabHeader.gsc-tabhInactive,
.gsc-tabHeader.gsc-tabhInactive {
  border-bottom: none;
  color: #666666;
  background-color: #e9e9e9;
  border: 1px solid;
  border-color: #e9e9e9;
  border-bottom: none; }

/* Active tab */
.cse .gsc-tabHeader.gsc-tabhActive,
.gsc-tabHeader.gsc-tabhActive {
  background-color: #FFFFFF;
  border: 1px solid;
  border-top: 2px solid;
  border-color: #e9e9e9;
  border-top-color: #FF9900;
  border-bottom: none; }

/* This is the tab bar bottom border. */
.cse .gsc-tabsArea,
.gsc-tabsArea {
  margin-top: 1em;
  border-bottom: 1px solid #e9e9e9; }

/* Inner wrapper for a result */
.cse .gsc-webResult.gsc-result,
.gsc-webResult.gsc-result,
.gsc-imageResult-column,
.gsc-imageResult-classic {
  padding: .25em;
  border: 1px solid;
  border-color: #ffffff;
  margin-bottom: 1em; }

/* Result hover event styling */
.cse .gsc-webResult.gsc-result:hover,
.gsc-webResult.gsc-result:hover,
.gsc-webResult.gsc-result.gsc-promotion:hover,
.gsc-results .gsc-imageResult-classic:hover,
.gsc-results .gsc-imageResult-column:hover {
  border: 1px solid;
  border-color: #FFFFFF; }

/*Promotion Settings*/
/* The entire promo */
.cse .gsc-webResult.gsc-result.gsc-promotion,
.gsc-webResult.gsc-result.gsc-promotion {
  background-color: #FFFFFF;
  border-color: #336699; }

/* Promotion links */
.cse .gs-promotion a.gs-title:link,
.gs-promotion a.gs-title:link,
.cse .gs-promotion a.gs-title:link *,
.gs-promotion a.gs-title:link *,
.cse .gs-promotion .gs-snippet a:link,
.gs-promotion .gs-snippet a:link {
  color: #0000CC; }

.cse .gs-promotion a.gs-title:visited,
.gs-promotion a.gs-title:visited,
.cse .gs-promotion a.gs-title:visited *,
.gs-promotion a.gs-title:visited *,
.cse .gs-promotion .gs-snippet a:visited,
.gs-promotion .gs-snippet a:visited {
  color: #0000CC; }

.cse .gs-promotion a.gs-title:hover,
.gs-promotion a.gs-title:hover,
.cse .gs-promotion a.gs-title:hover *,
.gs-promotion a.gs-title:hover *,
.cse .gs-promotion .gs-snippet a:hover,
.gs-promotion .gs-snippet a:hover {
  color: #0000CC; }

.cse .gs-promotion a.gs-title:active,
.gs-promotion a.gs-title:active,
.cse .gs-promotion a.gs-title:active *,
.gs-promotion a.gs-title:active *,
.cse .gs-promotion .gs-snippet a:active,
.gs-promotion .gs-snippet a:active {
  color: #0000CC; }

/* Promotion snippet */
.cse .gs-promotion .gs-snippet,
.gs-promotion .gs-snippet,
.cse .gs-promotion .gs-title .gs-promotion-title-right,
.gs-promotion .gs-title .gs-promotion-title-right,
.cse .gs-promotion .gs-title .gs-promotion-title-right *,
.gs-promotion .gs-title .gs-promotion-title-right * {
  color: #000000; }

/* Promotion url */
.cse .gs-promotion .gs-visibleUrl,
.gs-promotion .gs-visibleUrl {
  color: #008000; }

.gsc-completion-selected {
  background: #EEE; }

.gsc-completion-container {
  font-family: Arial, sans-serif;
  font-size: 13px;
  background: white;
  border: 1px solid #CCC;
  border-top-color: #D9D9D9;
  margin-left: 0;
  margin-right: 0; }

.gsc-completion-title {
  color: #0000CC; }

.gsc-completion-snippet {
  color: #000000; }

/* Full URL */
.gs-webResult div.gs-visibleUrl-long,
.gs-promotion div.gs-visibleUrl-long {
  display: block; }

.gcsc-branding, .gsc-result-info-container, .gsc-search-box {
  display: none; }

.cse .gsc-control-cse, .gsc-control-cse {
  padding: 0px; }

.gsc-webResult .gsc-result {
  border-bottom: 1px solid #fff; }

.gs-result a.gs-visibleUrl, .gs-result .gs-visibleUrl {
  color: #267bd5;
  text-decoration: none; }

.gs-result .gs-title, .gs-result .gs-title * {
  color: #0000cc;
  text-decoration: none; }

.treebaralt .side-nav {
  float: left; }

.side-nav .sfNavToggle {
  display: block !important; }

/* Betco Page content ------------------------------------------------------------ */
/* meat and potatos  ------------------------------------------------------------ */
.pagecontent {
  position: relative;
  margin: 0 auto;
  z-index: 10; }

/* Betco Admin section ------------------------------------------------------------ */
/* admin edit view alterations ------------------------------------------------------------ */
.sfPageWrapper .header {
  position: relative; }

.sfPageWrapper .print-header {
  display: block; }

.sfPageWrapper .header-margin {
  display: none; }

.sfPageWrapper .header-sas {
  position: relative; }

.sfPageWrapper .pagecontent {
  top: 5px; }

.sfPageWrapper .sfNavHorizontalDropDown {
  display: none; }

.sfPageWrapper #ctl00_search_ctl01_C_T101D0803001 {
  display: none; }

.sfContentBlock {
  clear: both; }

.pagecontent {
  margin-top: 0px; }

.sfPageWrapper .navbox {
  display: none; }

.sfPageWrapper #ctl00_search_ctl01_C {
  display: none; }

.sfPageWrapper .searchbox {
  display: none; }

.sfPageWrapper .Contentplaceholder1 {
  margin-top: 10px; }

.sfPageWrapper .masthead-large {
  display: block; }

.sfPageWrapper .masthead-large h1,
.sfPageWrapper .masthead-large h2 {
  display: block; }

.sfPageWrapper .masthead-large .h1,
.sfPageWrapper .masthead-large .h2 {
  display: block; }

.sfPageWrapper .masthead-medium {
  display: block; }

.sfPageWrapper .masthead-small {
  display: block; }

.sfPageWrapper .masthead-medium h1,
.sfPageWrapper .masthead-medium h2 {
  display: block; }

.sfPageWrapper .masthead-small h1,
.sfPageWrapper .masthead-medium h2 {
  display: block; }

.sfPageWrapper .masthead-medium .h1,
.sfPageWrapper .masthead-medium .h2 {
  display: block; }

.sfPageWrapper .masthead-small .h1,
.sfPageWrapper .masthead-medium .h2 {
  display: block; }

.sfPageWrapper .mastheadimage {
  margin-left: 0px;
  margin-bottom: 0px; }

.sfPageWrapper .masthead-large h1 {
  padding-top: 0px; }

.sfPageWrapper .masthead-medium h1 {
  padding-top: 0px; }

.sfPageWrapper .masthead-small h1 {
  padding-top: 0px; }

.sfPageWrapper .masthead-large .h1 {
  padding-top: 0px; }

.sfPageWrapper .masthead-medium .h1 {
  padding-top: 0px; }

.sfPageWrapper .masthead-small .h1 {
  padding-top: 0px; }

.sfPageWrapper iframe {
  display: none; }

/*! jQuery UI - v1.9.0 - 2012-10-05
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.accordion.css, jquery.ui.autocomplete.css, jquery.ui.button.css, jquery.ui.datepicker.css, jquery.ui.dialog.css, jquery.ui.menu.css, jquery.ui.progressbar.css, jquery.ui.resizable.css, jquery.ui.selectable.css, jquery.ui.slider.css, jquery.ui.spinner.css, jquery.ui.tabs.css, jquery.ui.tooltip.css, jquery.ui.theme.css
* Copyright 2012 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px); }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-clearfix {
  zoom: 1; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important; }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  position: relative;
  margin-top: 2px;
  padding: .5em .5em .5em .7em;
  zoom: 1; }

.ui-accordion .ui-accordion-icons {
  padding-left: 2.2em; }

.ui-accordion .ui-accordion-noicons {
  padding-left: .7em; }

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
  padding-left: 2.2em; }

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  left: .5em;
  top: 50%;
  margin-top: -8px; }

.ui-accordion .ui-accordion-content {
  padding: 1em 2.2em;
  border-top: 0;
  overflow: auto;
  zoom: 1; }

.ui-autocomplete {
  position: absolute;
  cursor: default; }

/* workarounds */
* html .ui-autocomplete {
  width: 1px; }

/* without this, the menu expands to 100% in IE6 */
.ui-button {
  display: inline-block;
  position: relative;
  padding: 0;
  margin-right: .1em;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible; }

/* the overflow property removes extra width in IE */
.ui-button, .ui-button:link, .ui-button:visited, .ui-button:hover, .ui-button:active {
  text-decoration: none; }

.ui-button-icon-only {
  width: 2.2em; }

/* to make room for the icon, a width needs to be set here */
button.ui-button-icon-only {
  width: 2.4em; }

/* button elements seem to need a little more width */
.ui-button-icons-only {
  width: 3.4em; }

button.ui-button-icons-only {
  width: 3.7em; }

/*button text element */
.ui-button .ui-button-text {
  display: block;
  line-height: 1.4; }

.ui-button-text-only .ui-button-text {
  padding: .4em 1em; }

.ui-button-icon-only .ui-button-text, .ui-button-icons-only .ui-button-text {
  padding: .4em;
  text-indent: -9999999px; }

.ui-button-text-icon-primary .ui-button-text, .ui-button-text-icons .ui-button-text {
  padding: .4em 1em .4em 2.1em; }

.ui-button-text-icon-secondary .ui-button-text, .ui-button-text-icons .ui-button-text {
  padding: .4em 2.1em .4em 1em; }

.ui-button-text-icons .ui-button-text {
  padding-left: 2.1em;
  padding-right: 2.1em; }

/* no icon support for input elements, provide padding by default */
input.ui-button {
  padding: .4em 1em; }

/*button icon element(s) */
.ui-button-icon-only .ui-icon, .ui-button-text-icon-primary .ui-icon, .ui-button-text-icon-secondary .ui-icon, .ui-button-text-icons .ui-icon, .ui-button-icons-only .ui-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px; }

.ui-button-icon-only .ui-icon {
  left: 50%;
  margin-left: -8px; }

.ui-button-text-icon-primary .ui-button-icon-primary, .ui-button-text-icons .ui-button-icon-primary, .ui-button-icons-only .ui-button-icon-primary {
  left: .5em; }

.ui-button-text-icon-secondary .ui-button-icon-secondary, .ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
  right: .5em; }

.ui-button-text-icons .ui-button-icon-secondary, .ui-button-icons-only .ui-button-icon-secondary {
  right: .5em; }

/*button sets*/
.ui-buttonset {
  margin-right: 7px; }

.ui-buttonset .ui-button {
  margin-left: 0;
  margin-right: -.3em; }

/* workarounds */
button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* reset extra padding in Firefox */
.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em; }

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%; }

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%; }

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em; }

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0; }

.ui-datepicker td {
  border: 0;
  padding: 1px; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0em; }

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
  position: absolute;
  /*must have*/
  z-index: -1;
  /*must have*/
  filter: mask();
  /*must have*/
  top: -4px;
  /*must have*/
  left: -4px;
  /*must have*/
  width: 200px;
  /*must have*/
  height: 200px;
  /*must have*/ }

.ui-dialog {
  position: absolute;
  padding: .2em;
  width: 300px;
  overflow: hidden; }

.ui-dialog .ui-dialog-titlebar {
  padding: .4em 1em;
  position: relative; }

.ui-dialog .ui-dialog-title {
  float: left;
  margin: .1em 16px .1em 0; }

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: .3em;
  top: 50%;
  width: 19px;
  margin: -10px 0 0 0;
  padding: 1px;
  height: 18px; }

.ui-dialog .ui-dialog-titlebar-close span {
  display: block;
  margin: 1px; }

.ui-dialog .ui-dialog-titlebar-close:hover, .ui-dialog .ui-dialog-titlebar-close:focus {
  padding: 0; }

.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: .5em 1em;
  background: none;
  overflow: auto;
  zoom: 1; }

.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  margin: .5em 0 0 0;
  padding: .3em 1em .5em .4em; }

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right; }

.ui-dialog .ui-dialog-buttonpane button {
  margin: .5em .4em .5em 0;
  cursor: pointer; }

.ui-dialog .ui-resizable-se {
  width: 14px;
  height: 14px;
  right: 3px;
  bottom: 3px; }

.ui-draggable .ui-dialog-titlebar {
  cursor: move; }

.ui-menu {
  list-style: none;
  padding: 2px;
  margin: 0;
  display: block;
  outline: none; }

.ui-menu .ui-menu {
  margin-top: -3px;
  position: absolute; }

.ui-menu .ui-menu-item {
  margin: 0;
  padding: 0;
  zoom: 1;
  width: 100%; }

.ui-menu .ui-menu-divider {
  margin: 5px -2px 5px -2px;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0; }

.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 2px .4em;
  line-height: 1.5;
  zoom: 1;
  font-weight: normal; }

.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
  font-weight: normal;
  margin: -1px; }

.ui-menu .ui-state-disabled {
  font-weight: normal;
  margin: .4em 0 .2em;
  line-height: 1.5; }

.ui-menu .ui-state-disabled a {
  cursor: default; }

/* icon support */
.ui-menu-icons {
  position: relative; }

.ui-menu-icons .ui-menu-item a {
  position: relative;
  padding-left: 2em; }

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: .2em;
  left: .2em; }

/* right-aligned */
.ui-menu .ui-menu-icon {
  position: static;
  float: right; }

.ui-progressbar {
  height: 2em;
  text-align: left;
  overflow: hidden; }

.ui-progressbar .ui-progressbar-value {
  margin: -1px;
  height: 100%; }

.ui-resizable {
  position: relative; }

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block; }

.ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle {
  display: none; }

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0; }

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0; }

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%; }

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%; }

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px; }

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px; }

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px; }

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px; }

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted black; }

.ui-slider {
  position: relative;
  text-align: left; }

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  cursor: default; }

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0; }

.ui-slider-horizontal {
  height: .8em; }

.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em; }

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

.ui-slider-horizontal .ui-slider-range-min {
  left: 0; }

.ui-slider-horizontal .ui-slider-range-max {
  right: 0; }

.ui-slider-vertical {
  width: .8em;
  height: 100px; }

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em; }

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%; }

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0; }

.ui-slider-vertical .ui-slider-range-max {
  top: 0; }

.ui-spinner {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle; }

.ui-spinner-input {
  border: none;
  background: none;
  padding: 0;
  margin: .2em 0;
  vertical-align: middle;
  margin-left: .4em;
  margin-right: 22px; }

.ui-spinner-button {
  width: 16px;
  height: 50%;
  font-size: .5em;
  padding: 0;
  margin: 0;
  z-index: 100;
  text-align: center;
  position: absolute;
  cursor: default;
  display: block;
  overflow: hidden;
  right: 0; }

.ui-spinner a.ui-spinner-button {
  border-top: none;
  border-bottom: none;
  border-right: none; }

/* more specificity required here to overide default borders */
.ui-spinner .ui-icon {
  position: absolute;
  margin-top: -8px;
  top: 50%;
  left: 0; }

/* vertical centre icon */
.ui-spinner-up {
  top: 0; }

.ui-spinner-down {
  bottom: 0; }

/* TR overrides */
span.ui-spinner {
  background: none; }

.ui-spinner .ui-icon-triangle-1-s {
  /* need to fix icons sprite */
  background-position: -65px -16px; }

.ui-tabs {
  position: relative;
  padding: .2em;
  zoom: 1; }

/* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
.ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: .2em .2em 0; }

.ui-tabs .ui-tabs-nav li {
  list-style: none;
  float: left;
  position: relative;
  top: 0;
  margin: 1px .2em 0 0;
  border-bottom: 0;
  padding: 0;
  white-space: nowrap; }

.ui-tabs .ui-tabs-nav li a {
  float: left;
  padding: .5em 1em;
  text-decoration: none; }

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-bottom: -1px;
  padding-bottom: 1px; }

.ui-tabs .ui-tabs-nav li.ui-tabs-active a, .ui-tabs .ui-tabs-nav li.ui-state-disabled a, .ui-tabs .ui-tabs-nav li.ui-tabs-loading a {
  cursor: text; }

.ui-tabs .ui-tabs-nav li a, .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a {
  cursor: pointer; }

/* first selector in group seems obsolete, but required to overcome bug in Opera applying cursor: text overall if defined elsewhere... */
.ui-tabs .ui-tabs-panel {
  display: block;
  border-width: 0;
  padding: 1em 1.4em;
  background: none; }

.ui-tooltip {
  padding: 8px;
  position: absolute;
  z-index: 9999;
  -o-box-shadow: 0 0 5px #aaa;
  -moz-box-shadow: 0 0 5px #aaa;
  -webkit-box-shadow: 0 0 5px #aaa;
  box-shadow: 0 0 5px #aaa; }

/* Fades and background-images don't work well together in IE6, drop the image */
* html .ui-tooltip {
  background-image: none; }

body .ui-tooltip {
  border-width: 2px; }

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Verdana,Arial,sans-serif;
  font-size: 1.1em; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Verdana,Arial,sans-serif;
  font-size: 1em; }

/*.ui-widget-content { border: 1px solid #cccccc; background: #f9f9f9 url(images/ui-bg_highlight-hard_100_f9f9f9_1x100.png) 50% top repeat-x; color: #222222; }
.ui-widget-content a { color: #222222; }
.ui-widget-header { border: 1px solid #a3a3a3; background: #333333 url(images/ui-bg_diagonals-thick_8_333333_40x40.png) 50% 50% repeat; color: #eeeeee; font-weight: bold; }
*/
.ui-widget-header a {
  color: #eeeeee; }

/* Interaction states
----------------------------------*/
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 1px solid #777777;
  background: #111111 url(images/ui-bg_glass_40_111111_1x400.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #e3e3e3; }

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
  color: #e3e3e3;
  text-decoration: none; }

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  border: 1px solid #000000;
  background: #1c1c1c url(images/ui-bg_glass_55_1c1c1c_1x400.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #ffffff; }

.ui-state-hover a, .ui-state-hover a:hover {
  color: #ffffff;
  text-decoration: none; }

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 1px solid #cccccc;
  background: #ffffff url(images/ui-bg_flat_65_ffffff_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #222222; }

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #222222;
  text-decoration: none; }

/* Interaction Cues
----------------------------------*/
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #ffde2e;
  background: #ffeb80 url(images/ui-bg_inset-hard_55_ffeb80_1x100.png) 50% bottom repeat-x;
  color: #363636; }

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #363636; }

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  border: 1px solid #9e0505;
  background: #cd0a0a url(images/ui-bg_inset-hard_45_cd0a0a_1x100.png) 50% bottom repeat-x;
  color: #ffffff; }

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
  color: #ffffff; }

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #ffffff; }

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal; }

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none; }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
  background-image: url(images/ui-icons_222222_256x240.png); }

.ui-widget-content .ui-icon {
  background-image: url(images/ui-icons_222222_256x240.png); }

.ui-widget-header .ui-icon {
  background-image: url(images/ui-icons_bbbbbb_256x240.png); }

.ui-state-default .ui-icon {
  background-image: url(images/ui-icons_ededed_256x240.png); }

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {
  background-image: url(images/ui-icons_ffffff_256x240.png); }

.ui-state-active .ui-icon {
  background-image: url(images/ui-icons_222222_256x240.png); }

.ui-state-highlight .ui-icon {
  background-image: url(images/ui-icons_4ca300_256x240.png); }

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
  background-image: url(images/ui-icons_ffcf29_256x240.png); }

/* positioning */
.ui-icon-carat-1-n {
  background-position: 0 0; }

.ui-icon-carat-1-ne {
  background-position: -16px 0; }

.ui-icon-carat-1-e {
  background-position: -32px 0; }

.ui-icon-carat-1-se {
  background-position: -48px 0; }

.ui-icon-carat-1-s {
  background-position: -64px 0; }

.ui-icon-carat-1-sw {
  background-position: -80px 0; }

.ui-icon-carat-1-w {
  background-position: -96px 0; }

.ui-icon-carat-1-nw {
  background-position: -112px 0; }

.ui-icon-carat-2-n-s {
  background-position: -128px 0; }

.ui-icon-carat-2-e-w {
  background-position: -144px 0; }

.ui-icon-triangle-1-n {
  background-position: 0 -16px; }

.ui-icon-triangle-1-ne {
  background-position: -16px -16px; }

.ui-icon-triangle-1-e {
  background-position: -32px -16px; }

.ui-icon-triangle-1-se {
  background-position: -48px -16px; }

.ui-icon-triangle-1-s {
  background-position: -64px -16px; }

.ui-icon-triangle-1-sw {
  background-position: -80px -16px; }

.ui-icon-triangle-1-w {
  background-position: -96px -16px; }

.ui-icon-triangle-1-nw {
  background-position: -112px -16px; }

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px; }

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px; }

.ui-icon-arrow-1-n {
  background-position: 0 -32px; }

.ui-icon-arrow-1-ne {
  background-position: -16px -32px; }

.ui-icon-arrow-1-e {
  background-position: -32px -32px; }

.ui-icon-arrow-1-se {
  background-position: -48px -32px; }

.ui-icon-arrow-1-s {
  background-position: -64px -32px; }

.ui-icon-arrow-1-sw {
  background-position: -80px -32px; }

.ui-icon-arrow-1-w {
  background-position: -96px -32px; }

.ui-icon-arrow-1-nw {
  background-position: -112px -32px; }

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px; }

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px; }

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px; }

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px; }

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px; }

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px; }

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px; }

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px; }

.ui-icon-arrowthick-1-n {
  background-position: 0 -48px; }

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px; }

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px; }

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px; }

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px; }

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px; }

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px; }

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px; }

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px; }

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px; }

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px; }

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px; }

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px; }

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px; }

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px; }

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px; }

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px; }

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px; }

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px; }

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px; }

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px; }

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px; }

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px; }

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px; }

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px; }

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px; }

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px; }

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px; }

.ui-icon-arrow-4 {
  background-position: 0 -80px; }

.ui-icon-arrow-4-diag {
  background-position: -16px -80px; }

.ui-icon-extlink {
  background-position: -32px -80px; }

.ui-icon-newwin {
  background-position: -48px -80px; }

.ui-icon-refresh {
  background-position: -64px -80px; }

.ui-icon-shuffle {
  background-position: -80px -80px; }

.ui-icon-transfer-e-w {
  background-position: -96px -80px; }

.ui-icon-transferthick-e-w {
  background-position: -112px -80px; }

.ui-icon-folder-collapsed {
  background-position: 0 -96px; }

.ui-icon-folder-open {
  background-position: -16px -96px; }

.ui-icon-document {
  background-position: -32px -96px; }

.ui-icon-document-b {
  background-position: -48px -96px; }

.ui-icon-note {
  background-position: -64px -96px; }

.ui-icon-mail-closed {
  background-position: -80px -96px; }

.ui-icon-mail-open {
  background-position: -96px -96px; }

.ui-icon-suitcase {
  background-position: -112px -96px; }

.ui-icon-comment {
  background-position: -128px -96px; }

.ui-icon-person {
  background-position: -144px -96px; }

.ui-icon-print {
  background-position: -160px -96px; }

.ui-icon-trash {
  background-position: -176px -96px; }

.ui-icon-locked {
  background-position: -192px -96px; }

.ui-icon-unlocked {
  background-position: -208px -96px; }

.ui-icon-bookmark {
  background-position: -224px -96px; }

.ui-icon-tag {
  background-position: -240px -96px; }

.ui-icon-home {
  background-position: 0 -112px; }

.ui-icon-flag {
  background-position: -16px -112px; }

.ui-icon-calendar {
  background-position: -32px -112px; }

.ui-icon-cart {
  background-position: -48px -112px; }

.ui-icon-pencil {
  background-position: -64px -112px; }

.ui-icon-clock {
  background-position: -80px -112px; }

.ui-icon-disk {
  background-position: -96px -112px; }

.ui-icon-calculator {
  background-position: -112px -112px; }

.ui-icon-zoomin {
  background-position: -128px -112px; }

.ui-icon-zoomout {
  background-position: -144px -112px; }

.ui-icon-search {
  background-position: -160px -112px; }

.ui-icon-wrench {
  background-position: -176px -112px; }

.ui-icon-gear {
  background-position: -192px -112px; }

.ui-icon-heart {
  background-position: -208px -112px; }

.ui-icon-star {
  background-position: -224px -112px; }

.ui-icon-link {
  background-position: -240px -112px; }

.ui-icon-cancel {
  background-position: 0 -128px; }

.ui-icon-plus {
  background-position: -16px -128px; }

.ui-icon-plusthick {
  background-position: -32px -128px; }

.ui-icon-minus {
  background-position: -48px -128px; }

.ui-icon-minusthick {
  background-position: -64px -128px; }

.ui-icon-close {
  background-position: -80px -128px; }

.ui-icon-closethick {
  background-position: -96px -128px; }

.ui-icon-key {
  background-position: -112px -128px; }

.ui-icon-lightbulb {
  background-position: -128px -128px; }

.ui-icon-scissors {
  background-position: -144px -128px; }

.ui-icon-clipboard {
  background-position: -160px -128px; }

.ui-icon-copy {
  background-position: -176px -128px; }

.ui-icon-contact {
  background-position: -192px -128px; }

.ui-icon-image {
  background-position: -208px -128px; }

.ui-icon-video {
  background-position: -224px -128px; }

.ui-icon-script {
  background-position: -240px -128px; }

.ui-icon-alert {
  background-position: 0 -144px; }

.ui-icon-info {
  background-position: -16px -144px; }

.ui-icon-notice {
  background-position: -32px -144px; }

.ui-icon-help {
  background-position: -48px -144px; }

.ui-icon-check {
  background-position: -64px -144px; }

.ui-icon-bullet {
  background-position: -80px -144px; }

.ui-icon-radio-on {
  background-position: -96px -144px; }

.ui-icon-radio-off {
  background-position: -112px -144px; }

.ui-icon-pin-w {
  background-position: -128px -144px; }

.ui-icon-pin-s {
  background-position: -144px -144px; }

.ui-icon-play {
  background-position: 0 -160px; }

.ui-icon-pause {
  background-position: -16px -160px; }

.ui-icon-seek-next {
  background-position: -32px -160px; }

.ui-icon-seek-prev {
  background-position: -48px -160px; }

.ui-icon-seek-end {
  background-position: -64px -160px; }

.ui-icon-seek-start {
  background-position: -80px -160px; }

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px; }

.ui-icon-stop {
  background-position: -96px -160px; }

.ui-icon-eject {
  background-position: -112px -160px; }

.ui-icon-volume-off {
  background-position: -128px -160px; }

.ui-icon-volume-on {
  background-position: -144px -160px; }

.ui-icon-power {
  background-position: 0 -176px; }

.ui-icon-signal-diag {
  background-position: -16px -176px; }

.ui-icon-signal {
  background-position: -32px -176px; }

.ui-icon-battery-0 {
  background-position: -48px -176px; }

.ui-icon-battery-1 {
  background-position: -64px -176px; }

.ui-icon-battery-2 {
  background-position: -80px -176px; }

.ui-icon-battery-3 {
  background-position: -96px -176px; }

.ui-icon-circle-plus {
  background-position: 0 -192px; }

.ui-icon-circle-minus {
  background-position: -16px -192px; }

.ui-icon-circle-close {
  background-position: -32px -192px; }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

.ui-icon-circle-triangle-s {
  background-position: -64px -192px; }

.ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-icon-circle-triangle-n {
  background-position: -96px -192px; }

.ui-icon-circle-arrow-e {
  background-position: -112px -192px; }

.ui-icon-circle-arrow-s {
  background-position: -128px -192px; }

.ui-icon-circle-arrow-w {
  background-position: -144px -192px; }

.ui-icon-circle-arrow-n {
  background-position: -160px -192px; }

.ui-icon-circle-zoomin {
  background-position: -176px -192px; }

.ui-icon-circle-zoomout {
  background-position: -192px -192px; }

.ui-icon-circle-check {
  background-position: -208px -192px; }

.ui-icon-circlesmall-plus {
  background-position: 0 -208px; }

.ui-icon-circlesmall-minus {
  background-position: -16px -208px; }

.ui-icon-circlesmall-close {
  background-position: -32px -208px; }

.ui-icon-squaresmall-plus {
  background-position: -48px -208px; }

.ui-icon-squaresmall-minus {
  background-position: -64px -208px; }

.ui-icon-squaresmall-close {
  background-position: -80px -208px; }

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px; }

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px; }

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px; }

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px; }

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px; }

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px; }

/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  -khtml-border-top-left-radius: 4px;
  border-top-left-radius: 4px; }

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  -khtml-border-top-right-radius: 4px;
  border-top-right-radius: 4px; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -khtml-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -khtml-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/* Overlays */
.ui-widget-overlay {
  background: #aaaaaa url(images/ui-bg_highlight-hard_40_aaaaaa_1x100.png) 50% top repeat-x;
  opacity: .3;
  filter: Alpha(Opacity=30); }

.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: #aaaaaa url(images/ui-bg_highlight-soft_50_aaaaaa_1x100.png) 50% top repeat-x;
  opacity: .2;
  filter: Alpha(Opacity=20);
  -moz-border-radius: 8px;
  -khtml-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px; }

/* CSS for Betco: Responsive ------------------------------------------------------------ */
/*  smooth responsive pre-config -------------------------------------------------------------------------------- */
html, body {
  width: 100%; }

* {
  -webkit-transition: all 0.5s;
  -moz-transition: width 0.5s, -moz-transform 0.5s;
  -webkit-transition: width 0.5s, -webkit-transform 0.5s;
  -o-transition: width 0.5s, -o-transform 0.5s;
  transition: width 0.5s, transform 0.5s;
  /*-webkit-transition: all 0.5s;*/ }

h1 {
  font-size: 3.8em;
  font-weight: bold; }

h2 {
  font-size: 1.8em;
  font-weight: bold; }

.h1 {
  font-size: 3.8em;
  font-weight: bold; }

.h2 {
  font-size: 1.8em;
  font-weight: bold; }

h3 {
  font-size: 1.3em;
  font-weight: bold; }

h4 {
  font-size: 1.25em;
  font-weight: bold; }

h5 {
  font-size: 1.25em;
  font-weight: bold; }

h6 {
  font-size: 1.15em;
  font-weight: normal;
  margin: 0px; }

quote {
  font-size: 1.25em;
  font-style: italic; }

.sfContentBlock p, .sfContentBlock li {
  font-size: 1em;
  line-height: 1.2; }

.lightgray h1 {
  font-size: 3.5em; }

.lightgray .h1 {
  font-size: 3.5em; }

img[usemap] {
  border: none;
  height: auto;
  max-width: 100%;
  width: auto; }

.red {
  text-align: center; }

.mapper {
  display: block; }

.maplist {
  display: none; }

.maplist li {
  background: none; }

.maplist li div {
  padding: 10px; }

.sfError {
  color: #ff0000;
  font-size: .9em; }

/* elastic images ------------------------------------------------------------ */
.bannerimage img {
  width: 100% !important;
  position: relative;
  margin-top: -10px !important; }

.red h1 {
  color: #e31937;
  margin-bottom: 0px; }

.red .h1 {
  color: #e31937;
  margin-bottom: 0px; }

h1.ss-icon.red {
  margin-top: 15px; }

.h1.ss-icon.red {
  margin-top: 15px; }

.white-lightblue .nocheck li {
  background: none; }

.lcheck {
  color: #fff; }

.evtstyle {
  width: 100%; }

.evtstyle p {
  line-height: .3em; }

.evtstyle hr {
  background-color: #ccc;
  border: 0 none;
  color: #ccc;
  height: 1px; }

.evtstyle .sp1 {
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: bold; }

.evtstyle .sp2 {
  font-size: .9em; }

.sfAddEventWrp {
  font-size: .8em; }

.betco-modal {
  /* some styles to position the modal at the center of the page */
  position: fixed;
  top: 17%;
  left: 28%;
  width: 40%;
  height: 500px;
  background-color: #000;
  text-align: center;
  border-radius: 5px;
  display: none;
  /* needed styles for the overlay */
  z-index: 100001;
  /* keep on top of other elements on the page */
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.8); }

.betco-modal iframe {
  border: 0px;
  width: 100%;
  height: 435px; }

.modalclose, a.modalclose:hover {
  background-image: url("../Images/close.png");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
  margin-left: -10px;
  margin-top: -10px;
  padding-right: 40px;
  color: transparent; }

/*  1200 + ------------------------------------------------------------------------------------------------------ */
@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 100%; }
  /* header ---------------------------------- */
  .header-container {
    width: 1200px; }
  .searchbox {
    margin-right: 20px;
    display: block;
    width: 190px; }
  .brandbox {
    width: 200px; }
  /* content ---------------------------------- */
  .pagecontent {
    width: 1170px; }
  .navbox {
    width: 590px; }
  #products-data {
    width: 75%; }
  .gallery-image-container-list {
    width: 45% !important; }
  /* footer ---------------------------------- */
  #vplayer {
    width: 700px;
    height: 400px; }
  .menu-toggle {
    display: none; } }

/*  1200px - 990px ----------------------------------------------------------------------------------------------- */
@media (max-width: 1200px) and (min-width: 990px) {
  /* header ---------------------------------- */
  html, body {
    font-size: 100%; }
  .searchbox {
    margin-right: 20px;
    display: block;
    width: 190px; }
  #search_TFB8053E7008_ctl00_ctl00_searchTextBox {
    width: 90% !important; }
  .brandbox {
    width: 140px; }
  a.brandbox-link {
    width: 106px; }
  .navbox .k-item a {
    font-size: 90%;
    padding: 41px 6px 10px 6px !important; }
  .navbox {
    width: 590px; }
  .rsearch {
    display: none; }
  #products-data {
    width: 70%; }
  .gallery-image-container-list {
    width: 45% !important; }
  /* content ---------------------------------- */
  .pagecontent {
    width: 950px; }
  /* footer ---------------------------------- */
  #vplayer {
    width: 600px;
    height: 340px; }
  /*.betco-modal {
        width: 90%;
        left: 5%;
    }*/
  .betco-modal {
    margin: 10px;
    top: 3px;
    left: 0;
    width: 98%;
    height: inherit;
    background: #000; }
  .menu-toggle {
    display: none; } }

/*  990px - 758px ------------------------------------------------------------------------------------------------------ */
@media (max-width: 990px) and (min-width: 760px) {
  /* header ---------------------------------- */
  html, body {
    font-size: 96%; }
  .searchbox {
    margin-right: 0px; }
  .searchbox {
    display: none; }
  #search_TFB8053E7008_ctl00_ctl00_searchTextBox {
    width: 200px; }
  .rsearch {
    display: block; }
  .brandbox {
    width: 140px; }
  a.brandbox-link {
    width: 106px; }
  .sfNavHorizontalDropDown {
    display: none !important; }
  .navbox .k-item a {
    font-size: 90%;
    padding: 41px 6px 10px 6px !important; }
  #products-data {
    width: 60%; }
  .gallery-image-container-list {
    width: 100% !important; }
  .tech-specs, .direction-of-use, .documents, .video-details, .sizes-line {
    width: 100% !important; }
  .flexslider2 {
    display: none; }
  .homepage-slideshow .slick-slide {
    height: 400px !important; }
  /* content ---------------------------------- */
  .pagecontent {
    width: 718px; }
  .sflogin {
    margin-left: 20px; }
  .white-darkblue .download a {
    font-size: 12px; }
  .white-lightblue .download a {
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px; }
  .rimage-a img {
    width: 100%; }
  .slides .s8 {
    top: 20%;
    left: -20px; }
  .slides .s3 {
    top: -10%;
    text-align: right; }
  .slides .s2 {
    top: 10%;
    text-align: right; }
  /* footer ---------------------------------- */
  #vplayer {
    width: 500px;
    height: 280px; }
  /*.betco-modal {
        width: 90%;
        left: 5%;
    }*/
  .betco-modal {
    margin: 10px;
    top: 3px;
    left: 0;
    width: 98%;
    height: inherit;
    background: #000; }
  .menu-toggle {
    display: block; }
  .menu-toggle, .sfNavWrp .sfNavList, .sfNavWrp .sfNavList.sfShown, .sfNavWrp .sfNavToggle {
    display: block; } }

/*  758px - 558px ---------------------------------------------------------------------------------------------------- */
@media (max-width: 760px) and (min-width: 558px) {
  /* header ---------------------------------- */
  html, body {
    font-size: 94%; }
  .sfNavHorizontalDropDown {
    display: none !important; }
  .header-sas {
    height: 100px;
    width: 100%; }
  .searchbox {
    margin-right: 0px; }
  #search_TFB8053E7008_ctl00_ctl00_searchTextBox {
    width: 200px; }
  .treebarnav .side-nav {
    width: 92.3%; }
  .searchbox {
    display: none; }
  .rsearch {
    display: block; }
  .brandbox {
    width: 140px; }
  a.brandbox-link {
    width: 106px; }
  .k-item a {
    font-size: 13px;
    /*padding: 41px 8px 10px 8px !important;*/ }
  .sfNavSelect {
    -webkit-appearance: none; }
  .homepage-slideshow .slick-slide {
    height: 400px !important; }
  /* products ---------------------------------- */
  .productselector {
    right: 0%; }
  .productselector-open, .productselector-close {
    display: block; }
  /* content ---------------------------------- */
  .pagecontent {
    width: 528px; }
  .mapper, #l0-HotSpot {
    display: none !important; }
  .maplist {
    display: block; }
  /* products ---------------------------------- */
  .productselector {
    right: -4%;
    position: absolute;
    top: -130px; }
  .gallery-image-container-list {
    width: 100% !important; }
  .tech-specs, .direction-of-use, .documents, .video-details, .sizes-line {
    width: 100% !important; }
  .sflogin {
    margin-top: 20px; }
  .flexslider2 {
    display: none; }
  /* footer ---------------------------------- */
  .pagearrow {
    display: none; }
  .slides .s8 {
    top: 20%;
    left: -20px; }
  .slides .s3 {
    top: 10%;
    text-align: right; }
  .slides .s4 {
    top: 20%;
    text-align: right;
    padding-right: 10px; }
  .slides .s2 {
    top: 25%;
    text-align: right;
    padding-right: 10px; }
  .home-slide {
    height: 400px !important; }
  .slides h1 {
    font-size: 2em; }
  .slides .h1 {
    font-size: 2em; }
  .slides .s1 {
    margin-top: 100px; }
  /*.betco-modal {
        width: 90%;
        left: 5%;
    }*/
  .betco-modal {
    margin: 10px;
    top: 3px;
    left: 0;
    width: 98%;
    height: inherit;
    background: #000; }
  .menu-toggle {
    display: block; }
  .menu-toggle, .sfNavWrp .sfNavList, .sfNavWrp .sfNavList.sfShown, .sfNavWrp .sfNavToggle {
    display: block; } }

/*  558px - 468px ------------------------------------------------------------------------------------------------------ */
@media (max-width: 558px) and (min-width: 468px) {
  /* header ---------------------------------- */
  html, body {
    font-size: 92%; }
  .header-sas {
    height: 100px;
    width: 100%; }
  .home-slide h1,
  .home-slide p,
  .bc-right {
    display: none; }
  .home-slide .h1,
  .home-slide p,
  .bc-right {
    display: none; }
  .searchbox {
    margin-right: 0px; }
  .sfNavHorizontalDropDown {
    display: none !important; }
  #search_TFB8053E7008_ctl00_ctl00_searchTextBox {
    width: 120px; }
  .treebarnav .side-nav {
    width: 91.3%; }
  .searchbox {
    display: none; }
  .rsearch {
    display: block; }
  .brandbox {
    width: 140px; }
  a.brandbox-link {
    width: 106px; }
  .k-item a {
    font-size: 13px;
    /*padding: 41px 8px 10px 8px !important;*/ }
  .sfNavSelect {
    -webkit-appearance: none; }
  .homepage-slideshow .slick-slide {
    height: 300px !important; }
  /* content ---------------------------------- */
  .pagecontent {
    width: 458px; }
  .mapper, #l0-HotSpot {
    display: none !important; }
  .maplist {
    display: block; }
  /* products ---------------------------------- */
  .productselector {
    right: -2%;
    position: absolute;
    top: -130px; }
  .productselector-close {
    display: block; }
  .gallery-image-container-list {
    width: 100% !important; }
  .tech-specs, .direction-of-use, .documents, .video-details, .sizes-line {
    width: 100% !important; }
  .flexslider2 {
    display: none; }
  /* footer ---------------------------------- */
  .pagearrow {
    display: none; }
  .slides .s8 {
    top: 20%;
    left: -20px; }
  .slides .s8 {
    top: 20%;
    left: -20px; }
  .slides .s3 {
    top: 10%;
    text-align: right; }
  .slides .s4 {
    top: 20%;
    text-align: right;
    padding-right: 10px; }
  .slides .s2 {
    top: 25%;
    text-align: right;
    padding-right: 10px; }
  .home-slide {
    height: 400px !important; }
  .slides h1 {
    font-size: 2em; }
  .slides .h1 {
    font-size: 2em; }
  .slides .s1 {
    margin-top: 100px; }
  .Contentplaceholder1 {
    /*padding-right:20px;
	    padding-left:20px;*/ }
  /*.betco-modal {
        width: 90%;
        left: 5%;
    }*/
  .betco-modal {
    margin: 10px;
    top: 3px;
    left: 0;
    width: 98%;
    height: inherit;
    background: #000; }
  .white-lightblue h1,
  .white-darkblue h1,
  .lightgray h1,
  .darkgray h1,
  .lightblue h1,
  .darkblue h1,
  .darkred h1,
  .white-default h1,
  .footer h1 {
    font-size: 3em; }
  .resources-block h1 {
    font-size: 2em; }
  .white-lightblue .h1,
  .white-darkblue .h1,
  .lightgray .h1,
  .darkgray .h1,
  .lightblue .h1,
  .darkblue .h1,
  .darkred .h1,
  .white-default .h1,
  .footer .h1 {
    font-size: 3em; }
  .resources-block .h1 {
    font-size: 2em; }
  .contactform {
    margin-left: 10px; }
  .menu-toggle {
    display: block; }
  .menu-toggle, .sfNavWrp .sfNavList, .sfNavWrp .sfNavList.sfShown, .sfNavWrp .sfNavToggle {
    display: block; } }

/*  468px - 0px ------------------------------------------------------------------------------------------------------ */
@media (max-width: 468px) and (min-width: 1px) {
  /* header ---------------------------------- */
  html, body {
    font-size: 90%; }
  .header-sas {
    height: 100px;
    width: 100%; }
  .home-slide h1,
  .home-slide p,
  .bc-right {
    display: none; }
  .home-slide .h1 {
    display: none; }
  .sfNavHorizontalDropDown {
    display: none !important; }
  .searchbox {
    margin-right: 0px; }
  .owl-rnav, .owl-lnav {
    display: none; }
  #search_TFB8053E7008_ctl00_ctl00_searchTextBox {
    width: 100px !important;
    font-size: 10px;
    float: right; }
  .searchbox {
    width: 150px;
    margin-top: 32px; }
  .searchbox fieldset {
    padding: 0px; }
  .white-lightblue > div {
    padding: 0px 0px 0px 0px; }
  #search_TFB8053E7008_ctl00_ctl00_searchButton {
    width: 32px;
    margin-top: -34px;
    margin-left: 10px; }
  .navbox .sfNavSelect {
    margin-left: 30px; }
  .treebarnav .side-nav {
    width: 86.5%; }
  .searchbox {
    display: none; }
  .rsearch {
    display: block; }
  .brandbox {
    width: 140px; }
  a.brandbox-link {
    width: 90px; }
  .sfNavSelect {
    font-size: 10px;
    width: 150px;
    -webkit-appearance: none; }
  .rsearch-text-o {
    margin-right: 0px; }
  .homepage-slideshow .slick-slide {
    height: 300px !important; }
  /* content ---------------------------------- */
  .pagecontent {
    width: 300px; }
  .tech-specs, .direction-of-use, .documents, .video-details, .sizes-line {
    width: 100% !important; }
  .mapper, #l0-HotSpot {
    display: none !important; }
  .maplist {
    display: block; }
  /* products ---------------------------------- */
  .productselector {
    margin-left: 23%;
    position: absolute;
    top: -130px; }
  .productselector-open, .productselector-close {
    display: block; }
  .gallery-image-container-list {
    width: 100% !important; }
  .flexslider2 {
    display: none; }
  /* footer ---------------------------------- */
  .pagearrow {
    display: none; }
  .slides .s8 {
    top: 20%;
    left: -20px; }
  .slides .s3 {
    top: -15%;
    text-align: right; }
  .slides .s4 {
    top: -10%;
    text-align: right;
    padding-right: 10px; }
  .slides .s2 {
    top: -40%;
    text-align: right;
    padding-right: 10px; }
  .home-slide {
    height: 300px !important; }
  .slides h1 {
    font-size: 2em; }
  .slides .h1 {
    font-size: 2em; }
  .slides .s1 {
    margin-top: 70px; }
  .slides .s7 {
    margin-top: 70px; }
  .slides p {
    font-size: .8em; }
  /*.betco-modal {
        width: 90%;
        left: 5%;
    }*/
  .contactform {
    margin-left: 10px; }
  .betco-modal {
    margin: 10px;
    top: 3px;
    left: 0;
    width: 98%;
    height: inherit;
    background: #000; }
  .menu-toggle {
    display: block; }
  .menu-toggle, .sfNavWrp .sfNavList, .sfNavWrp .sfNavList.sfShown, .sfNavWrp .sfNavToggle {
    display: block; } }

@media (max-width: 488px) and (min-width: 0px) {
  .navbox .sfNavSelect {
    font-size: 10px;
    width: 130px;
    padding-left: 0px; }
  .white-lightblue h1,
  .white-darkblue h1,
  .lightgray h1,
  .darkgray h1,
  .lightblue h1,
  .darkblue h1,
  .darkred h1,
  .white-default h1,
  .footer h1 {
    font-size: 1.8em; }
  .white-lightblue .h1,
  .white-darkblue .h1,
  .lightgray .h1,
  .darkgray .h1,
  .lightblue .h1,
  .darkblue .h1,
  .darkred .h1,
  .white-default .h1,
  .footer .h1 {
    font-size: 1.8em; }
  .betco-modal {
    width: 90%;
    left: 5%;
    top: 5%; }
  .contactform {
    margin-left: 10px; }
  .cert, .certtext {
    margin: 0px !important;
    background: none !important; }
  .betco-modal {
    margin: 10px;
    top: 3px;
    left: 0;
    width: 98%;
    height: inherit;
    background: #000; }
  .menu-toggle {
    display: block; }
  .sfNavHorizontalDropDown {
    display: none !important; }
  .menu-toggle, .sfNavWrp .sfNavList, .sfNavWrp .sfNavList.sfShown, .sfNavWrp .sfNavToggle {
    display: block; } }

.panel {
  background-color: #444;
  height: 34px;
  padding: 10px; }

.panel a#login_pop, .panel a#join_pop {
  border: 2px solid #aaa;
  color: #fff;
  display: block;
  float: right;
  margin-right: 10px;
  padding: 5px 10px;
  text-decoration: none;
  text-shadow: 1px 1px #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px; }

a#login_pop:hover, a#join_pop:hover {
  border-color: #eee; }

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  cursor: default;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s; }

.overlay:target {
  visibility: visible;
  opacity: 1; }

.popup {
  background-color: #fff;
  border: 3px solid #fff;
  display: inline-block;
  left: 50%;
  opacity: 0;
  padding: 15px;
  position: fixed;
  text-align: justify;
  top: 40%;
  visibility: hidden;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -moz-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -ms-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -o-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -webkit-transition: opacity .5s, top .5s;
  -moz-transition: opacity .5s, top .5s;
  -ms-transition: opacity .5s, top .5s;
  -o-transition: opacity .5s, top .5s;
  transition: opacity .5s, top .5s; }

.overlay:target + .popup {
  top: 50%;
  opacity: 1;
  visibility: visible; }

.close {
  background-color: rgba(0, 0, 0, 0.8);
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: -15px;
  width: 30px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px; }

.close:before {
  color: rgba(255, 255, 255, 0.9);
  content: "X";
  font-size: 24px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.9); }

.close:hover {
  background-color: rgba(64, 128, 128, 0.8); }

.popup p, .popup div {
  margin-bottom: 10px; }

.popup label {
  display: inline-block;
  text-align: left;
  width: 120px; }

.popup input[type="text"], .popup input[type="password"] {
  border: 1px solid;
  border-color: #999 #ccc #ccc;
  margin: 0;
  padding: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px; }

.popup input[type="text"]:hover, .popup input[type="password"]:hover {
  border-color: #555 #888 #888; }

.pd-container {
  display: none; }

/* Betco print friendly ------------------------------------------------------------ */
@media print {
  .pagecontent {
    clear: both;
    width: 100% !important; }
  div {
    width: 100% !important;
    padding: 0px !important; }
  .main-container {
    display: none !important; }
  .pd-container {
    display: block;
    position: relative;
    font-size: 80%;
    width: 690px !important; }
  .pd-left {
    position: relative;
    width: 49% !important;
    float: left; }
  .pd-right {
    position: relative;
    width: 45% !important;
    float: right;
    padding-left: 20px; }
  .pd-container h1 {
    color: #000;
    font-size: 2em;
    font-weight: bold; }
  .pd-container h2 {
    color: #000;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 40px; }
  .pd-container .h1 {
    color: #000;
    font-size: 2em;
    font-weight: bold; }
  .pd-container .h2 {
    color: #000;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 40px; }
  .pd-container p {
    color: #000;
    font-size: 1em; }
  .pd-image img {
    width: 200px; }
  .pd-container h5 {
    color: #000;
    padding: 0px;
    margin: 0px; }
  .pd-container h5 {
    color: #000;
    padding: 0px;
    margin: 0px; }
  .pd-container {
    width: 100%; }
  .pd-sizes p {
    color: #e31937;
    border: 1px solid #ebebeb;
    padding: 5px 20px 5px 20px;
    display: inline-block;
    font-weight: bold;
    margin: 4px 2px; }
  .details-dir,
  .product-tech-specs,
  .details-desc,
  .details-content {
    margin: 0px;
    padding: 0px;
    height: auto;
    overflow: auto; }
  .pd-techspecs .tech-spec-key,
  .pd-techspecs .tech-spec-value {
    border-bottom: solid 1px #c0c0c0;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 45% !important; }
  .pd-features h5,
  .pd-techspecs h5 {
    margin-top: 10px;
    padding-bottom: 10px; }
  .pd-features .tech-spec-key,
  .pd-features .tech-spec-value {
    float: none; }
  .pd-sizes {
    clear: both;
    margin-top: 20px;
    margin-bottom: 20px; }
  .header,
  .header-margin,
  .footer,
  .pagearrow {
    display: none !important; }
  .print-header {
    display: block;
    position: relative;
    width: 100%;
    height: 65px;
    border-bottom: solid 6px #c0c0c0;
    font-size: 12px; }
  .print-header img {
    width: 150px; }
  .white-products {
    padding-top: 0px; }
  .main-container h1 {
    font-size: 20px;
    color: #000; }
  .main-container .h1 {
    font-size: 20px;
    color: #000; }
  .white-products > div {
    padding: 0px; }
  .pagecontent {
    width: 100%; }
  /*
.video-details  > div{ 
	position:relative;
	width: 100% ;
	height: 240px;
}

.sizes-line    {
	display:none;
	position:relative;
	width:100%;
	float:left;
	border:solid 0px #ff0000;
	padding:0px;
}
.image-container  img {
	width: 200px;
	float:left;
}

.product-features { 

position:relative;
width:100%;
	float:left;
	border:solid 1px #fff;
	
	padding:0px !important;
}


.details-desc {
	padding-top:0px;
	border:solid 1px #fff;
}

.details-features{
	display:block;
	position:relative;
	width:100% !important;
	height:auto !important;
	padding:0px;
}


 

.product-description h5, .product-description p, .details-desc, .tech-spec-key, .product-features h5, .product-tech-specs h5, .product-direction-of-use h5, .details-dir, .product-tech-specs{
	margin:0px;
	padding:0px;
	height:auto;
	overflow:auto;
}
.product-features, .product-direction-of-use{
	margin-top:40px;
}
.details-container {
clear:both;
	position: inherit;
	display:block;

	border:solid 0px #fff;
}
.details-content  {
	overflow:auto !important;
	padding:0px;
	height:auto !important;
	padding:0px;
}
.tech-spec-key, .tech-spec-value {

	padding:0px !important;
}



	
 .product-tech-specs, .product-direction-of-use {
	display:block;
	position:relative;
	border:solid 0px #ff0000;
	width:30% !important;
	float:left;
	margin-bottom:20px;
 }


	
    .details-container h5 {
        background: none;
        color: #000 !important;
        padding: 0px 0px 0px 0px;
        width: 100%;
    }

    .details-content {
        padding: 0px;
    }

    .details-container, .tech-specs {
        width: 100% !important;
    }

    .white-products h5 {
        padding: 0px;
        margin: 0px;
    }

    .printcontainer {
        display: block;
        position: relative;
        width: 60%;
        margin-top: -20px;
    }

    .printleft, .printright {
        display: block;
        position: relative;
        width: 45% !important;
        float: left;
        min-height: 100px;
    }

    .printright {
        margin-left: 20px;
    }

    .direction-of-use .details-content, .tech-specs .details-content, .documents .details-content {
        height: auto;
        overflow: auto;
    }




    .tech-specs {
        margin-top: 20px;
    }

        .tech-specs h5 {
            margin-bottom: 10px;
        }
		
		*/ }

/* Styles for basic forms
-----------------------------------------------------------*/
.useradmincontainer fieldset {
  border: 1px solid #ddd;
  padding: 0 1.4em 1.4em 1.4em;
  margin: 0 0 1.5em 0;
  clear: both;
  position: relative;
  display: block; }

fieldset legend {
  padding: 0px 6px 0px 6px;
  font-size: 1.3em;
  font-weight: bold;
  margin-left: 15px;
  color: #8f8f8f;
  /*overwriting stupid bootstrap and their 100% legend
            DUMB!!!*/
  width: auto;
  border: none; }

fieldset label {
  width: 90%;
  text-align: left;
  position: relative;
  display: block;
  padding-top: 6px;
  margin-left: 20px; }

.field-group-wide label {
  width: 28%; }

.editor-label {
  margin: 1em 0 0 0; }

.editor-field {
  margin: 0.5em 0 0 0; }

/* Styles for validation helpers
-----------------------------------------------------------*/
.field-validation-error, .message {
  color: #f00;
  font-weight: bold; }

.field-validation-valid {
  display: none; }

.input-validation-error {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: #fee !important; }

.validation-summary-errors {
  font-weight: bold;
  color: #f00; }

.validation-summary-valid {
  display: none; }

.operation-result-success {
  color: #007a00;
  font-weight: bold;
  margin-bottom: 10px; }

.operation-result-failure {
  color: #f00;
  font-weight: bold;
  margin-bottom: 10px; }

/* Form Styles */
.form-group {
  margin-bottom: 15px;
  width: 100%;
  float: right; }

.field-group {
  width: 55%;
  text-align: left;
  float: left;
  padding: 0px 0px 0px 20px; }

.field-group p {
  padding-top: 5px;
  margin: 0px; }

.field-group-val {
  text-align: right;
  float: left;
  padding: 5px 0px 0px 15px; }

/* Grid Styles */
.table-container {
  overflow: hidden; }

.tabular-data-view {
  width: 100%; }

.tabular-data-view table {
  width: 100%;
  border: solid 0px #c8c8c9; }

.tabular-data-view table tr {
  border-bottom: solid 1px #c8c8c9; }

.tabular-data-view table thead {
  background-color: #003e80;
  color: #fff; }

.tabular-data-view table thead input[type="submit"] {
  display: inline-block;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background: none !important;
  padding: 0px; }

.tabular-data-view table thead th .sort-indicator-ascending {
  content: url("images/uparrow.png"); }

.tabular-data-view table thead th .sort-indicator-descending {
  content: url("images/downarrow.png"); }

.tabular-data-view table tbody {
  color: #000;
  text-align: center; }

.tabular-data-view table tbody tr.even-row {
  background-color: #fff; }

.tabular-data-view table tbody tr.odd-row {
  background-color: #fff; }

.tabular-data-view-sortable-column {
  color: #fffece; }

.tabular-data-navigation {
  width: 100%;
  font-size: .8em !important;
  clear: right;
  height: 24px;
  margin-top: 20px; }

.tabular-data-navigation .record-count {
  width: 50%; }

.tabular-data-navigation .page-size {
  width: 50%;
  text-align: right; }

.tabular-data-navigation .page-size .go-button {
  width: auto;
  height: auto; }

.useradmincontainer h1 {
  width: 100%; }

.useradmincontainer .h1 {
  width: 100%; }

.useradmincontainer fieldset a {
  margin: 20px; }

input.no-webkit-autofill, input.no-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 500px #fff inset;
  box-shadow: 0 0 0 500px #fff inset; }

#lcheck {
  color: #fff; }

.sfprofileChangePasswordFormWrp li {
  background: none; }

fieldset.sfprofileChangePasswordFormWrp {
  border: 3px solid #E31937; }

.sfprofileChangePasswordFormWrp .sfprofileLnkWrp a {
  transition: all .2s ease;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  border: 1px solid #0054A0;
  color: #166bc5;
  text-decoration: none;
  margin: 10px; }

.sfprofileChangePasswordFormWrp .sfprofileLnkWrp a :hover {
  background-color: #166bc5;
  color: #fff; }

.sfprofileChangePasswordFormWrp label {
  margin: 0px;
  font-size: .9em; }

.sfprofileChangePasswordFormWrp li {
  list-style: none;
  margin: 0px;
  padding: 0px; }

.sfprofileChangePasswordFormWrp ol {
  list-style: none;
  margin-left: 30px;
  padding: 0px; }

.sfprofileChangePasswordFormWrp input {
  height: 20px;
  width: 30%; }

.sfprofileChangePasswordFormWrp .sfprofileLnkWrp {
  margin-left: 20px; }

.sfprofileChangePasswordFormWrp .sfprofileFailureMsg {
  padding: 30px;
  font-size: .9em; }

.sfprofileChangePasswordFormWrp .sfprofileSuccessMsg {
  padding: 30px;
  font-size: .9em; }

.sfprofileLnkWrp a.sfprofileCancelLnk {
  display: none; }

.side-nav .sfNavSelect {
  height: 34px;
  width: 220px;
  border: 0px;
  margin-top: 10px;
  margin-left: 0px;
  color: #000;
  background-image: url(../Images/ddl-caret-white.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-left: 6px;
  overflow: hidden; }

.vrmessage {
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: bold; }

.expander_a, .expander_b {
  display: none; }

.expander_a a, .expander_b a {
  color: #666666;
  font-size: 13px; }

.expander_a a:hover, .expander_b a:hover {
  color: #1267c1; }

.expander_b {
  padding: 8px 0px 8px 0px; }

#trackFrame {
  width: 90%; }

.contactform .sfError {
  color: #fff; }

html.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%; }

/* slick -----------------------------------------------    */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  padding: 0;
  margin-left: 5%; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  outline: none;
  height: 100%;
  min-height: 1px;
  padding: 16px; }

.slick-slide .sflistItemContent, .slick-slide .sflistItemContent img {
  width: 100%; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* slick theme ------------------------------------------- */
/* Slider */
.slick-loading .slick-list {
  background: #fff url("../Images/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 40%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 28px;
  line-height: 1;
  opacity: .90;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.white-lightblue .slick-prev:before,
.white-lightblue .slick-next:before,
.white-darkblue .slick-prev:before,
.white-darkblue .slick-next:before,
.white-darkgray .slick-prev:before,
.white-darkgray .slick-next:before,
.white-lightgray .slick-prev:before,
.white-lightgray .slick-next:before {
  color: #bbb; }

.slick-prev {
  left: 8px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: 20px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background: none; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #003e80; }

.videos-category .slick-prev:before,
.videos-category .slick-next:before,
.videos-suggested .slick-prev:before,
.videos-suggested .slick-next:before {
  font-family: 'slick';
  font-size: 28px;
  line-height: 1;
  opacity: .90;
  color: #003e80;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.video-search-results {
  background-color: #f5f5f5;
  padding: 4px 20px;
  position: relative;
  width: 80%;
  float: right;
  margin-top: 10px;
  padding-bottom: 12px;
  /*background-image: url(../Images/stripes-gray-ulight.png)*/ }

.video-search-results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px; }

.video-search-results li {
  padding: 4px 0px 4px 0px; }

.video-search-results li a {
  color: #666666;
  text-decoration: none;
  font-size: 14px; }

.video-search-results li a:hover {
  color: #1267c1; }

#Contentplaceholder1_TE76B9677010_videosearch {
  width: 50% !important;
  height: 30px;
  padding: 0px 8px 0px 4px;
  border: 1px solid #cecece; }

.video-search-results input[type=submit] {
  width: 40%;
  border: 0px;
  height: 32px;
  font-size: .8em;
  background-color: #f5f5f5;
  margin-top: 9px;
  border-top: solid 1px #cecece;
  border-right: solid 1px #cecece;
  border-bottom: solid 1px #cecece;
  border-left: solid 1px #cecece;
  color: #666666; }

.video-search-results input[type=submit]:hover {
  background-color: #ccc; }

.videos-category {
  margin: 0px 0px 60px 0px; }

.videos-block {
  margin: 40px 0px 0px 0px; }

.shim20 {
  margin-top: 40px; }

.videos-banner {
  display: block;
  width: 100%;
  height: 24px;
  background-color: #166bc5;
  padding-top: 8px; }

.videos-block h1 {
  color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 1.2em;
  padding-left: 24px; }

.videos-block .h1 {
  color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 1.2em;
  padding-left: 24px; }

.ultoomany,
.ulneedcontent,
.success,
.ulfail {
  display: none; }

.ctform #description {
  width: 300px;
  height: 60px; }

input[type=submit]#imageloaderBTN, input[type=file].ChooseFiles {
  color: #fff;
  background-color: #1267c1;
  border-color: #1267c1;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 20px;
  font-size: .9em;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  margin-left: 0px; }

.registerform input[type=checkbox] {
  margin-top: -10px; }

.ct-double-octave:after,
.ct-major-eleventh:after,
.ct-major-second:after,
.ct-major-seventh:after,
.ct-major-sixth:after,
.ct-major-tenth:after,
.ct-major-third:after,
.ct-major-twelfth:after,
.ct-minor-second:after,
.ct-minor-seventh:after,
.ct-minor-sixth:after,
.ct-minor-third:after,
.ct-octave:after,
.ct-perfect-fifth:after,
.ct-perfect-fourth:after,
.ct-square:after {
  content: "";
  clear: both; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: .75rem;
  line-height: 1; }

.ct-chart-bar .ct-label,
.ct-chart-line .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 4px; }

.ct-area {
  stroke: none;
  fill-opacity: .1; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #d70206; }

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #d70206; }

.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #f05b4f; }

.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: #f4c63d; }

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #f4c63d; }

.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: #d17905; }

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #d17905; }

.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
  stroke: #453d3f; }

.ct-series-e .ct-area,
.ct-series-e .ct-slice-pie {
  fill: #453d3f; }

.ct-series-f .ct-bar,
.ct-series-f .ct-line,
.ct-series-f .ct-point,
.ct-series-f .ct-slice-donut {
  stroke: #59922b; }

.ct-series-f .ct-area,
.ct-series-f .ct-slice-pie {
  fill: #59922b; }

.ct-series-g .ct-bar,
.ct-series-g .ct-line,
.ct-series-g .ct-point,
.ct-series-g .ct-slice-donut {
  stroke: #0544d3; }

.ct-series-g .ct-area,
.ct-series-g .ct-slice-pie {
  fill: #0544d3; }

.ct-series-h .ct-bar,
.ct-series-h .ct-line,
.ct-series-h .ct-point,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392; }

.ct-series-h .ct-area,
.ct-series-h .ct-slice-pie {
  fill: #6b0392; }

.ct-series-i .ct-bar,
.ct-series-i .ct-line,
.ct-series-i .ct-point,
.ct-series-i .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-i .ct-area,
.ct-series-i .ct-slice-pie {
  fill: #f05b4f; }

.ct-series-j .ct-bar,
.ct-series-j .ct-line,
.ct-series-j .ct-point,
.ct-series-j .ct-slice-donut {
  stroke: #dda458; }

.ct-series-j .ct-area,
.ct-series-j .ct-slice-pie {
  fill: #dda458; }

.ct-series-k .ct-bar,
.ct-series-k .ct-line,
.ct-series-k .ct-point,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d; }

.ct-series-k .ct-area,
.ct-series-k .ct-slice-pie {
  fill: #eacf7d; }

.ct-series-l .ct-bar,
.ct-series-l .ct-line,
.ct-series-l .ct-point,
.ct-series-l .ct-slice-donut {
  stroke: #86797d; }

.ct-series-l .ct-area,
.ct-series-l .ct-slice-pie {
  fill: #86797d; }

.ct-series-m .ct-bar,
.ct-series-m .ct-line,
.ct-series-m .ct-point,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326; }

.ct-series-m .ct-area,
.ct-series-m .ct-slice-pie {
  fill: #b2c326; }

.ct-series-n .ct-bar,
.ct-series-n .ct-line,
.ct-series-n .ct-point,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-area,
.ct-series-n .ct-slice-pie {
  fill: #6188e2; }

.ct-series-o .ct-bar,
.ct-series-o .ct-line,
.ct-series-o .ct-point,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-area,
.ct-series-o .ct-slice-pie {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }

.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%; }

.ct-square:after {
  display: table; }

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%; }

.ct-minor-second:after {
  display: table; }

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%; }

.ct-major-second:after {
  display: table; }

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%; }

.ct-minor-third:after {
  display: table; }

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%; }

.ct-major-third:after {
  display: table; }

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%; }

.ct-perfect-fourth:after {
  display: table; }

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%; }

.ct-perfect-fifth:after {
  display: table; }

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%; }

.ct-minor-sixth:after {
  display: table; }

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }

.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%; }

.ct-golden-section:after {
  content: "";
  display: table;
  clear: both; }

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%; }

.ct-major-sixth:after {
  display: table; }

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%; }

.ct-minor-seventh:after {
  display: table; }

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%; }

.ct-major-seventh:after {
  display: table; }

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%; }

.ct-octave:after {
  display: table; }

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%; }

.ct-major-tenth:after {
  display: table; }

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%; }

.ct-major-eleventh:after {
  display: table; }

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%; }

.ct-major-twelfth:after {
  display: table; }

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%; }

.ct-double-octave:after {
  display: table; }

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

#chart1 {
  width: 98% !important; }

#chart1 .ct-series-a .ct-bar, .ct-series-b .ct-bar, .ct-series-c .ct-bar {
  stroke: #166bc5; }

#chart1 span.ct-vertical {
  width: 55px !important; }

#chart1 .ct-label {
  color: #fff; }

#chart1 .ct-grid {
  stroke: #fff; }

#chart2 .ct-series-a .ct-line, #chart2 .ct-series-a .ct-point {
  stroke: #8b0606; }

#chart2 .ct-series-b .ct-line, #chart2 .ct-series-b .ct-point {
  stroke: #0f9b27; }

#chart2 .ct-series-c .ct-line, #chart2 .ct-series-c .ct-point {
  stroke: #166bc5; }

#chart2 .ct-label {
  color: #000; }

#chart2 .ct-grid {
  stroke: #ff; }

#chart2 {
  width: 100% !important; }

.chart2-content {
  width: 100%; }

.cname {
  text-align: center;
  font-weight: bold; }

#chart3 .ct-series-a .ct-bar:nth-child(-n+4) {
  stroke: #ff0000; }

#chart3 .ct-series-a .ct-bar:nth-child(-n+3) {
  stroke: #198539; }

#chart3 .ct-series-a .ct-bar:nth-child(-n+2) {
  stroke: #6d2f53; }

#chart3 .ct-series-a .ct-bar:nth-child(-n+1) {
  stroke: #ff0075; }

#chart3 .ct-label {
  color: #000; }

#chart3 .ct-grid {
  stroke: #ff; }

#chart3 {
  width: 100% !important; }

.chart3-content {
  width: 100%; }

.cname {
  text-align: center;
  font-weight: bold; }

.linkwhite a, .linkwhite a:visited {
  color: #fff; }

.imgsizer img {
  width: 80%; }

.regchoice label {
  margin-bottom: 20px;
  margin-top: -24px; }

.regchoice {
  padding-top: 10px; }

.home-slide1 {
  -webkit-background-size: cover;
  background-position: center center; }

.loadingimage {
  background: url(../Images/loading_spinner.gif) no-repeat center;
  width: 300px;
  height: 160px; }

.goog-te-banner-frame {
  padding: 0px !important;
  margin: 0px !important;
  top: -50px !important;
  margin-bottom: -50px !important; }

.goog-logo-link {
  display: none !important; }

.goog-te-gadget {
  color: transparent !important; }

#google_translate_element select {
  border: 1px solid #003e80;
  height: 30px;
  font: .8em;
  padding-left: 15px;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif; }

.contactform .sfError {
  color: #fff; }

.r1 a:hover, .r2 a:hover, .r3 a:hover {
  color: #fff; }

.ltypesd {
  display: none; }

.certtext {
  display: inline-block;
  font-size: 80%;
  margin: 8px 0px 0px 35px;
  line-height: 10px; }

.cert {
  margin-top: 5px !important;
  text-align: left;
  border: solid 0px #ff00ff !important;
  width: 100%;
  height: 35px;
  background-repeat: no-repeat !important;
  background-position-x: left !important;
  background-size: 30px 30px !important; }

.ideabookwrapper {
  display: none; }

.modelsizer {
  height: 350px;
  width: 30%; }

.goog-te-combo {
  display: none !important; }

.langEnglish {
  padding-left: 10px; }

.registerform .sfErrorSummary {
  color: #d80e2c;
  padding-bottom: 10px; }

#PureChatWidget {
  display: none; }

.rsearch {
  display: none;
  border: solid 0 #fff;
  height: 30px;
  width: 30px;
  float: right;
  margin-top: 32px; }

.rsearch-text {
  display: none;
  border: solid 0 #fff;
  height: 30px;
  width: 30px;
  float: right;
  margin-top: 33px; }

.rsearch-text-o {
  background: url(../../../images/search-icon-open.png) no-repeat;
  background-position: center center;
  background-position-y: 1px;
  margin-right: 10px; }

.rsearch-text-c {
  background: url(../../../images/search-icon-closed.png) no-repeat;
  background-position: center center;
  background-position-y: 1px;
  margin-right: 10px; }

.k-popup.k-list-container, .k-popup.k-calendar-container {
  display: none !important; }

.sfNavHorizontalDropDownWrp select {
  display: none !important; }

.menu-rtype {
  display: block;
  border: solid 0 #f0f;
  margin: 0 0 100px 0;
  background: #00508a; }

.menu-rtype .sfNavSelect {
  display: none; }

.menu-rtype .k-treeview {
  overflow: initial; }

.menu-rtype a.k-in {
  width: 100%;
  margin: 0;
  padding: 10px 10px;
  line-height: 1.3333em;
  text-decoration: none;
  border-style: none;
  border-width: 0;
  padding-top: 10px;
  line-height: 20px;
  color: #fff; }

.menu-rtype .sfUL {
  display: none !important; }

.menu-rtype .sfSelUL {
  display: block !important; }

.menu-rtype .sfSelUL a.k-in {
  width: 100%;
  margin: 0;
  padding: 10px 10px;
  line-height: 1.3333em;
  text-decoration: none;
  border-style: none;
  border-width: 0;
  line-height: 10px;
  padding-left: 30px;
  color: #003e80;
  background: #fff; }

.menu-rtype .k-i-arrow-s {
  background-image: none;
  /*background-image: url(../../../images/nav-arrow-down-white.png);*/
  background-image: url(../Images/nav-arrow-down-white.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 8px;
  top: 1px;
  margin-right: 5px !important;
  margin-left: 6px !important; }

.menu-rtype .sfSelUL a:hover.k-in {
  background: #003e80;
  color: #fff; }

.menu-rtype ul li.k-item {
  padding: 0; }

.menu-rtype ul li ul li ul li a {
  padding-left: 50px !important; }

.menu-rtype ul li ul li .k-i-arrow-s {
  background-image: none;
  /*background-image: url(../../../images/nav-arrow-down-red.png);*/
  background-image: url(../Images/nav-arrow-down-white.png);
  background-position: center;
  width: 8px;
  top: 1px;
  margin-right: 5px !important;
  margin-left: 6px !important; }

.menu-rtype ul li ul li a.k-in:hover .k-i-arrow-s {
  /*background-image: url(../../../images/nav-arrow-down-white.png);*/
  background-image: url(../Images/nav-arrow-down-white.png); }

.k-i-arrow-s:before {
  content: ""; }

.menu-toggle .navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 6px 10px 10px 10px;
  margin-top: 28px;
  background-color: transparent;
  background-image: none;
  border: 0 solid #fff;
  border-radius: 4px;
  border-color: #fff;
  cursor: pointer; }

.menu-toggle .navbar-toggle .icon-bar {
  background-color: #fff;
  display: block;
  width: 34px;
  height: 5px;
  border-radius: 1px;
  margin-top: 4px; }

.menu-toggle .navbar-toggle:hover, .menu-toggle .navbar-toggle:focus, .menu-toggle .navbar-toggle:active {
  background-color: rgba(255, 255, 255, 0.5);
  outline: 0; }

html {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden; }

.menu-swap-off {
  display: none; }

.menu-swap-on {
  display: block; }

.responsive-search {
  background: #fff;
  padding-left: 10px; }

.responsive-search fieldset {
  padding: 0;
  margin: 0; }

.responsive-search input[type=text] {
  position: relative;
  margin-left: 20px; }

.responsive-search input[type=submit] {
  font-family: 'SSPika';
  content: "\SEARCH";
  color: #003e80;
  top: 6px;
  cursor: pointer;
  width: 20px;
  border: 0;
  float: left;
  height: 34px;
  background: #fff;
  padding: 0;
  font-size: 14px;
  margin: 0; }

.responsive-search input[type=text] {
  line-height: 28px;
  height: 28px;
  border: solid 0 #000;
  font-size: 16px; }

.hidden:before {
  display: none; }

.nodh:before {
  content: "Search";
  font-size: 13px;
  position: absolute;
  z-index: 100;
  color: #333;
  padding: 10px 0 10px 20px; }

.searchbox span {
  width: 150px;
  position: relative;
  display: block;
  float: right; }

/*.searchbox {
    width: 240px;
}*/
.searchbox fieldset {
  padding: 0; }

.rsearch {
  display: none !important; }

.Contentplaceholder1 {
  background: #fff; }

.header * {
  -webkit-transition: all 0s !important;
  -moz-transition: width 0s,-moz-transform 0s !important;
  -webkit-transition: width 0s,-webkit-transform 0s !important;
  -o-transition: width 0s,-o-transform 0s !important;
  transition: width 0s,transform 0s !important;
  -webkit-transition: all 0s !important; }

.menu-swap-red {
  background: #003e80; }

.searchbox input[type=text] {
  position: relative;
  height: 34px;
  padding: 0 8px 0 0;
  border: 0;
  float: right;
  width: 100%; }

.searchbox input[type="submit"] {
  /*background: url(../../../images/search-icon-blue.png) no-repeat;*/
  background: url(../Images/search-icon-red.png) no-repeat;
  cursor: pointer;
  width: 34px;
  border: 0;
  float: left;
  height: 34px;
  color: #fff; }

@media only screen and (min-width: 1200px) {
  .menu-toggle {
    display: none; } }

li.sfsearchResultTitle {
  padding: 2px 0;
  font-size: .92em;
  background: 0;
  list-style: disc; }

#rssresult {
  margin: 0 auto;
  border: solid 0 #000;
  transition: all .1s ease;
  padding: 0;
  margin: 0;
  min-height: 560px; }

#rssresult .blog-item-1, #rssresult .blog-item-2, #rssresult .blog-item-3 {
  position: relative;
  display: block;
  border: solid 0 #f0f; }

#rssresult .blog-item-1 {
  width: 66%;
  float: left; }

#rssresult .blog-item-2, #rssresult .blog-item-3 {
  width: 33%;
  float: right; }

#rssresult .blog-img-1, #rssresult .blog-img-2, #rssresult .blog-img-3 {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
  padding: 20px; }

#rssresult .blog-img-1 {
  height: 520px;
  margin-right: 20px; }

#rssresult .blog-img-2 {
  height: 230px; }

#rssresult .blog-img-3 {
  height: 230px;
  margin-top: 22px; }

#rssresult .blog-img-1 p, #rssresult .blog-img-2 p, #rssresult .blog-img-3 p {
  display: block;
  position: absolute;
  bottom: 0; }

#rssresult .blog-item-1 a, #rssresult .blog-item-2 a, #rssresult .blog-item-3 a {
  position: relative;
  display: block; }

#rssresult .blog-item-1 a:hover, #rssresult .blog-item-2 a:hover, #rssresult .blog-item-3 a:hover {
  transform: translate(0, -10px); }

#rssresult .blog-link {
  display: inline;
  color: #fff;
  background-color: #003e80;
  padding: 2px 5px;
  margin: 0;
  font-weight: 300;
  line-height: 1.5;
  font-size: 22px; }

#rssresult .blog-updated {
  display: none !important;
  color: #fff;
  background-color: #e31937;
  padding: 2px 5px;
  margin: 0;
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px; }

#rssresult .blog-img-2 .blog-link, #rssresult .blog-img-3 .blog-link {
  font-size: 16px; }

#rssresult .blog-img-2 .blog-updated, #rssresult .blog-img-3 .blog-updated {
  font-size: 11px; }

#rssresult .blog-img-1 span, #rssresult .blog-img-2 span, #rssresult .blog-img-3 span {
  display: block;
  margin-top: 2px; }

.sfcommentAuthorAvatar {
  display: none; }

.pagecontent .recentblgposts h2 {
  font-size: 1em; }

.sflistitem .continuereading span {
  color: #003e80 !important; }

.sflistitem .readmore {
  display: none !important; }

.sfpostsList {
  list-style: none;
  margin: 0;
  padding: 0; }

.sfcommentsForm ol, .sfcommentsForm li {
  list-style: none;
  padding: 0;
  margin: 0; }

.sfcommentsForm input[type="submit"] {
  position: relative;
  border-radius: 2px;
  padding: 10px;
  border: 0;
  max-width: 300px;
  margin: 20px auto 10px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
  white-space: nowrap;
  background: #003e80;
  color: #fff; }

.sfcommentAuthorAvatar {
  display: none; }

.sfSubscribeToEmailLnkWrp {
  padding-bottom: 20px; }

.sfcommentsForm ol, .sfcommentsForm li {
  list-style: none;
  padding: 0;
  margin: 0; }

.sfcommentsForm input[type="submit"] {
  position: relative;
  border-radius: 2px;
  padding: 10px;
  border: 0;
  max-width: 300px;
  margin: 20px auto 10px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.42857143;
  white-space: nowrap;
  background: #003e80;
  color: #fff; }

.sfcommentsForm label {
  padding: 10px 0 0 0;
  margin: 0;
  font-size: 85%; }

fieldset.sfcommentsForm {
  padding: 0;
  margin: 0; }

.sfcommentsForm input[type="text"] {
  width: 85%;
  border: 1px solid #e0e0e0;
  height: 30px;
  font-size: .8em;
  padding-left: 15px;
  background-color: #fff;
  color: #787878;
  padding: 2px .3em; }

.sfcommentsWrp .sfNeutral {
  color: #d80e2c; }

.card-row {
  margin-bottom: 2rem; }
  .card-row > .sf_colsOut > .sf_colsIn {
    display: flex;
    height: 100%; }
    .card-row > .sf_colsOut > .sf_colsIn .card {
      height: 100%; }

.card {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 #00000033;
  box-sizing: border-box; }
  .card.card--center-body-text .card__body {
    text-align: center; }

.card__img img {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%; }

.card__body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 1rem 1rem;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.15); }

.card__title {
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem; }

.card__text {
  margin-bottom: 1rem; }

.btn.card__btn {
  margin-top: auto !important;
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-size: 0.875rem; }

.homepage-slides {
  position: relative; }
  .homepage-slides.homepage-slides--slick {
    opacity: 0;
    transition: opacity 0.3s ease-in; }
    .homepage-slides.homepage-slides--slick.homepage-slides--ready {
      opacity: 1; }
  .homepage-slides .slick-slider {
    margin-bottom: 0; }
  .homepage-slides .slick-list,
  .homepage-slides .slick-slide {
    margin-left: 0;
    padding: 0; }
  .homepage-slides .slick-list {
    width: 100%; }
  .homepage-slides .slick-slide {
    height: 500px; }
  .homepage-slides .slick-next,
  .homepage-slides .slick-prev,
  .homepage-slides .slick-dots {
    z-index: 10; }
  .homepage-slides .slides .slide {
    width: 1920px;
    height: 300px;
    background-position: center;
    background-size: cover; }
    @media (min-width: 768px) {
      .homepage-slides .slides .slide {
        height: 450px; } }

.homepage-slides__title-container {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.homepage-slides__title-block {
  width: 528px;
  max-width: 95%;
  margin: -5rem auto 0; }
  @media (min-width: 768px) {
    .homepage-slides__title-block {
      width: 768px; } }
  @media (min-width: 1024px) {
    .homepage-slides__title-block {
      width: 950px; } }
  @media (min-width: 1200px) {
    .homepage-slides__title-block {
      width: 1170px; } }

.homepage-slides__title {
  display: inline-block;
  padding: 1rem;
  margin-bottom: 0.125em;
  background: rgba(0, 62, 128, 0.5);
  font-size: 2.5rem;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.35); }
  @media (min-width: 768px) {
    .homepage-slides__title {
      font-size: 4rem; } }

.homepage-slides__subtitle {
  padding-left: 1rem;
  font-size: 1.5rem;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8); }
  @media (min-width: 768px) {
    .homepage-slides__subtitle {
      font-size: 2rem; } }

.homepage-featured-tiles {
  background-color: #E1E1E1; }
  .homepage-featured-tiles .pagecontent {
    margin-top: -5rem;
    padding-bottom: 3rem; }

.homepage__card-row {
  display: flex;
  flex-direction: column; }
  @media (min-width: 640px) {
    .homepage__card-row {
      flex-direction: row;
      margin-bottom: 1rem; } }
  .homepage__card-row .card {
    margin-bottom: 1rem; }
  .homepage__card-row .sf_colsIn, .homepage__card-row .card {
    height: 100%; }
  .homepage__card-row .sf_3cols_2_34 {
    width: 33%; }

.homepage-about-us-section {
  padding: 4rem 0;
  background-image: url(/Assets/Themes/BasicCoatings/App_Themes/Default/Images/homepage-aboutus-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #003e80;
  color: #fff; }
  .homepage-about-us-section .homepage-about-us-section__flex {
    display: flex;
    flex-direction: column; }
    .homepage-about-us-section .homepage-about-us-section__flex img {
      max-width: 100%; }
    @media (min-width: 768px) {
      .homepage-about-us-section .homepage-about-us-section__flex {
        flex-direction: row;
        align-items: center; } }

@media (max-width: 1024px) and (min-width: 1px) {
  #rssresult .blog-item-1, #rssresult .blog-item-2, #rssresult .blog-item-3 {
    width: 95%;
    padding: 10px; } }

@media (max-width: 990px) and (min-width: 760px) {
  #rssresult .blog-img-1 {
    height: 230px;
    margin-right: 0; }
  #rssresult .blog-item-1 {
    float: right; }
  #rssresult .blog-img-1 .blog-link {
    font-size: 16px; } }

@media (max-width: 760px) and (min-width: 558px) {
  #rssresult .blog-img-1 {
    height: 230px;
    margin-right: 0; }
  #rssresult .blog-item-1 {
    float: right; }
  #rssresult .blog-img-1 .blog-link {
    font-size: 16px; } }

@media (max-width: 558px) and (min-width: 468px) {
  #rssresult .blog-img-1 {
    height: 230px;
    margin-right: 0; }
  #rssresult .blog-item-1 {
    float: right; }
  #rssresult .blog-img-1 .blog-link {
    font-size: 16px; } }

@media (max-width: 468px) and (min-width: 1px) {
  #rssresult .blog-img-1 {
    height: 230px;
    margin-right: 0; }
  #rssresult .blog-item-1 {
    float: right; }
  #rssresult .blog-img-1 .blog-link {
    font-size: 16px; } }

.clear {
  clear: both;
  display: block; }

.left-col {
  width: 30%;
  float: left;
  line-height: 1.25em; }

.right-col {
  width: 70%;
  float: left; }

.left-col li {
  line-height: 1.8em; }

.left-col .image-container {
  padding-bottom: 20px;
  margin-left: 0; }

.li-bold {
  font-weight: bold; }

ul.no-list-style li {
  list-style-type: none; }

.ingredient-row {
  display: block;
  padding: 8px 15px; }

.ingredient-row:nth-child(odd) {
  background-color: #f1f1f1; }

.ingredient-row .col-left {
  width: 70%;
  float: left; }

.ingredient-row .col-center {
  width: 25%;
  float: left;
  font-style: italic;
  text-align: right; }

.ingredient-row .col-right {
  width: 5%;
  float: right;
  text-align: right; }

.more {
  display: none; }

.expand .more {
  display: block !important; }

.moreclick .fa-minus {
  display: none; }

.moreclick.expand .fa-minus {
  display: inline-block;
  color: #898989; }

.moreclick.expand .fa-plus {
  display: none; }

.moreclick .fa-plus {
  display: inline-block;
  color: #898989; }

.fa {
  cursor: pointer; }

ul.no-list-style {
  padding-left: 0;
  padding-top: 0;
  margin-top: 0; }

.more {
  border-top: 1px #bbbbbb dotted;
  margin-top: 10px;
  padding-top: 10px;
  line-height: 1.5em; }

.more a {
  text-decoration: underline; }

#accordion {
  padding-top: 5px; }

.main-container .right-col h5 {
  background-color: #003e80; }

@media (max-width: 823px) {
  .left-col {
    width: 100%;
    float: none; }
  .right-col {
    width: 100%;
    float: none; }
  .ingredient-row .col-left {
    width: 70%; }
  .ingredient-row .fa {
    font-size: 1.5em; }
  .ingredient-row .col-center {
    width: 70%;
    float: left;
    clear: left;
    text-align: left; }
  .left-col .image-container {
    margin-left: auto; } }

.videocontrol-container {
  margin-top: 40px;
  min-height: 350px; }

.videocontrol-container h2 {
  display: block;
  width: 93%;
  background-color: #166bc5;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  color: #fff;
  font-size: 18px;
  border-bottom: 8px #b3d6ff solid; }

.videocontrol-container ul {
  list-style: none;
  padding-left: 0; }

.videocontrol-container li {
  cursor: pointer;
  padding: 5px; }

.videocontrol-container li:hover {
  cursor: pointer;
  background-color: #f1f1f1; }

.videocontrol-container li:before {
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  content: "\f054";
  margin-right: 10px; }

.album-list {
  clear: both; }

.album-list, .video-list {
  background-color: #fff; }

.spin {
  background-image: url(/assets/images/2.gif);
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 350px; }

.album, .video {
  float: left;
  width: 49%;
  border: 0px #000 solid; }

.album-list li:before {
  content: "\f24d"; }

.video-list li:before {
  content: "\f03d"; }

.clear {
  clear: both;
  display: block; }

.album-item.active {
  cursor: pointer;
  background-color: #f1f1f1; }

.video-item.active {
  cursor: pointer;
  background-color: #f1f1f1; }

.searchback {
  display: none;
  float: left; }

a.searchback {
  color: #222; }

.backclick {
  font-size: 24px;
  /* padding-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer*/ }

h2.video-title {
  /*display: inline;
    margin-top: 30px;*/
  margin-bottom: 30px; }

#btn-search-videos {
  padding: 10px 20px;
  background-color: #0255af;
  border: 0;
  color: #fff;
  max-width: 40%; }

.video-search {
  padding: 8px 20px;
  width: 300px;
  max-width: 50%; }

.mobileonly {
  display: none;
  /*  float: left;*/
  z-index: 9999; }

.mobileonly .backclick {
  margin-bottom: 10px; }

.padtop {
  height: 20px; }

.backclick {
  /* background-color: #166bc5;
    border-bottom: 8px #b3d6ff solid;*/
  min-height: 22px;
  color: #b3d6ff;
  padding: 5px;
  float: left;
  cursor: pointer; }

.video-container h2 {
  min-height: 24px;
  margin-top: 0; }

.closer {
  position: fixed;
  top: 17%;
  left: 28%;
  width: 40%;
  min-height: 300px;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  z-index: 100001;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.8); }

.ibetmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none; }

.ibetmodal img {
  box-shadow: none; }

.k-icon.k-clear-value.k-i-close {
  display: none !important; }

/*tracking-consent-dialog*/
#tracking-consent-dialog {
  padding: 20px;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 40%;
  left: 30%; }

#tracking-consent-dialog a {
  color: #fff;
  text-decoration: underline; }

#tracking-consent-dialog button {
  background-color: #003e80;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-right: 20px;
  border: 0px; }

#tracking-consent-dialog button:hover {
  background-color: #f1f1f1;
  color: #003e80; }

@media (max-width: 800px) {
  #tracking-consent-dialog {
    width: 100%;
    left: 0;
    bottom: auto;
    top: 0; }
  #tracking-consent-dialog p {
    max-width: 95%; }
  #tracking-consent-dialog button {
    float: none; }
  .padtop {
    height: 0px; }
  .mobileonly {
    display: block; }
  .video-container {
    display: none; }
  .album, .video {
    float: none;
    width: 100%;
    border: 0px #000 solid; }
  .album.clicked {
    display: none; }
  .video.clicked {
    display: none; }
  #btn-search-videos {
    padding: 7px 10px; }
  .video-search {
    padding: 5px 10px; }
  .backclick {
    /*float: left;*/
    width: 5%;
    margin-top: 0; }
  .padv {
    height: 20px; } }

/*
ul.album-list.clicked {display:none;}
ul.video-list.clicked {display:none;}*/
@media (max-width: 990px) {
  .productselector {
    display: none;
    width: 130%;
    margin: 0;
    margin-left: -15%;
    right: 0;
    left: 0;
    position: absolute;
    float: none;
    background: #003e80;
    border: 0px red solid !important; }
  .productselector > div {
    border: 0px red solid !important;
    width: 85%;
    margin: 0 auto; }
  .productselector-open {
    display: block;
    background-image: none;
    background-color: #166bc5;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    background-image: url(../../../images/filter-icon.png);
    background-position: left center;
    margin-top: 20px; }
  .productselector-open::before {
    content: "Filter "; }
  .productselector-close {
    display: block; } }

.sfPageWrapper .searchbox {
  display: block; }

.sfPageWrapper .navbox {
  display: block; }

/* header icons and loginlogout*/
.searchbox {
  margin-top: 14px;
  width: 240px; }

.searchbox span {
  width: 200px;
  position: relative;
  display: block;
  float: right; }

.searchbox .header-icons {
  margin-bottom: 3px; }

.searchbox .header-icons img {
  padding: 0px 9px 0px 0px; }

.searchbox .header-login a {
  color: #fff;
  background-image: url(../images/LogIn.png);
  padding-left: 25px;
  background-position: left;
  background-repeat: no-repeat;
  font-size: .9em;
  display: inline-block;
  min-height: 20px; }

.searchbox .sf_2cols_2_33 .sf_2cols_2in_33, .searchbox .sf_2cols_1_67 .sfContentBlock {
  margin: 0;
  padding: 0; }

.reponsive-header-icons {
  margin-top: 10px;
  margin-bottom: 10px; }

.responsive-nav .menu-rtype {
  margin-bottom: 0; }

.col-1-icons {
  float: none;
  min-height: 22px;
  text-align: center; }

.col-1-icons img {
  padding: 0px 0px 0px 0px; }

.col-2-login.header-login {
  float: none;
  min-height: 22px;
  text-align: center; }

.col-2-login.header-login a {
  color: #fff;
  background-image: url(../images/LogIn.png);
  padding-left: 18px;
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 17px;
  display: block;
  padding-top: 5px;
  background-size: 16px;
  width: 50px;
  margin: 0 auto 15px; }

.searchbox {
  margin-top: 2px; }

.header-icons img {
  width: 16px; }

.searchbox .header-icons {
  margin: 0;
  text-align: left; }

.searchbox .header-icons img {
  padding: 0px; }

.header-icons a {
  padding: 0;
  margin: 0;
  width: auto; }

.searchbox .header-login a {
  background-size: 16px;
  padding-top: 4px; }

.reponsive-header-icons img {
  width: 16px;
  padding: 0px 5px; }

.header-icons img {
  width: 16px;
  margin: 0px 6px 0px 0px;
  vertical-align: middle; }

.header-icons {
  vertical-align: bottom;
  color: #fff;
  padding-bottom: 2px; }

.header-icons .sf_2cols_1_67 {
  margin-top: 4px; }

.searchbox .header-icons {
  margin: 0;
  text-align: left; }

.header-login {
  margin: 0;
  text-align: right; }

.header-login a {
  margin-left: 10px; }

/* fix for plus wide vertical phones*/
@media (max-width: 490px) and (min-width: 390px) {
  .productselector-open {
    top: -25px; } }

/* bright edge */
.be-ix-link-block {
  background-color: #fff;
  padding-bottom: 20px; }

.be-ix-link-block .be-related-link-container {
  padding: 30px 10px 0;
  color: #003e80; }

.be-ix-link-block .be-related-link-container .be-list .be-list-item a {
  color: #003e80; }

.catelist, .return-to-news {
  display: none; }

.hph1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.1; }

.hph2 {
  font-size: 22px;
  opacity: .7;
  font-weight: 300;
  margin: 0 0 10px; }

.blogwidget-container .blogwidget {
  position: relative;
  transition: all .2s ease-in-out;
  top: 5px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  border-radius: 2px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
  height: 400px;
  margin: 20px 20px 40px 0; }
  .blogwidget-container .blogwidget h2.blogwidget__title {
    display: block;
    position: absolute;
    bottom: 0;
    color: #fff;
    background-color: #003e80;
    padding: 2px 5px;
    margin: 20px;
    font-weight: 300;
    line-height: 1.5;
    font-size: 18px; }
  .blogwidget-container .blogwidget .blogwidget__img {
    max-width: 100%;
    width: 100%; }
  .blogwidget-container .blogwidget a.sffullstory {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999; }

.blogwidget-container .blogwidget:hover {
  position: relative;
  top: -5px; }

@media (max-width: 1180px) {
  .blogwidget-container .blogwidget {
    height: 30vw; } }

@media (max-width: 600px) {
  .blogwidget-container .blogwidget {
    margin: 20px 0 40px 0;
    height: 50vw; } }

form[action^="./Preview"] .header-login {
  display: none; }
