﻿@import "../variables";

.homepage-slides {
    position: relative;

    &.homepage-slides--slick {
        opacity: 0;
        transition: opacity 0.3s ease-in;

        &.homepage-slides--ready {
            opacity: 1;
        }
    }

    .slick-slider {
        margin-bottom: 0;
    }

    .slick-list,
    .slick-slide {
        margin-left: 0;
        padding: 0;
    }

    .slick-list {
        width: 100%;
    }

    .slick-slide {
        height: 500px;
    }

    .slick-next,
    .slick-prev,
    .slick-dots {
        z-index: 10;
    }

    .slides {
        .slide {
            width: 1920px;
            height: 300px;
            background-position: center;
            background-size: cover;

            @include media-query(md) {
                height: 450px;
            }
        }
    }
}

.homepage-slides__title-container {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.homepage-slides__title-block {
    width: 528px;
    max-width: 95%;
    margin: -5rem auto 0;

    @include media-query(md) {
        width: 768px;
    }

    @include media-query(lg) {
        width: 950px;
    }

    @include media-query(xl) {
        width: 1170px;
    }
}

.homepage-slides__title {
    display: inline-block;
    padding: 1rem;
    margin-bottom: 0.125em;
    background: transparentize(colors(primary), 0.5);
    font-size: 2.5rem;
    line-height: 1;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);

    @include media-query(md) {
        font-size: 4rem;
    }
}

.homepage-slides__subtitle {
    padding-left: 1rem;
    font-size: 1.5rem;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);

    @include media-query(md) {
        font-size: 2rem;
    }
}

.homepage-featured-tiles {
    background-color: #E1E1E1;

    .pagecontent {
        margin-top: -5rem;
        padding-bottom: 3rem;
    }
}

.homepage__card-row {
    display: flex;
    flex-direction: column;

    @include media-query(sm) {
        flex-direction: row;
        margin-bottom: 1rem;
    }

    .card {
        margin-bottom: 1rem;
    }

    .sf_colsIn, .card {
        height: 100%;
    }

    .sf_3cols_2_34 {
        width: 33%;
    }
}

.homepage-about-us-section {
    padding: 4rem 0;
    background-image: url(#{$themePath}/Images/homepage-aboutus-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: colors(primary);
    color: #fff;

    .homepage-about-us-section__flex {
        display: flex;
        flex-direction: column;

        img {
            max-width: 100%;
        }

        @include media-query(md) {
            flex-direction: row;
            align-items: center;
        }
    }
}
