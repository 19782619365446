@import "addons/card";
@import "addons/homepage";

@media (max-width: 1024px) and (min-width: 1px) {
    #rssresult .blog-item-1, #rssresult .blog-item-2, #rssresult .blog-item-3 {
        width: 95%;
        padding: 10px;
    }
}

@media(max-width: 990px) and (min-width: 760px) {

    #rssresult .blog-img-1 {
        height: 230px;
        margin-right: 0
    }

    #rssresult .blog-item-1 {
        float: right
    }

    #rssresult .blog-img-1 .blog-link {
        font-size: 16px
    }
}

@media(max-width:760px) and (min-width:558px) {
    #rssresult .blog-img-1 {
        height: 230px;
        margin-right: 0
    }

    #rssresult .blog-item-1 {
        float: right
    }

    #rssresult .blog-img-1 .blog-link {
        font-size: 16px
    }
}

@media(max-width:558px) and (min-width:468px) {

    #rssresult .blog-img-1 {
        height: 230px;
        margin-right: 0
    }

    #rssresult .blog-item-1 {
        float: right
    }

    #rssresult .blog-img-1 .blog-link {
        font-size: 16px
    }
}

@media(max-width:468px) and (min-width:1px) {
    #rssresult .blog-img-1 {
        height: 230px;
        margin-right: 0
    }

    #rssresult .blog-item-1 {
        float: right
    }

    #rssresult .blog-img-1 .blog-link {
        font-size: 16px
    }
}

.clear {
    clear: both;
    display: block
}

.left-col {
    width: 30%;
    float: left;
    line-height: 1.25em;
}

.right-col {
    width: 70%;
    float: left;
}

.left-col li {
    line-height: 1.8em;
}

.left-col .image-container {
    padding-bottom: 20px;
    margin-left: 0;
}

.li-bold {
    font-weight: bold;
}

ul.no-list-style li {
    list-style-type: none;
}

.ingredient-row {
    display: block;
    padding: 8px 15px;
}

.ingredient-row:nth-child(odd) {
    background-color: #f1f1f1;
}

.ingredient-row .col-left {
    width: 70%;
    float: left;
}

.ingredient-row .col-center {
    width: 25%;
    float: left;
    font-style: italic;
    text-align: right;
}

.ingredient-row .col-right {
    width: 5%;
    float: right;
    text-align: right;
}

.more {
    display: none;
}

.expand .more {
    display: block !important;
}

.moreclick .fa-minus {
    display: none;
}

.moreclick.expand .fa-minus {
    display: inline-block;
    color: #898989;
}

.moreclick.expand .fa-plus {
    display: none;
}

.moreclick .fa-plus {
    display: inline-block;
    color: #898989;
}

.fa {
    cursor: pointer;
}

ul.no-list-style {
    padding-left: 0;
    padding-top: 0;
    margin-top: 0
}

.more {
    border-top: 1px #bbbbbb dotted;
    margin-top: 10px;
    padding-top: 10px;
    line-height: 1.5em
}

.more a {
    text-decoration: underline
}


#accordion {
    padding-top: 5px;
}

.main-container .right-col h5 {
    background-color: #003e80;
}

@media (max-width:823px) {
    .left-col {
        width: 100%;
        float: none;
    }

    .right-col {
        width: 100%;
        float: none;
    }


    .ingredient-row .col-left {
        width: 70%;
    }

    .ingredient-row .fa {
        font-size: 1.5em;
    }

    .ingredient-row .col-center {
        width: 70%;
        float: left;
        clear: left;
        text-align: left;
    }

    .left-col .image-container {
        margin-left: auto;
    }
}

.videocontrol-container {
    margin-top: 40px;
    min-height: 350px
}

.videocontrol-container h2 {
    display: block;
    width: 93%;
    background-color: #166bc5;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    color: #fff;
    font-size: 18px;
    border-bottom: 8px #b3d6ff solid;
}


.videocontrol-container ul {
    list-style: none;
    padding-left: 0;
}

.videocontrol-container li {
    cursor: pointer;
    padding: 5px
}

.videocontrol-container li:hover {
    cursor: pointer;
    background-color: #f1f1f1
}

.videocontrol-container li:before {
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 900;
    content: "\f054";
    margin-right: 10px
}

h2 {
}

.album-list {
    clear: both;
}

.album-list, .video-list {
    background-color: #fff;
}

.spin {
    background-image: url(/assets/images/2.gif);
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 350px;
}

.album, .video {
    float: left;
    width: 49%;
    border: 0px #000 solid;
}

.album-list li:before {
    content: "\f24d";
}

.video-list li:before {
    content: "\f03d";
}

.clear {
    clear: both;
    display: block
}

.album-item.active {
    cursor: pointer;
    background-color: #f1f1f1
}

.video-item.active {
    cursor: pointer;
    background-color: #f1f1f1
}

.searchback {
    display: none;
    float: left;
}

a.searchback {
    color: #222
}

.showback {
}

.backclick {
    font-size: 24px;
    /* padding-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer*/
}

h2.video-title {
    /*display: inline;
    margin-top: 30px;*/
    margin-bottom: 30px;
}

#btn-search-videos {
    padding: 10px 20px;
    background-color: #0255af;
    border: 0;
    color: #fff;
    max-width: 40%;
}

.video-search {
    padding: 8px 20px;
    width: 300px;
    max-width: 50%;
}

.mobileonly {
    display: none;
    /*  float: left;*/
    z-index: 9999;
}

.mobileonly .backclick {
    margin-bottom: 10px;
}

.padtop {
    height: 20px
}

.backclick {
    /* background-color: #166bc5;
    border-bottom: 8px #b3d6ff solid;*/
    min-height: 22px;
    color: #b3d6ff;
    padding: 5px;
    float: left;
    cursor: pointer;
}

.video-container h2 {
    min-height: 24px;
    margin-top: 0
}

.closer {
    position: fixed;
    top: 17%;
    left: 28%;
    width: 40%;
    min-height: 300px;
    background-color: #fff;
    text-align: center;
    border-radius: 5px;
    z-index: 100001;
    box-shadow: 0 0 0 9999px rgba(0,0,0,0.8);
}

.ibetmodal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
}

.ibetmodal img {
    box-shadow: none;
}

.k-icon.k-clear-value.k-i-close {
    display: none !important
}
/*tracking-consent-dialog*/

#tracking-consent-dialog {
    padding: 20px;
    z-index: 10000000;
    background-color: rgba(0,0,0,.8);
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 40%;
    left: 30%;
}

#tracking-consent-dialog a {
    color: #fff;
    text-decoration: underline;
}

#tracking-consent-dialog button {
    background-color: #003e80;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
    border: 0px;
}

#tracking-consent-dialog button:hover {
    background-color: #f1f1f1;
    color: #003e80;
}

@media (max-width:800px) {
    #tracking-consent-dialog {
        width: 100%;
        left: 0;
        bottom: auto;
        top: 0;
    }

    #tracking-consent-dialog p {
        max-width: 95%;
    }

    #tracking-consent-dialog button {
        float: none;
    }

    .padtop {
        height: 0px
    }

    .mobileonly {
        display: block;
    }

    .video-container {
        display: none;
    }

    .album, .video {
        float: none;
        width: 100%;
        border: 0px #000 solid
    }

    .album.clicked {
        display: none;
    }

    .video.clicked {
        display: none;
    }


    #btn-search-videos {
        padding: 7px 10px;
    }

    .video-search {
        padding: 5px 10px;
    }

    .backclick {
        /*float: left;*/
        width: 5%;
        margin-top: 0
    }

    .padv {
        height: 20px;
    }
}
/*
ul.album-list.clicked {display:none;}
ul.video-list.clicked {display:none;}*/
@media(max-width:990px) /*and (min-width:760px)*/ {


    .productselector {
        display: none;
        width: 130%;
        margin: 0;
        margin-left: -15%;
        right: 0;
        left: 0;
        position: absolute;
        float: none;
        background: #003e80;
        border: 0px red solid !important;
    }

    .productselector > div {
        border: 0px red solid !important;
        width: 85%;
        margin: 0 auto;
    }



    .productselector-open {
        display: block;
        background-image: none;
        background-color: #166bc5;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        background-image: url(../../../images/filter-icon.png);
        background-position: left center;
        margin-top: 20px;
    }

    .productselector-open::before {
        content: "Filter ";
    }

    .productselector-close {
        display: block;
    }
}

.sfPageWrapper .searchbox {
    display: block
}

.sfPageWrapper .navbox {
    display: block
}

/* header icons and loginlogout*/
.searchbox {
    margin-top: 14px;
    width: 240px;
}

.searchbox span {
    width: 200px;
    position: relative;
    display: block;
    float: right;
}


.searchbox .header-icons {
    margin-bottom: 3px;
}

.searchbox .header-icons img {
    padding: 0px 9px 0px 0px
}

.searchbox .header-login a {
    color: #fff;
    background-image: url(../images/LogIn.png);
    padding-left: 25px;
    background-position: left;
    background-repeat: no-repeat;
    font-size: .9em;
    display: inline-block;
    min-height: 20px;
}

.searchbox .sf_2cols_2_33 .sf_2cols_2in_33, .searchbox .sf_2cols_1_67 .sfContentBlock {
    margin: 0;
    padding: 0;
}

.reponsive-header-icons {
    margin-top: 10px;
    margin-bottom: 10px;
}

.responsive-nav .menu-rtype {
    margin-bottom: 0
}

.col-1-icons {
    float: none;
    min-height: 22px;
    text-align: center;
}

.col-1-icons img {
    padding: 0px 0px 0px 0px
}

.col-2-login.header-login {
    float: none;
    min-height: 22px;
    text-align: center;
}

.col-2-login.header-login a {
    color: #fff;
    background-image: url(../images/LogIn.png);
    padding-left: 18px;
    background-position: left center;
    background-repeat: no-repeat;
    min-height: 17px;
    display: block;
    padding-top: 5px;
    background-size: 16px;
    width: 50px;
    margin: 0 auto 15px;
}

.searchbox {
    margin-top: 2px;
}

.header-icons img {
    width: 16px;
}

.searchbox .header-icons {
    margin: 0;
    text-align: left
}

.searchbox .header-icons img {
    padding: 0px;
}

.header-icons a {
    padding: 0;
    margin: 0;
    width: auto;
}

.searchbox .header-login a {
    background-size: 16px;
    padding-top: 4px
}

.reponsive-header-icons img {
    width: 16px;
    padding: 0px 5px;
}

.header-icons img {
    width: 16px;
    margin: 0px 6px 0px 0px;
    vertical-align: middle
}

.header-icons {
    vertical-align: bottom;
    color: #fff;
    padding-bottom: 2px
}

.header-icons .sf_2cols_1_67 {
    margin-top: 4px
}

.searchbox .header-icons {
    margin: 0;
    text-align: left
}

.header-login {
    margin: 0;
    text-align: right
}

.header-login a {
    margin-left: 10px
}
/* fix for plus wide vertical phones*/
@media(max-width:490px) and (min-width:390px) {

    .productselector-open {
        top: -25px
    }
}
/* bright edge */
.be-ix-link-block {
    background-color: #fff;
    padding-bottom: 20px;
}

.be-ix-link-block .be-related-link-container {
    padding: 30px 10px 0;
    color: #003e80;
}

.be-ix-link-block .be-related-link-container .be-list .be-list-item a {
    color: #003e80;
}

.catelist, .return-to-news {
    display: none;
}
.hph1 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1;
}

.hph2 {
    font-size: 22px;
    opacity: .7;
    font-weight: 300;
    margin: 0 0 10px;
}

.bloglayout-container {
}

.blogwidget-container {

    .blogwidget {
        position: relative;
        transition: all .2s ease-in-out;
        top: 5px;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: cover !important;
        border-radius: 2px;
        box-shadow: 0 3px 6px rgba(0,0,0,.175);
        height: 400px;
        margin: 20px 20px 40px 0;

        h2.blogwidget__title {
            display: block;
            position: absolute;
            bottom: 0;
            color: #fff;
            background-color: #003e80;
            padding: 2px 5px;
            margin: 20px;
            font-weight: 300;
            line-height: 1.5;
            font-size: 18px;
        }

        .blogwidget__img {
            max-width: 100%;
            width: 100%;
        }

        a.sffullstory {
            height: 100%;
            width: 100%;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 999;
        }
    }

    .blogwidget:hover {
        position: relative;
        top: -5px;
    }
}

@media(max-width:1180px) {

    .blogwidget-container {

        .blogwidget {
            height: 30vw;
        }
    }
}

@media(max-width:600px) {

    .blogwidget-container {

        .blogwidget {
            margin: 20px 0 40px 0;
            height: 50vw;
        }
    }
}

form[action^="./Preview"] {
    .header-login {
        display: none;
    }
}
