﻿.card-row {
    margin-bottom: 2rem;

    > .sf_colsOut {
        > .sf_colsIn {
            display: flex;
            height: 100%;

            .card {
                height: 100%;
            }
        }
    }
}

.card {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 #00000033;
    box-sizing: border-box;

    &.card--center-body-text {
        .card__body {
            text-align: center;
        }
    }
}

.card__img {
}

.card__img img {
    object-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 1rem 1rem;
    box-shadow: 0 -1px 3px rgba(0,0,0,0.15);
}

.card__title {
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.card__text {
    margin-bottom: 1rem;
}

.btn.card__btn {
    margin-top: auto !important;
    padding: 0.25rem 0.5rem;
    text-align: center;
    font-size: 0.875rem;
}
