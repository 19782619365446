$themePath: "/Assets/Themes/BasicCoatings/App_Themes/Default";

// Colors
$colors: (
    primary: (
        400: #00459E,
        500: #003e80,
        600: #002E6B,
        700: #223E54,
    )
);

@function colors($colorName, $value: 500) {
    @return map-get(map-get($colors, $colorName), $value);
}

// Breakpoints
@mixin media-query($size) {
    @if $size == sm {
        @media (min-width: 640px) {
            @content;
        }
    } @else if $size == md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $size == lg {
        @media (min-width: 1024px) {
            @content;
        }
    } @else if $size == xl {
        @media (min-width: 1200px) {
            @content;
        }
    }
}
