/*  normalize ------------------------------------------------------------ */
html {
}

body {
    margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}

audio, canvas, progress, video {
    display: inline-block;
    /* 1 */
    vertical-align: /* 2 */
    baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden], template {
    display: none;
}

a {
    background: transparent;
}

a:active, a:hover {
    outline: 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b, strong {
    font-weight: 700;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code, kbd, pre, samp {
    font-family: monospace,monospace;
    font-size: 1em;
}

button {
    overflow: visible;
}

button, select {
    text-transform: none;
}

button, html input[type="button"], /* 1 */
input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    /* 2 */
    cursor: /* 3 */
    pointer;
}

button[disabled], html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    /* 2 */
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

fieldset {
    border: 0 solid silver;
    margin: 0 !important;
}

legend {
    border: 0;
    /* 1 */
    margin: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: 700;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td, th {
    padding: 0;
}

input, textarea, select {
    -webkit-border-radius: 0;
}

* {
    -webkit-tap-highlight-color: transparent;
}

p {
}
